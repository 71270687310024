import React, { useEffect } from "react";
import { Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/logo.png";
import {
  DashboardOutlined,
  ShopOutlined,
  UserOutlined,
  OrderedListOutlined, // Icon for Orders
  BellOutlined, // Icon for Reminders
  TransactionOutlined, // Icon for Transactions
  RadarChartOutlined, // Icon for Tracking
  AccountBookOutlined,
  SettingOutlined,
  FileExcelOutlined,
  MoneyCollectOutlined
} from "@ant-design/icons";
import "./Sidebar.css";
import SubMenu from "antd/es/menu/SubMenu";

const { Sider } = Layout;

const Sidebar = ( { collapsed, setCollapsed, darkMode, setDarkMode } ) => {
  const location = useLocation();
  const getActiveKey = ( path ) => {
    const pathToKeyMapping = {
      "/": "1",
      "/products": "2",
      "/customers": "6",
      "/orders": "7",
      "/ledger": "11",
      "/transactions": "9",
      "/reminders": "8",
      "/expenses": "5",
      "/companies": "12",
      "/settings/roles": "13",
      "/settings/users": "14",
      "/settings/balance": "15",
      "/report/order": "16",
      "/report/daily": "17",
      "report/expenses": "expenses",
      "/finance/payments": "payments",
      "/finance/receipts": "receipts"
    };
    return pathToKeyMapping[path] || "1"; // default to Dashboard
  };

  const onCollapse = ( collapsed ) => {
    setCollapsed( collapsed );
  };

  const handleResize = ( collapsed ) => {
    if ( window.innerWidth < 576 ) {
      setCollapsed( collapsed );
    } else if ( window.innerWidth > 576 ) {
      setCollapsed( collapsed );
    }
  };

  useEffect( () => {
    window.addEventListener( "resize", handleResize );
    return () => {
      window.removeEventListener( "resize", handleResize );
    };
  }, );

  return (
    <React.Fragment>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        theme={darkMode ? "dark" : "light"}
        className="sider-transition"
        style={{
          overflow: "auto",
          height: "94vh",
          position: "fixed",
          left: 0,
        }}
      >
        <div className="logo">
          <img
            width={collapsed ? "50px" : "100px"}
            height={collapsed ? "50px" : "70px"}
            src={logo}
            alt="logoshow"
          />
        </div>
        <Menu
          theme={darkMode ? "dark" : "light"}
          mode="inline"
          selectedKeys={[getActiveKey( location.pathname )]}
        >
          <Menu.Item key="1" icon={<DashboardOutlined />}>
            <Link to="/">Dashboard</Link>
          </Menu.Item>
          <SubMenu key="financeManagement" icon={<MoneyCollectOutlined />} title="Finance">
            <Menu.Item key="payments">
              <Link to="/finance/payments">Payments</Link>
            </Menu.Item>
            <Menu.Item key="receipts">
              <Link to="/finance/receipts">Receipts</Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="2" icon={<ShopOutlined />}>
            <Link to="/products">Products</Link>
          </Menu.Item>
          <Menu.Item key="6" icon={<UserOutlined />}>
            <Link to="/customers">Customers</Link>
          </Menu.Item>
          <Menu.Item key="7" icon={<OrderedListOutlined />}>
            <Link to="/orders">Orders</Link>
          </Menu.Item>

          <Menu.Item key="11" icon={<MoneyCollectOutlined />} >
            <Link to="/ledger">Ledger</Link>
          </Menu.Item>


          {/* <Menu.Item key="9" icon={<TransactionOutlined />}>
            <Link to="/transactions">Transactions</Link>
          </Menu.Item> */}
          {/* <Menu.Item key="8" icon={<BellOutlined />}>
            <Link to="/reminders">Reminders</Link>
          </Menu.Item> */}
          <Menu.Item key="5" icon={<DashboardOutlined />}>
            <Link to="/expenses">Expenses</Link>
          </Menu.Item>
          <Menu.Item key="12" icon={<ShopOutlined />}>
            <Link to="/companies">Companies</Link>
          </Menu.Item>{" "}
          {/* New Companies Menu */}
          <SubMenu key="settings" icon={<SettingOutlined />} title="Settings">
            {/* <Menu.Item key="13">
              <Link to="/settings/roles">Roles</Link>
            </Menu.Item>
            <Menu.Item key="14">
              <Link to="/settings/users">Users</Link>
            </Menu.Item> */}
            <Menu.Item key="15">
              <Link to="/settings/balance">Balance</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="reports" icon={<FileExcelOutlined />} title="Reports">
            <Menu.Item key="16">
              <Link to="/report/order">Order Report</Link>
            </Menu.Item>
            <Menu.Item key="16">
              <Link to="/report/expenses">Expense Report</Link>
            </Menu.Item>
            <Menu.Item key="17">
              <Link to="/report/daily">Daily</Link>
            </Menu.Item>
            {/* <Menu.Item key="17">
              <Link to="/summeries/monthly">Monthly</Link>
            </Menu.Item> */}
          </SubMenu>
        </Menu>
      </Sider>
    </React.Fragment>
  );
};

export default Sidebar;
