import { useQuery } from "react-query";
import { fetchSummeryByCompanyId, fetchSummeryRemainingByCompanyId } from "../APICalls/SummeryService";

export const useGetSummeryByCompanyId = ( id ) => {
    return useQuery( ['summery', id], () => fetchSummeryByCompanyId( id ) );
};
export const useGetSummeryRemainingByCompanyId = ( id ) => {
    return useQuery( ['summeryRemaining', id], () => fetchSummeryRemainingByCompanyId( id ) );
};

