import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import ReactApexChart from 'react-apexcharts';
import { Alert, Spin, Typography } from 'antd';
import { useGetSalesPurchase } from '../../services/Query/OrderQuery';

const Loading = () => {
    return <Spin size="large" />;
}

const ErrorComponent = () => {
    return <Alert message="Error fetching data. Please try again later." type="error" />;
}

const CompanyIncom = () => {
    const { data=[], loading, error } = useGetSalesPurchase();

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <ErrorComponent />;
    }

    const series = data.map(d => d.value);
    const labels = data.map(d => d.name);

    const chartOptions = {
        chart: {
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true
                },
                autoSelected: 'zoom'
            }
        },
        labels: labels,
        plotOptions: {
            pie: {
                donut: {
                    size: '60%',
                    labels: {
                        show: true,
                        name: {
                            show: true
                        },
                        value: {
                            show: true
                        }
                    }
                }
            }
        },
        dataLabels: {
            enabled: true
        },
        legend: {
            show: true
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };

    return (
        <>
            <Typography.Title level={5}>Company's Total Sales vs. Purchases Overview</Typography.Title>
            <ReactApexChart options={chartOptions} series={series} type="donut" height={250} />
        </>
    );
};

export default CompanyIncom;

