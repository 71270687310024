import { useMutation, useQuery, useQueryClient } from "react-query";
import { addPaymentCategory, fetchPaymentCategories } from "../APICalls/PaymentCategory";

export const useAddPaymentCategory = () => {
    const queryClient = useQueryClient();

    return useMutation( addPaymentCategory, {
        onSuccess: () => {
            queryClient.invalidateQueries( 'paymentCategories' );
        }
    } );
};

export const useFetchPaymentCategories = () => {
    return useQuery( 'paymentCategories', fetchPaymentCategories );
};
