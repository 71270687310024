import api from "../../utils/interceptors/api";

export const addOrder = ( data ) => {
    return api.post( `/orders`, data );
}

export const fetchOrders = async ( searchText ) => {
    const response = await api.get( '/orders', { params: { searchText } } );
    return response.data;
};

export const updateOrder = ( orderId, orderData ) => {
    return api.put( `/orders/${ orderId }`, orderData );
}
export const deleteOrder = async ( orderId ) => {
    const response = await api.delete( `/orders/${ orderId }` );
    return response.data;
}

export const fetchLedger = async ( id ) => {
    const response = await api.get( `/ledger/fetchLedger/${ id }` );
    return response.data;
};
export const getSalesPurchase = async () => {
    const response = await api.get( `/orders/getSalesPurchase` );
    return response.data;
};
export const salesPurchaseLastWeek = async () => {
    const response = await api.get( `/orders/salesPurchaseLastWeek` );
    return response.data;
};
export const getDashboardKpis = async () => {
    const response = await api.get( `/orders/getDashboardKpis` );
    return response.data;
};
export const fetchTodayRecords = async ( id ) => {
    const response = await api.get( `/orders/fetchTodayRecords/${ id }` );
    return response.data;
};


export const getOrderReport = async ( searchParams, isExport = 0 ) => {
    return api.get( `/orders/getOrderReport?dateFrom=${ searchParams.dateFrom }&dateTo=${ searchParams.dateTo }&companyId=${ searchParams.companyId }&productId=${ searchParams.productId }&orderType=${ searchParams.orderType }&paymentType=${ searchParams.paymentType }&customerId=${ searchParams.customerId }&export=${ isExport }` );
    // return response.data;
}



export const checkOrderNumber = async ( orderNumber ) => {
    const response = await api.get( `/orders/check-order?orderNumber=${ orderNumber }` );
    return response.data;
};
export const fetchOrderById = async ( id ) => {
    const response = await api.get( `/orders/${ id }` );
    return response.data;
};