import React from "react";
import { Button, Modal } from "antd";
import {
    useGetPaymentById
} from "../../services/Query/PaymentQuery";
import { InfoCircleOutlined, PrinterOutlined } from "@ant-design/icons";
import CustomCard from "../card/CustomCard";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import logo from "../../assets/logo.png";
import styles from "./ViewPayment.module.css"; // Add a CSS module for custom styles

const ViewPayment = ( { show, id, onClose } ) => {
    const { data: payments, isLoading } = useGetPaymentById( id );
    let payment = isLoading ? {} : payments[0];

    const handlePrint = () => {
        const modalContent = document.getElementById( "payment-modal-content" );
        html2canvas( modalContent ).then( ( canvas ) => {
            const imgData = canvas.toDataURL( "image/png" );
            const pdf = new jsPDF( "p", "mm", "a4" );
            pdf.addImage( imgData, "PNG", 0, 0, pdf.internal.pageSize.width, 150 );
            pdf.save( "payment_detail.pdf" );
        } );
    };

    const renderContent = () => (
        <Modal
            title={
                <div className={styles.modalTitle}>
                    <h3>
                        <InfoCircleOutlined /> Payment Detail
                    </h3>
                    <Button key="print" icon={<PrinterOutlined />} onClick={handlePrint}>
                        Print
                    </Button>
                </div>
            }
            visible={show}
            onCancel={onClose}
            className={styles.customModal}
            footer={null}
            width={800}
        >
            <div className={styles.container} id="payment-modal-content">
                <CustomCard>
                    <div className={styles.invoiceHeader}>
                        <div className={styles.logoContainer}>
                            <img src={logo} alt="Company Logo" className={styles.logo} />
                        </div>
                        <div className={styles.invoiceHeaderRight}>
                            <h2>Payment</h2>
                            <p>Date: {moment( payment?.paymentDate ).format( "YYYY-MM-DD" )}</p>
                        </div>
                    </div>
                    <div className={styles.invoiceHeader}>
                        <div className={styles.customerDetails}>
                            <h3>Customer: {payment?.customer?.name}</h3>
                            <p>
                                <strong>Company:</strong> {payment?.company?.name}
                            </p>
                            <strong>Description:</strong>
                            {payment?.description}

                        </div>
                        <div className={styles.paymentDetails}>
                            {/* <h3>Payment Details</h3> */}
                            <p>
                                <strong>Amount:</strong> {payment?.amount}
                            </p>
                            <p>
                                <strong>Category:</strong> {payment?.category?.name}
                            </p>

                        </div>
                    </div>
                </CustomCard>
            </div>
        </Modal>
    );

    return isLoading ? null : <div className={styles.modalLedger}>{renderContent()}</div>;
};

export default ViewPayment;
