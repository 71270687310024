
import React, { useState, useRef, useCallback, useEffect } from "react";
import { Col, DatePicker, Button, Form, Select, Row } from "antd";
import { useDownloadExcel } from "react-export-table-to-excel";
import { FileExcelOutlined, PlusCircleOutlined, PrinterOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import {
  lockerCash,
  drawerCash,
  oppeningData,
  closingData,

} from "../../dummy";
import dayjs from "dayjs";
import "./reports.css";
import html2canvas from 'html2canvas';

import { useGetTodayRecords } from "../../services/Query/OrderQuery";
import { useGetCompanies } from "../../services/Query/CompanyQuery";
import { useGetExpenseById } from "../../services/Query/ExpenseQuery";
import { useGetSummeryByCompanyId, useGetSummeryRemainingByCompanyId } from "../../services/Query/SummeryQuery";
import { useUserProfile } from "../../services/Query/UserQuery";
import CustomCard from "../../components/card/CustomCard";

const DailySummaryReport = () => {
  const [drawerAmounts, setDrawerAmounts] = useState( {} );
  const [lockerAmounts, setLockerAmounts] = useState( {} );
  const { data: companies } = useGetCompanies();
  const { data: profile } = useUserProfile( true );
  const [selectedCompany, setSelectedCompany] = useState( companies && companies[0]?.companyName || null );
  const [selectedCompanyId, setSelectedCompanyId] = useState( companies && companies[0]._id || profile?.defaultCompany );
  const componentRef = useRef();
  const { data: dailyData, isLoading, error } = useGetTodayRecords( selectedCompanyId );
  const [searchTerm, setSearchTerm] = useState( "" );
  const { data: summery, isLoading: isLoadingSummery } = useGetSummeryByCompanyId( selectedCompanyId );
  const { data: summeryRemianing, isLoading: isLoadingSummeryRemaining } = useGetSummeryRemainingByCompanyId( selectedCompanyId );


  const [axcessAmount, setAxcessAmount] = useState( 0 )
  const [shortageAmount, setShortageAmount] = useState( 0 )

  useEffect( () => {
    if ( companies ) {
      let selectedCompany = companies.find( rec => rec._id === profile.defaultCompany )
      setSelectedCompanyId( profile.defaultCompany )
      setSelectedCompany( selectedCompany?.companyName )
    }
  }, [profile, companies] )
  useEffect( () => {
    const receiptTotal = summery?.receiptResult?.reduce( ( sum, entry ) => sum + entry.amount, 0 ) || 0;
    let totalAmount = calculateTotal( drawerAmounts ) + calculateTotal( lockerAmounts )
    if ( totalAmount - receiptTotal > 0 ) {
      setAxcessAmount( totalAmount - receiptTotal )
      setShortageAmount( 0 )
    } else {
      setAxcessAmount( 0 )
      setShortageAmount( Math.abs( totalAmount - receiptTotal ) )
    }
  }, [summery, drawerAmounts, lockerAmounts] )
  const paymentTotal = summery?.paymentResult?.reduce( ( sum, entry ) => sum + entry.amount, 0 );
  const receiptTotal = summery?.receiptResult?.reduce( ( sum, entry ) => sum + entry.amount, 0 );
  const paymentRemainingTotal = summeryRemianing?.paymentResult?.reduce( ( sum, entry ) => sum + entry.amount, 0 );
  const receiptRemainingTotal = summeryRemianing?.receiptResult?.reduce( ( sum, entry ) => sum + entry.amount, 0 );
  // console.log( "summery", JSON.stringify( summery.paymentResult ) )
  const handleSearchChange = ( e ) => {
    setSearchTerm( e );
  };

  if ( isLoading ) {
    return <div>Loading...</div>;
  }

  if ( error ) {
    return <div>An error occurred: {error.message}</div>;
  }
  const { Sale, Purchase } = dailyData[0] || { Sale: {}, Purchase: {} }; // This will handle the default state

  const handleCalculation = ( event, type ) => {
    const notesInput = event.target;
    const denomination = parseInt( notesInput.parentElement.previousSibling.textContent );

    if ( type === "drawer" ) {
      setDrawerAmounts( {
        ...drawerAmounts,
        [denomination]: ( parseFloat( notesInput.value ) || 0 ) * denomination
      } );
    } else if ( type === "locker" ) {
      setLockerAmounts( {
        ...lockerAmounts,
        [denomination]: ( parseFloat( notesInput.value ) || 0 ) * denomination
      } );
    }
  };


  function calculateTotal( obj ) {
    return Object.values( obj ).reduce( ( acc, curr ) => acc + curr, 0 );
  };


  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Col lg={6}>
          <Form.Item>
            <Select
              showSearch
              placeholder="Select Company"
              options={
                Array.isArray( companies )
                  ? companies
                    .map( ( company ) =>
                      company ? { value: company?._id, label: company?.companyName } : null
                    )
                    .filter( Boolean )
                  : []
              }
              onSearch={handleSearchChange}
              filterOption={false}
              onChange={( value ) => {
                let selectedCompany = companies.find( ( rec ) => rec._id === value );
                setSelectedCompanyId( value );
                setSelectedCompany( selectedCompany?.companyName );
              }}
              value={selectedCompanyId}  // Set default value based on profile
            />
          </Form.Item>
        </Col>
        <div style={{ display: "flex", gap: 4 }}>
          <Col>
            {/* <Button
              onClick={()=>onDownload()}
              icon={<FileExcelOutlined />}
              type="primary"
            >
              Export Summary Daily Wise
            </Button> */}
          </Col>

        </div>
      </div>
      <div ref={componentRef} >
        <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
          <Col lg={12} span={6}>
            <CustomCard>
              <h2>Money In</h2>
              <Row>
                <Col lg={8}>
                  <p>Total: {receiptTotal + receiptRemainingTotal}</p>
                </Col>
                <Col lg={8}>
                  <p>Cash: {receiptTotal}</p>
                </Col>
                <Col lg={8}>
                  <p>Credit: {receiptRemainingTotal}</p>
                </Col>
              </Row>
            </CustomCard>
          </Col>
          <Col lg={12} span={6}>
            <CustomCard>
              <h2>Money Out</h2>
              <Row>
                <Col lg={8}>
                  <p>Total: {paymentTotal + paymentRemainingTotal}</p>
                </Col>
                <Col lg={8}>
                  <p>Cash: {paymentTotal}</p>
                </Col>
                <Col lg={8}>
                  <p>Credit: {paymentRemainingTotal}</p>
                </Col>
              </Row>
            </CustomCard>
          </Col>
        </Row>

        <div style={{ gap: 2, display: "flex" }} >
          <table className="table-1">
            <tbody>
              {drawerCash.map( ( e, i ) => (
                <tr key={i}>
                  <td>{e.name}</td>
                  <td>
                    <input
                      type="number"
                      onChange={( ev ) => handleCalculation( ev, 'drawer' )}
                    />
                  </td>
                  <td>{drawerAmounts[e.name] || 0}</td>
                </tr>
              ) )}
              <tr>
                <th colSpan={2}>Total Drawer Cash</th>
                <th align="left">{calculateTotal( drawerAmounts )}</th>
              </tr>
            </tbody>
          </table>

          <table className="table-2">
            <tbody>
              {lockerCash.map( ( e, i ) => (
                <tr key={i}>
                  <td>{e.name}</td>
                  <td>
                    <input
                      type="number"
                      onChange={( ev ) => handleCalculation( ev, 'locker' )}
                    />
                  </td>
                  <td colSpan={4}>{lockerAmounts[e.name] || 0}</td>
                </tr>
              ) )}
              <tr>
                <th colSpan={2}>Total Locker Cash</th>
                <th align="left">{calculateTotal( lockerAmounts )}</th>
              </tr>
            </tbody>
          </table>
        </div>
        <table className="table-3">
          <tbody>
            <tr>
              <th>Daily Cash Report {selectedCompany || "NIAZ METAL"}</th>
              <th>{dayjs( new Date() ).format( "YYYY/MM/DD" )}</th>
            </tr>
          </tbody>
        </table>
        <div style={{ gap: 2, display: "flex", marginBottom: 20 }}>
          <table className="table-3">
            <tbody>
              {summery?.receiptResult.map( ( v, i ) => (
                <tr key={i}>
                  <td>{v.value} </td>
                  <td>{v.amount} </td>
                </tr>
              ) )}
              <tr>
                <td>Excess Amount</td>
                <td>
                  {axcessAmount}
                </td>
              </tr>

              {receiptTotal && <tr>
                <th>Total</th>
                <th align="left">{receiptTotal.toFixed( 2 )}</th>
              </tr>}
            </tbody>
          </table>
          <table className="table-3">
            <tbody>
              {summery?.paymentResult.map( ( v, i ) => (
                <tr key={i}>
                  <td>{v.value} </td>
                  <td>{v.amount} </td>
                </tr>
              ) )}
              <tr>
                <td>Shortage Amount</td>
                <td>
                  {shortageAmount}
                </td>
              </tr>
              {paymentTotal && <tr>
                <th>Total</th>
                <th align="left">{paymentTotal.toFixed( 2 )}</th>
              </tr>}
            </tbody>
          </table>
        </div>
        <table className="table-3">
          <tbody>
            <tr>
              <th>Daily Credit Report {selectedCompany || "NIAZ METAL"}</th>
              <th>{dayjs( new Date() ).format( "YYYY/MM/DD" )}</th>
            </tr>
          </tbody>
        </table>
        <div style={{ gap: 2, display: "flex", marginBottom: 20 }}>
          <table className="table-3">
            <tbody>
              {summeryRemianing?.receiptResult.map( ( v, i ) => (
                <tr key={i}>
                  <td>{v.value} </td>
                  <td>{v.amount.toFixed( 2 )} </td>
                </tr>
              ) )}
              {summeryRemianing && <tr>
                <th>Total</th>
                <th align="left">{receiptRemainingTotal.toFixed( 2 )}</th>
              </tr>}
            </tbody>
          </table>
          <table className="table-3">
            <tbody>
              {summeryRemianing?.paymentResult.map( ( v, i ) => (
                <tr key={i}>
                  <td>{v.value} </td>
                  <td>{v.amount.toFixed( 2 )} </td>
                </tr>
              ) )}
              {paymentRemainingTotal && <tr>
                <th>Total</th>
                <th align="left">{paymentRemainingTotal.toFixed( 2 )}</th>
              </tr>}
            </tbody>
          </table>
        </div>
        <div style={{ display: "flex", gap: 2 }}>
          <table className="table-4">
            <tbody>
              <tr />
              <tr>
                <th colSpan={2}>Purchase Data</th>
                <th colSpan={2}>{dayjs( new Date() ).format( "YYYY/MM/DD" )}</th>
              </tr>
              <tr>
                <th>Name</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Avg</th>
              </tr>
              {Purchase && Purchase.products && Purchase.products.map( ( v, i ) => (
                <tr key={i}>
                  <td>{v.name}</td>
                  <td>{v.Quantity.toFixed( 2 )}</td>
                  <td>{v.Price.toFixed( 2 )}</td>
                  <td>{v.avg.toFixed( 2 )}</td>

                </tr>
              ) )}
              {Purchase && Purchase.total && <tr>
                <th>Total</th>
                <th align="left">{Purchase.total.quantity.toFixed( 2 )}</th>
                <th align="left">{Purchase.total.Price.toFixed( 2 )}</th>
                <th align="left">{Purchase.total.avg.toFixed( 2 )}</th>
              </tr>}
            </tbody>
          </table>
          <table className="table-4">
            <tbody>
              <tr />
              <tr>
                <th colSpan={2}>Sale Data</th>
                <th colSpan={2}>{dayjs( new Date() ).format( "YYYY/MM/DD" )}</th>
              </tr>
              <tr>
                <th>Name</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Avg</th>
              </tr>
              {Sale && Sale.products && Sale.products.map( ( v, i ) => (
                <tr key={i}>
                  <td>{v.name}</td>
                  <td>{v.Quantity.toFixed( 2 )}</td>
                  <td>{v.Price.toFixed( 2 )}</td>
                  <td>{v.avg.toFixed( 2 )}</td>
                </tr>
              ) )}
              {Sale && Sale.total && <tr>
                <th>Total</th>
                <th align="left">{Sale.total.quantity.toFixed( 2 )}</th>
                <th align="left">{Sale.total.Price.toFixed( 2 )}</th>
                <th align="left">{Sale.total.avg.toFixed( 2 )}</th>
              </tr>}
            </tbody>
          </table>

        </div>

      </div>

    </div >
  );
};

class PrintableComponent extends React.Component {
  handlePrint = () => {
    const componentRef = this.componentRef;

    html2canvas( componentRef ).then( ( canvas ) => {
      const imageData = canvas.toDataURL( 'image/png' );

      const printWindow = window.open( '', '_blank' );
      printWindow.document.open();
      printWindow.document.write( `
        <html>
          <head>
            <title>Print</title>
          </head>
          <body>
            <img src="${ imageData }" style="zoom:0.3"/>
            <script type="text/javascript">
              window.onload = function() {
                window.print();
                window.onafterprint = function() {
                  window.close();
                }
              }
            </script>
          </body>
        </html>
      `);
      printWindow.document.close();
    } );
  };

  render() {
    return (
      <><div ref={( ref ) => ( this.componentRef = ref )}>
        <DailySummaryReport />
      </div>
        <div style={{ position: "absolute", top: 83, right: 20 }}>
          <Button type="default" icon={<PrinterOutlined />} onClick={this.handlePrint}>Print</Button>
        </div>
      </>
    );
  }
}
export default PrintableComponent