import React, { useEffect } from "react";
import { Input, Select, Form, Modal, message } from "antd";
import { useGetCompanies } from "../../../services/Query/CompanyQuery";

const ProductForm = ( {
  isEdit,
  open,
  onOk,
  onCancel,
  formData,
  handleEditSubmit,
} ) => {
  const { data: companies } = useGetCompanies();
  const [form] = Form.useForm();

  useEffect( () => {
    if ( formData && open ) {
      // Check if formData is present and modal is visible
      form.setFieldsValue( {
        name: formData.name,
        code: formData.code,
        quantity: formData.quantity,
        purchasePrice: formData.purchasePrice,
        salePrice: formData.salePrice,
        unit: formData.unit,
        companyId: formData.companyId,
      } );
    } else {
      form.resetFields(); // Reset form when modal is not visible
    }
  }, [formData, open, form] );

  const handleSubmit = () => {
    form
      .validateFields()
      .then( ( values ) => {
        if ( values.unit === 'KG' ) {
          values.salePrice = values.salePrice * 1000;
          values.purchasePrice = values.purchasePrice * 1000;
          values.unit = 'MT' 
        }

        if ( isEdit ) {
          handleEditSubmit( values );
        } else {
          onOk( values );
        }

        form.resetFields();
        onCancel();
      } )
      .catch( ( errorInfo ) => {
        message.error( "Please correct the errors in the form." );
      } );
  };


  return (
    <Modal
      title={isEdit ? "Edit Product" : "Add Product"}
      open={open}
      onOk={handleSubmit}
      onCancel={onCancel}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true, message: "Please input the product name!" },
          ]}
        >
          <Input placeholder="Enter product name" />
        </Form.Item>

        <Form.Item
          label="Code"
          name="code"
          // rules={[
          //   { required: true, message: "Please input the product code!" },
          // ]}
        >
          <Input placeholder="Enter product code" />
        </Form.Item>

        <Form.Item
          label="Quantity"
          name="quantity"
          // rules={[
          //   { required: true, message: "Please input the Quantity" },
          //   {
          //     pattern: /^[0-9]+(\.[0-9]{1,2})?$/,
          //     message: "Quantity should be a valid float or integer!",
          //   },
          // ]}
        >
          <Input placeholder="Enter product quantity" />
        </Form.Item>

        <Form.Item
          label="Sale Price"
          name="salePrice"
          // rules={[
          //   { required: true, message: "Please input the sale price!" },
          //   {
          //     pattern: /^[0-9]+(\.[0-9]{1,2})?$/,
          //     message: "Sale Price should be a valid float or integer!",
          //   },
          // ]}
        >
          <Input placeholder="Enter sale price" />
        </Form.Item>

        <Form.Item
          label="Purchase Price"
          name="purchasePrice"
          // rules={[
          //   { required: true, message: "Please input the purchase price!" },
          //   {
          //     pattern: /^[0-9]+(\.[0-9]{1,2})?$/,
          //     message: "Purchase Price should be a valid float or integer!",
          //   },
          // ]}
        >
          <Input placeholder="Enter purchase price" />
        </Form.Item>

        <Form.Item
          label="Unit"
          name="unit"
          // rules={[{ required: true, message: "Please select a unit!" }]}
        >
          <Select placeholder="Select a unit">
            <Select.Option value="MT">MT</Select.Option>
            <Select.Option value="KG">KG</Select.Option>
          </Select>
        </Form.Item>


        {/* <Form.Item>
                <Button type="primary" onClick={handleFormSubmit}>
                    Submit
                </Button>
            </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default ProductForm;
