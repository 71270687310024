import api from "../../utils/interceptors/api";

export const addReminder = (data) => {
    return api.post(`/reminders`, data);
}

export const fetchReminders = async () => {
    const response = await api.get('/reminders');
    return response.data;
};

export const updateReminder = (reminderId, reminderData) => {
    return api.put(`/reminders/${reminderId}`, reminderData);
}

export const deleteReminder = async (reminderId) => {
    const response = await api.delete(`/reminders/${reminderId}`);
    return response.data;
}
