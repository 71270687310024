import axios from "axios";
import api from "../../utils/interceptors/api";

let baseUrl = process.env.REACT_APP_BASE_URL

export const login = ( data ) => {
  return axios.post( `${ baseUrl }/users/login`, data );
}
export const register = ( data ) => {
  return axios.post( `${ baseUrl }/users/register`, data );
}

export const fetchUserProfile = async () => {
  const response = await api.get( '/users/profile' );
  return response.data;
};
export const fetchProfile = async () => {
  const response = await api.get( '/users/profile-details' );
  return response.data;
};
export const updateUser = async (userId, userData) => {
  const response = await api.put(`/users/${userId}`, userData);
  return response.data;
};


