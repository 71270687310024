import { Button, Space, Table, Spin, Alert, Tag } from "antd";
import { useGetLedger } from "../../services/Query/OrderQuery";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function LedgerTable( { selectedCustomer } ) {
    const navigate = useNavigate()
    const { data, isLoading, isError } = useGetLedger( selectedCustomer );
    const [serialNumber, setSerialNumber] = useState( 0 );

    // const filteredData = selectedCustomer
    //     ? data.filter( entry => entry._id === selectedCustomer ) // Assuming each entry has a 'customerId' field 
    //     : data;

    const columns = [
        {
            title: 'SN',
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            render: ( text, record, index ) => index + 1 + serialNumber, // Render the serial number starting from 1
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Total Transactions',
            dataIndex: 'transactions',
        },
        {
            title: 'Opening Balance',
            dataIndex: 'openingBalance',
            render: ( balance ) => {
                let formattedBalance = Math.abs( parseFloat( balance ) ).toFixed( 2 );

                if ( balance < 0 ) {
                    return (
                        <>
                            {formattedBalance}
                            <Tag color="green">: Receivable</Tag>
                        </>
                    );
                } else if ( balance > 0 ) {
                    return (
                        <>
                            {formattedBalance}
                            <Tag color="red">: Payable</Tag>
                        </>
                    );
                }

                return formattedBalance; // in case it's zero
            }

        },

        {
            title: 'Balance',
            dataIndex: 'balance',
            render: ( balance ) => {
                let formattedBalance = Math.abs( parseFloat( balance ) ).toFixed( 2 );

                if ( balance < 0 ) {
                    return (
                        <>
                            {formattedBalance}
                            <Tag color="green">: Receivable</Tag>
                        </>
                    );
                } else if ( balance > 0 ) {
                    return (
                        <>
                            {formattedBalance}
                            <Tag color="red">: Payable</Tag>
                        </>
                    );
                }

                return formattedBalance; // in case it's zero
            }

        },

        // {
        //     title: 'Action',
        //     key: 'action',
        //     render: () => (
        //         <Space size="middle">
        //             <Button>See all transactions</Button>
        //         </Space>
        //     ),
        // },
    ];


    if ( isLoading ) {
        return <Spin size="large" />;
    }

    if ( isError ) {
        return <Alert message="Error fetching ledger data" type="error" />;
    }

    return <Table
        dataSource={data}
        columns={columns}
        onRow={( record ) => ( {
            onClick: () => {
                console.log( "opening balance", record.openingBalance )
                navigate( `/ledger/details/${ record._id }`,
                    {
                        state:
                        {
                            balance: record.balance,
                            openingBalance: record.openingBalance
                        }
                    } );
            },
        } )}
        pagination={{
            onChange: ( page, pageSize ) => {
                const startSerial = ( page - 1 ) * pageSize;
                setSerialNumber( startSerial );
            },
        }}
    />;
}

export default LedgerTable;
