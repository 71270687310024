import React, { useState } from 'react';
import { Table, Input, Button, Modal, message, Form, Popconfirm, Switch, Space } from 'antd';
import { SearchOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import UserForm from './components/UserForm';
import CustomCard from '../../components/card/CustomCard';

// import './User.css';  // You can create a separate CSS similar to Role.css for styling, if needed.

const User = () => {
    const [isVisible, setIsVisible] = useState( false );
    const [formData, setFormData] = useState( {} );
    const [isEdit, setIsEdit] = useState( false );
    const [form] = Form.useForm();
    // For demo, I'm using static data. You'll replace this with API data.
    const users = [
        {
            _id: '1',
            name: 'John Doe',
            email: 'john@example.com',
            contact: '1234567890',
            address: '123 Main St',
            emiratesId: 'UAE1234',
            expiryDate: '2023-12-31',
            accountStatus: true
        }
        //... add other users
    ];

    const openModal = ( record ) => {
        if ( record ) {
            setFormData( record );
            setIsEdit( true );
            setIsVisible( true );
        } else {
            setIsEdit( false );
            setFormData( {} );
            setIsVisible( true );
        }
    };

    const handleSubmit = () => {
        form.validateFields().then( values => {
            if ( isEdit ) {
                // Implement update logic
                message.success( "User updated successfully" );
            } else {
                // Implement add logic
                message.success( "User added successfully" );
            }
            closeModal();
        } );
    };

    const closeModal = () => {
        setIsVisible( false );
        setIsEdit( false );
        form.resetFields();
    };

    const handleDelete = ( key ) => {
        // Implement delete logic
        message.success( "User deleted successfully!" );
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Status',
            dataIndex: 'accountStatus',
            render: status => (
                <Switch checked={status} checkedChildren="Active" unCheckedChildren="Inactive" disabled />
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: ( text, record ) => (
                <Space size="middle">
                    <EditOutlined onClick={() => openModal( record )} />
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete( record._id )}>
                        <DeleteOutlined style={{ color: 'red' }} />
                    </Popconfirm>
                </Space>
            ),
        }
    ];

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                <Input placeholder="Search User" prefix={<SearchOutlined />} style={{ width: 200 }} />
                <Button type="primary" onClick={() => openModal()}>Add User</Button>
            </div>
            <CustomCard>
                <Table columns={columns} dataSource={users} />
            </CustomCard>
            <Modal
                title={isEdit ? "Edit User" : "Add User"}
                open={isVisible}
                onOk={handleSubmit}
                onCancel={closeModal}
            >
                <UserForm formData={formData} form={form} />
            </Modal>
        </div>
    );
};

export default User;
