// userReducer.js
const initialState = {
    id: null,
    name: null,
    email: null,
  };
  
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_USER_DETAILS':
        return {
          ...state,
          ...action.payload,
        };
      case 'CLEAR_USER_DETAILS':
        return initialState;
      default:
        return state;
    }
  };
  
  export default userReducer;
  