import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate, Outlet } from "react-router-dom";
import Customers from "./pages/customer/Customers";
import Products from "./pages/product/Products";
import Dashboard from "./pages/dashboard/Dashboard";
import Sales from "./pages/sales/Sales";
import SalesDetails from "./pages/sales/SalesDetails";
import Login from "./pages/login/Login";
import { Layout } from "antd";
import CustomHeader from "./components/header/CustomHeader";
import Sidebar from './components/sidebar/Sidebar';
import FooterComponent from './components/footer/FooterComponent';
import './App.css'
import Order from './pages/order/Order';
import OrderList from './pages/order/OrderList';
import Reminders from './pages/reminder/Reminder';
import Transactions from './pages/transaction/Transactions';
import Ledger from './pages/ledger/Ledger';
import Expense from './pages/expenses/Expenses';
import Roles from './pages/roles/Role';
import User from './pages/users/User';
import Companies from './pages/company/Companies';
import OrderReport from './pages/report/orderReport';
import DailySummaryReport from './pages/report/DailySummaryReport';
import MonthlySummaryReport from './pages/report/MonthlySummaryReport';
import BalanceSetting from './pages/balance/BalanceSetting';
import Receipt from './pages/reciepts/Reciepts';
import Payment from './pages/payments/Payments';
import LedgerDetail from './pages/ledger/LedgerDetail';
import Profile from './pages/users/profile/Profile';
import SignUp from './pages/register/SignUp.jsx';
import ExpenseReport from './pages/report/ExpenseReport.jsx';
const { Content } = Layout;


const LayoutRoute = ( { children } ) => {
    const location = useLocation();
    const [collapsed, setCollapsed] = useState( false );
    const [darkMode, setDarkMode] = useState( false );
    const isAuthenticated = !!localStorage.getItem( 'token' );
    console.log( "isAuthenticated", isAuthenticated )
    if ( !isAuthenticated ) {
        return <Navigate to="/login" replace />;
    }


    const pageTitle = location.pathname.substring( 1 ).charAt( 0 ).toUpperCase() + location.pathname.slice( 2 ) || 'Home';

    const headerWidth = collapsed ? 'calc(100% - 80px)' : 'calc(100% - 200px)'; // Assuming sidebar is 80px when collapsed and 200px when expanded
    const headerLeft = collapsed ? '80px' : '200px';
    const headerStyle = { position: 'fixed', width: headerWidth, left: headerLeft, zIndex: 1000 }
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sidebar
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                darkMode={darkMode}
                setDarkMode={setDarkMode}
            />
            <Layout className={darkMode ? 'dark-theme content-transition' : 'light-theme content-transition'}
                style={{ marginLeft: collapsed ? 80 : 200 }}

            >
                <CustomHeader pageTitle={pageTitle} headerStyle={headerStyle} />
                <Content style={{ margin: '84px 16px 0', overflow: 'initial' }}>
                    <div className="site-layout-content">
                        <Outlet />
                    </div>
                </Content>
                <FooterComponent />
            </Layout>
        </Layout>
    );
};

const App = () => (
    <Router>
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />

            <Route path="/" element={<LayoutRoute />}>
                <Route index element={<Dashboard />} />
                <Route path="products" element={<Products />} />
                <Route path="customers" element={<Customers />} />
                <Route path="sales" element={<Sales />} />
                <Route path="checkout" element={<SalesDetails />} />
                <Route path="orders" element={<OrderList />} />
                <Route path="order-details" element={<Order />} />
                <Route path="reminders" element={<Reminders />} />
                <Route path="transactions" element={<Transactions />} />
                <Route path="ledger" element={<Ledger />} />
                <Route path="ledger/details/:id" element={<LedgerDetail />} />
                <Route path="expenses" element={<Expense />} />
                <Route path="companies" element={< Companies />} />
                <Route path="settings/balance" element={<BalanceSetting />} />
                <Route path="settings/roles" element={<Roles />} />
                <Route path="settings/users" element={<User />} />
                <Route path="report/order" element={<OrderReport />} />
                <Route path="report/expenses" element={<ExpenseReport />} />
                <Route path='report/daily' element={<DailySummaryReport />} />
                <Route path='finance/receipts' element={<Receipt />} />
                <Route path='finance/payments' element={<Payment />} />
                <Route path="profile" element={<Profile />} />
                {/* <Route path='summeries/monthly' element={<MonthlySummaryReport />} /> */}
            </Route>
        </Routes>
    </Router>
);

export default App;
