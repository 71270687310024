import React, { useEffect, useState } from 'react';
import { Form, Row, Col, InputNumber, Select, Checkbox } from 'antd';
const { Option } = Select;

// Styles
const fullWidthStyle = { width: '100%' };
const formTitleStyle = {
    marginBottom: 20,
    fontSize: 24,
    fontWeight: 'bold'
};
const formItemStyle = {
    marginBottom: 16
};

// Helper Component: Input
const AmountInput = ( { name, label, onChange, defaultValue = 0, placeholder, disabled = false, rules = [], } ) => {
    console.log( "default value", defaultValue )
    return (
        <Form.Item name={name} label={label} style={formItemStyle} rules={rules}>
            <InputNumber
                min={0}
                style={fullWidthStyle}
                onChange={onChange}
                defaultValue={defaultValue}
                placeholder={placeholder}
                disabled={disabled}
            />
        </Form.Item>
    );
}

const PaymentDetailsForm = ( {
    form,
    setIsTaxable,
    setAmountPaid,
    initialValues,
    disabled = false,
    setPaymentType,
    paymentType
} ) => {
    const [amountPaidDisable, setAmountDisable] = useState( false )
    const paymentTypes = ['Cash', 'Credit', 'Bank'];

    const handlePaymentTypeChange = ( value ) => {
        setPaymentType( value );
        // Check if the selected payment type is "Credit"
        if ( value === "Credit" ) {
            setAmountDisable( true )
            // Disable the "Amount Paid" input
            // form.setFieldsValue( { amountPaid: 0 } ); // Set the value to 0
            // form.setFields( [{ name: "amountPaid", value: 0 }] ); // Set the field as disabled
        } else {
            setAmountDisable( false )

        }
    };

    //   useEffect(() => {
    //     form.setFieldsValue({ paymentType: "Cash" });
    //   }, [form]);
    useEffect( () => {
        form.setFieldsValue( { paymentType: 'Cash' } );
    }, [form] );
    console.log( initialValues )
    return (
        <Form
            form={form}
            initialValues={initialValues}
            layout="vertical"
            disabled={disabled}
        >
            <h2 style={formTitleStyle}>Payment Details</h2>

            <Row gutter={32}>
                <Col lg={8} md={24} sm={24} xs={24}>
                    <AmountInput
                        name="amountPaid"
                        label="Amount Paid"
                        defaultValue={form.getFieldValue( 'totalAmount' )}
                        onChange={value => setAmountPaid( value )}
                        placeholder="0"
                        rules={[
                            // {
                            //     validator: ( _, value ) => {
                            //         if ( value > initialValues.totalAmount ) {
                            //             return Promise.reject( 'Paid amount cannot be greater than the total amount.' );
                            //         }
                            //         return Promise.resolve();
                            //     }
                            // }
                        ]}
                        disabled={amountPaidDisable}
                    />
                </Col>
                <Col lg={8} md={24} sm={24} xs={24}>
                    <AmountInput name="subTotal" label="Sub Total" disabled />
                </Col>
                <Col lg={8} md={24} sm={24} xs={24}>
                    <Form.Item
                        name="isTaxable"
                        valuePropName="checked"
                        style={{
                            ...formItemStyle,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: '100%'
                        }}
                    >
                        <Checkbox onChange={e => setIsTaxable( e.target.checked )}>VAT</Checkbox>
                    </Form.Item>
                </Col>

            </Row>

            <Row gutter={32}>
                <Col lg={8} md={24} sm={24} xs={24}>
                    <AmountInput name="taxAmount" label="Tax Amount" disabled />
                </Col>
                <Col lg={8} md={24} sm={24} xs={24}>
                    <AmountInput name="totalAmount" label="Net Amount" disabled />
                </Col>
                <Col lg={8} md={24} sm={24} xs={24}>
                    <AmountInput
                        name="amountRemaining"
                        label="Amount Remaining"
                        defaultValue={0}
                        disabled />
                </Col>
            </Row>

            <Row gutter={32}>
                <Col lg={8} md={24} sm={24} xs={24}>
                    <Form.Item
                        name="paymentType"
                        label="Payment Type"
                        rules={[{ required: true, message: 'Please select a payment type!' }]}
                        style={formItemStyle}
                    >
                        {/* <Select style={fullWidthStyle}
                            placeholder="Select payment type"
                            
                        // defaultValue="Cash"
                        > */}
                        <Select
                            style={fullWidthStyle}
                            placeholder="Select payment type"
                            onChange={handlePaymentTypeChange}
                        >
                            {paymentTypes.map( type => (
                                <Option key={type} value={type}>{type}</Option>
                            ) )}
                        </Select>
                    </Form.Item>

                </Col>

            </Row>
        </Form>
    );
};

// export default PaymentDetailsForm;
export default React.memo( PaymentDetailsForm );
