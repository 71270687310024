import api from "../../utils/interceptors/api";

export const createOrUpdateMetrics = (data) => {
    return api.post(`/metrics`, data);
}

export const fetchMetricsByCompanyId = async (companyId) => {
    const response = await api.get(`/metrics/${companyId}`);
    return response.data;
};
