import React, { useState } from 'react';
import { Button, message } from 'antd';
import PaymentForm from './components/PaymentForm';
import PaymentTable from './components/PaymentTable';
import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import CustomCard from '../../components/card/CustomCard';
import { useAddPayment, useDeletePayment, useGetPayments, useUpdatePayment } from '../../services/Query/PaymentQuery';
import './Payments.css'

const Payment = () => {
    const [payments, setPayments] = useState( [] );
    const createPaymentMutation = useAddPayment();
    const updatePaymentMutation = useUpdatePayment();
    const deletePaymentMutation = useDeletePayment();
    const [selectedRow, setSelectedRow] = useState( null );
    const [isEdit, setIsEdit] = useState( false );

    const handleAddPayment = ( payment ) => {
        createPaymentMutation.mutate( payment, {
            onSuccess: () => {
                message.success( "Payment added successfully" );
                // You can refresh the payment list or close a modal here if needed
            },
            onError: () => {
                message.error( "Something went wrong while adding data" );
            }
        } );
    };

    const handleDeletePayment = ( id ) => {
        deletePaymentMutation.mutate( id, {
            onSuccess: () => {
                message.success( "Payment deleted successfully" );
                // Refresh the payment list or handle other side effects
            },
            onError: () => {
                message.error( "Error deleting the payment" );
            }
        } );
    };

    const handleEditPayment = ( record ) => {
        console.log( "record========>", record )
        updatePaymentMutation.mutate( { paymentId: selectedRow._id, paymentData: { ...record } }, {
            onSuccess: () => {
                message.success( "Payment updated successfully" );
                setIsEdit( false )
            },
            onError: () => {
                message.error( "Error updating the payment" );
                setIsEdit( false )

            }
        } );
    };

    return (
        <div>
            <CustomCard>
                <PaymentForm
                    onAddPayment={handleAddPayment}
                    selectedRow={selectedRow}
                    onEdit={handleEditPayment}
                    isEdit={isEdit}
                />
            </CustomCard>
            <CustomCard>
                <PaymentTable
                    onDelete={handleDeletePayment}
                    setSelectedRow={setSelectedRow}
                    setIsEdit={setIsEdit}
                />
            </CustomCard>
        </div>
    );
};

export default Payment;
