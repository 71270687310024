import { useMutation, useQuery, useQueryClient } from "react-query";
import { addExpenseCategory, fetchExpenseCategories } from "../APICalls/ExpenseCategory";

export const useAddExpenseCategory = () => {
    const queryClient = useQueryClient();

    return useMutation( addExpenseCategory, {
        onSuccess: () => {
            queryClient.invalidateQueries( 'expenseCategories' );
        }
    } );
};

export const useFetchExpenseCategories = () => {
    return useQuery( 'expenseCategories', fetchExpenseCategories );
};
