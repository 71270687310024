import axios from "axios";
import api from "../../utils/interceptors/api";

export const addCustomer = ( data ) => {
    return api.post( `/customers`, data );
}

export const fetchCustomers = async ( page, pageSize, searchParams ) => {
    const response = await api.get( `/customers?page=${ page }&limit=${ pageSize }&name=${ searchParams.name }&id=${ searchParams.id }&validity=${ searchParams.validity }` );
    return response.data;
};
export const getEmiratesIDStatus = async () => {
    const response = await api.get( '/customers/getEmiratesIDStatus' );
    return response.data;
};

export const updateCustomer = ( customerId, customerData ) => {
    return api.put( `/customers/${ customerId }`, customerData );
}
export const deleteCustomer = async ( customerId ) => {
    const response = await api.delete( `/customers/${ customerId }` );
    return response.data;
}
export const fetchAllCustomers = async ( searchTerm ) => {
    const response = await api.get( `/customers/all?search=${ searchTerm }` );
    return response.data;
};
export const fetchCustomer = async (customerId) => {
    const response = await api.get(`/customers/${customerId}`);
    return response.data;
  };