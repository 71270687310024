import api from "../../utils/interceptors/api";

export const addPayment = (data) => {
    return api.post(`/payments`, data);
};

export const fetchPayments = async () => {
    const response = await api.get('/payments');
    return response.data;
};

export const updatePayment = (paymentId, paymentData) => {
    return api.put(`/payments/${paymentId}`, paymentData);
};

export const deletePayment = async (paymentId) => {
    const response = await api.delete(`/payments/${paymentId}`);
    return response.data;
};

export const fetchPaymentById = async ( id ) => {
    const response = await api.get( `/payments/${ id }` );
    return response.data;
};