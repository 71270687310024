import { Input, Button, Table, Space, Popconfirm, message, Tag } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import CustomCard from '../../components/card/CustomCard';
import { Link, useNavigate } from 'react-router-dom';
import { useGetOrders, useDeleteOrder } from '../../services/Query/OrderQuery';
import moment from 'moment/moment';
import { useState } from 'react';
import { deleteOrder } from '../../services/APICalls/OrderService';
const OrderList = () => {
    const [selectedOrder, setSelectedOrder] = useState( {} )
    const [isView, setIsView] = useState( false )
    const [serialNumber, setSerialNumber] = useState( 0 );
    const [searchText, setSearchText] = useState()
    const { orders, isLoading: orderLoading, isError: orderEror } = useGetOrders( searchText );
    // console.log("orders===>",orders)
    const navigate = useNavigate()
    const deleteOrderMutation = useDeleteOrder();
    const handleSearch = ( e ) => {
        console.log( "e========>", e.target.value )
        setSearchText( e.target.value )
    }
    // Placeholder functions for actions
    const handleView = ( record ) => {
        // console.log("View==============>",record)
        // setSelectedOrder( prev => ( { ...record } ) );
        // setIsView( true )
        navigate( "/order-details", { state: { isView: true, selectedOrder: { ...record } } } )
        // alert( `Viewing order with ID: ${ record.orderId }` );
    }


    const handleUpdate = ( record ) => {
        // setIsView( false )
        // setSelectedOrder( prev => ( { ...prev, ...record } ) );
        navigate( "/order-details", { state: { isEdit: true, selectedOrder: { ...record } } } )
    }

    const handleDelete = async ( record ) => {
        try {
            await deleteOrderMutation.mutateAsync( record._id );
            message.success( "Order deleted successfully" )
        } catch ( error ) {
            message.error( "Error deleting order" )

        }

    }

    const columns = [
        {
            title: 'SN',
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            render: ( text, record, index ) => index + 1 + serialNumber,
        },
        {
            title: 'Slip Number',
            dataIndex: 'orderNumber',
            key: 'orderNumber',
        },
        {
            title: 'Order Type',
            dataIndex: 'orderType',
            key: 'orderType',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: ( text, record ) => (
                <div>
                    {record.items.map( ( item, index ) => (
                        <div key={index}>
                            {item.name}
                        </div>
                    ) )}
                </div>
            ),
        },

        {
            title: 'Total Amount',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
        },
        {
            title: 'Amount Paid',
            dataIndex: 'amountPaid',
            key: 'amountPaid',
        },
        {
            title: 'Balance',
            dataIndex: 'amountRemaining',
            key: 'amountRemaining',
            render: ( rec, record ) => {

                if ( record.orderType == "Sale" ) {
                    return <Tag color="green">{rec}</Tag>
                } else {
                    return <Tag color="red">{rec}</Tag>
                }
            }
        },

        {
            title: 'Order Date',
            dataIndex: 'orderDate',
            key: 'orderDate',
            render: date => moment( date ).format( 'MMMM D, YYYY, h:mm A' )

        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            key: 'createdBy',

        },
        {
            title: 'Action',
            key: 'action',
            render: ( text, record ) => (
                <Space size="middle">
                    <EyeOutlined onClick={() => handleView( record )} />
                    <EditOutlined style={{ color: 'orange', cursor: 'pointer' }} onClick={() => handleUpdate( record )} />

                    <Popconfirm
                        title="Are you sure you want to delete this order?"
                        onConfirm={() => handleDelete( record )}
                        onCancel={( e ) => e.stopPropagation()}  // To prevent any further action like row click
                    >
                        <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <Input
                    placeholder="Search Order By Slip Number"
                    style={{ width: 300 }}
                    onChange={( e ) => handleSearch( e )}
                    value={searchText}
                    suffix={<SearchOutlined />}
                />
                <Link to="/order-details">
                    <Button type="primary">Create Order</Button>
                </Link>
            </div>

            <CustomCard>
                <Table
                    columns={columns}
                    dataSource={orders}
                    rowKey="_id"
                    pagination={{
                        onChange: ( page, pageSize ) => {
                            // Calculate the starting serial number for the current page
                            const startSerial = ( page - 1 ) * pageSize;

                            // Update the serial number in the state or wherever you store it
                            // This assumes you have a state variable for serialNumber
                            // Make sure to handle state appropriately (use useState, etc.)
                            setSerialNumber( startSerial );
                        },
                    }}
                />
            </CustomCard>
        </div>
    );
};

export default OrderList;
