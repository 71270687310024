import { useMutation, useQuery, useQueryClient } from "react-query";
import { fetchRoles, addRole, updateRole, deleteRole } from "../APICalls/RoleService";

export const useAddRole = () => {
    const queryClient = useQueryClient();
    return useMutation( addRole, {
        onSuccess: () => {
            // After a successful mutation, invalidate the 'roles' query to refetch the data
            queryClient.invalidateQueries( 'roles' );
        }
    } );
}
export const useGetRoles = () => {
    return useQuery( 'roles', fetchRoles );
}

export const useUpdateRole = () => {
    const queryClient = useQueryClient();

    return useMutation( (data) => updateRole(data.roleId, data.roleData), {
        onSuccess: () => {
            // Invalidate the 'roles' query to refetch data after updating
            queryClient.invalidateQueries( 'roles' );
        }
    } );
}

export const useDeleteRole = () => {
    const queryClient = useQueryClient();

    return useMutation(
        roleId => deleteRole(roleId),
        {
            onSuccess: () => {
                // Invalidate the 'roles' query to refetch data after deletion
                queryClient.invalidateQueries('roles');
            }
        }
    );
}
