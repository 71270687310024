import React from 'react';
import { Table, Space, Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const ProductTable = ( { productDetails, onEdit, onRemove, isView, orderType } ) => {
    console.log( "sanaullah mama", productDetails )
    const baseColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            render: ( text ) => parseFloat( text ).toFixed( 2 ), // Format as floating-point with two decimal places
        },
        {
            title: 'Price',
            dataIndex: 'price',
            render: ( text ) => parseFloat( text ).toFixed( 2 ), // Format as floating-point with two decimal places
        },
        {
            title: 'Total',
            dataIndex: 'total',
            render: ( text ) => parseFloat( text ).toFixed( 2 ), // Format as floating-point with two decimal places
        },
        {
            title: 'Unit',
            dataIndex: 'unit',
        },
    ];

    const actionColumn = {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: ( text, record ) => (
            <Space size="middle">
                <a onClick={() => onEdit( record )}>
                    <EditOutlined style={{ color: '#1890ff' }} />
                </a>
                <Popconfirm title="Are you sure you want to remove?" onConfirm={() => onRemove( record.product )}>
                    <DeleteOutlined style={{ color: '#ff4d4f' }} />
                </Popconfirm>
            </Space>
        ),
    };

    const columns = isView ? baseColumns : [...baseColumns, actionColumn];

    return <Table columns={columns} dataSource={productDetails} rowKey="key" pagination={false} />;
};

export default ProductTable;
