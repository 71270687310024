import React, { useEffect, useState } from 'react';
import { Row, Col, Form, InputNumber, Button, Select, message, Modal, Input, Space } from 'antd';
import { PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useAddProduct } from '../../../services/Query/ProductsQuery';

const ProductForm = ( props ) => {
    const [form] = Form.useForm();
    const [newProduct, setNewProduct] = useState( '' );
    const [isModalVisible, setModalVisible] = useState( false )
    const createProductMutation = useAddProduct();

    const { onAddProduct, products, editedProduct, isEdit = false, orderType = "Sale" } = props;
    const handleProductChange = ( productId ) => {
        const selectedProduct = products.find( product => product._id === productId );
        // if ( orderType === "Sale" && selectedProduct ) {
        //     form.setFieldsValue( { price: selectedProduct.salePrice } );
        // } else if ( orderType !== "Sale" && selectedProduct ) {  // Assuming the only other type is "Purchase"
        //     form.setFieldsValue( { price: selectedProduct.purchasePrice } );
        // }
    };
    const customFilter = ( input, option ) => {
        const optionLabel = option.label?.toLowerCase();
        const inputLower = input?.toLowerCase();
        return optionLabel && inputLower ? optionLabel.includes( inputLower ) : false;
    };


    const handleAddNewProduct = () => {
        createProductMutation.mutate( { name: newProduct }, {
            onSuccess: () => {
                message.success( "Category added successfully" );
                setModalVisible( false );
                setNewProduct( "" )
            },
            onError: () => {
                message.error( "Product Already Added" );
                setModalVisible( false );
                setNewProduct( "" )
            }
        } );
    };

    useEffect( () => {
        if ( editedProduct && isEdit ) {
            const initialPrice = orderType === "Sale" ? editedProduct.salePrice : editedProduct.purchasePrice;
            form.setFieldsValue( {
                product: editedProduct.product,
                price: initialPrice,  // Use the initial price based on orderType
                quantity: editedProduct.quantity,
                unit: editedProduct.unit
            } );
        }
    }, [editedProduct, isEdit] );


    useEffect( () => {
        let productSelected = form.getFieldValue( "product" );
        if ( productSelected ) {
            handleProductChange( productSelected )
        }
        console.log( "simran orderType", orderType )
    }, [orderType] );

    const handleAdd = () => {
        form.validateFields()
            .then( values => {
                if ( values ) {
                    console.log( "values", values );
                    if ( isEdit ) {
                        onAddProduct( values, true );

                    } else {
                        onAddProduct( values );

                    }

                    form.resetFields();
                }
            } )
            .catch( info => {
                console.log( 'Form validation failed:', info );
            } );
    };

    return (
        <Form form={form}>
            <Row gutter={24}>
                <Col xl={5} lg={5} md={12} sm={24}>
                    <Form.Item
                        name="product"
                        label="Product"
                        rules={[{ required: true, message: 'Please select a product!' }]}
                        noStyle={isEdit}
                    >
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={customFilter}
                            onChange={handleProductChange}
                            disabled={isEdit}  // The Select component remains disabled when editing.
                            options={products?.map( rec => ( { value: rec._id, label: rec.name } ) )}
                            suffixIcon={
                                <PlusCircleOutlined
                                    style={{ cursor: 'pointer', color: '#1890ff' }}
                                    onClick={() => setModalVisible( true )}
                                />
                            }

                        />
                    </Form.Item>
                </Col>

                <Col xl={5} lg={5} md={12} sm={24}>
                    <Form.Item
                        name="quantity"
                        label="Quantity"
                        rules={[{ required: true, message: 'Please enter a quantity!' }]}
                    >
                        <InputNumber placeholder="1" min={1} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col xl={5} lg={5} md={12} sm={24}>
                    <Form.Item
                        name="price"
                        label="Price"
                        rules={[{ required: true, message: 'Please enter a price!' }]}
                    >
                        <InputNumber placeholder="0.00" min={0} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col xl={5} lg={5} md={12} sm={24}>
                    <Form.Item
                        name="unit"
                        label="Unit"
                        initialValue="MT"
                        rules={[{ required: true, message: 'Please select a unit!' }]}
                    >
                        <Select style={{ width: '100%' }}>
                            <Select.Option value="MT">MT</Select.Option>
                            <Select.Option value="KG">KG</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xl={4} lg={4} md={12} sm={24}>
                    <Form.Item>
                        <Button type="primary" icon={<PlusOutlined />} onClick={handleAdd}>
                            {isEdit ? "Update" : "Add"}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
            <Modal
                title="Add new Product"
                visible={isModalVisible}
                onCancel={() => setModalVisible( false )}
                footer={null}
            >
                <Form layout="vertical">
                    <Form.Item label="Product Name" required>
                        <Input
                            placeholder="Enter Product name"
                            value={newProduct}
                            onChange={e => setNewProduct( e.target.value )}
                        />
                    </Form.Item>
                    <Space align="end">
                        <Button onClick={() => setModalVisible( false )}>
                            Cancel
                        </Button>
                        <Button type="primary" onClick={handleAddNewProduct}>
                            Add Product
                        </Button>
                    </Space>
                </Form>
            </Modal>
        </Form>

    );
};

export default ProductForm;
