import React from 'react';
import { Form, Input, DatePicker, Switch, Select } from 'antd';
import { useGetRoles } from '../../../services/Query/RoleQuery';
const { Option } = Select;
const UserForm = ( { formData, form } ) => {
    const { data: roles, isLoading, isError } = useGetRoles()
    return (
        <Form layout="vertical" form={form}>
            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input the name!' }]}>
                <Input placeholder="Enter name" />
            </Form.Item>
            <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input the email!' }]}>
                <Input placeholder="Enter email" />
            </Form.Item>
            <Form.Item label="Contact" name="contact">
                <Input placeholder="Enter contact" />
            </Form.Item>
            <Form.Item label="Address" name="address">
                <Input placeholder="Enter address" />
            </Form.Item>
            <Form.Item label="Emirates ID" name="emiratesId">
                <Input placeholder="Enter Emirates ID" />
            </Form.Item>
            <Form.Item label="Role" name="roleId" rules={[{ required: true, message: 'Please select a role!' }]}>
                <Select placeholder="Select a role">
                    {roles && roles.map( role => (
                        <Option key={role._id} value={role._id}>{role.title}</Option>
                    ) )}
                </Select>
            </Form.Item>
            <Form.Item label="Expiry Date" name="expiryDate">
                <DatePicker />
            </Form.Item>
            <Form.Item label="Account Status" name="accountStatus" valuePropName="checked">
                <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
            </Form.Item>
            {/* You can add more form items here as needed */}
        </Form>
    );
}

export default UserForm;
