import { useState, useRef } from 'react';
import { Col, DatePicker, Button, } from 'antd';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { FileExcelOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import './reports.css'
const { RangePicker } = DatePicker;


const MonthlySummaryReport = () => {
    const tableMonthly = useRef(null);
    const [searchParams, setSearchParams] = useState({
        dateFrom: '',
        dateTo: ''
    })

    const notes = [
        {
            name: '1000',
            count: '0',
            total: '0',
        },
        {
            name: '500',
            count: '20',
            total: '10000',
        },
        {
            name: '200',
            count: '0',
            total: '0',
        },
        {
            name: '100',
            count: '2',
            total: '200',
        },
        {
            name: '50',
            count: '57',
            total: '2850',
        },
        {
            name: '20',
            count: '0',
            total: '0',
        },
        {
            name: '10',
            count: '38',
            total: '380',
        },
        {
            name: '5',
            count: '42',
            total: '210',
        },
    ]

    const drawercash = [
        {
            name: '1000',
            count: '0',
            total: '0',
        },
        {
            name: '500',
            count: '0',
            total: '0',
        },
        {
            name: '200',
            count: '0',
            total: '0',
        },
        {
            name: '100',
            count: '0',
            total: '0',
        },
        {
            name: '50',
            count: '0',
            total: '0',
        },
        {
            name: '20',
            count: '0',
            total: '0',
        },
        {
            name: '10',
            count: '300',
            total: '3000',
        },
        {
            name: '5',
            count: '700',
            total: '3500',
        },
    ]

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableMonthly.current,
        filename: "Monthly Summary Report",
        sheet: `monthly_summary_report${new Date().getTime()}.csv`
    });

    return (
        <div>
            <div style={{ display: "flex", justifyContent: 'space-between', marginBottom: '20px' }}>
                <Col >
                    <RangePicker
                        format="YYYY-MM-DD"
                        onChange={(dates, dateStrings) => {
                            setSearchParams({
                                ...searchParams,
                                dateFrom: dateStrings[0],
                                dateTo: dateStrings[1]
                            });
                        }}
                        style={{ width: '300px' }}
                    />
                </Col>
                <Col>
                    <Button onClick={onDownload} icon={<FileExcelOutlined />} type='primary'> Export Summary Monthly Wise</Button>
                </Col>
            </div>
            <div ref={tableMonthly}>
                <div style={{ gap: 4, display: "flex" }}>
                    <table className='table-1'>
                        <tbody>
                            <tr>
                                <th colSpan={3} align='left'>Denomination</th>
                            </tr>
                            {notes.map((e) => (
                                <tr>
                                    <td>{e.name}</td>
                                    <td>{e.count}</td>
                                    <td>{e.total}</td>
                                </tr>
                            ))}
                            <tr>
                                <th >Total</th>
                                <th>1818</th>
                                <th align='left'>13640</th>
                            </tr>
                            <tr>
                                <th colSpan={2}>Drawer cash</th>
                                <th align='left'>15458</th>
                            </tr>

                        </tbody>
                    </table>
                    <table className='table-2'>
                        <tbody>
                            {drawercash.map((e) => (
                                <tr>
                                    <td>{e.name}</td>
                                    <td>{e.count}</td>
                                    <td>{e.total}</td>
                                </tr>
                            ))}
                            <tr>
                                <th colSpan={2}>Locker cash</th>
                                <th align='left'>6500</th>
                            </tr>
                            <tr>

                            </tr>
                        </tbody>
                    </table>
                </div>
                <table className='table-3'>
                    <tbody>
                        <tr />
                        <tr>
                            <th colSpan={3}>Daily Report Gulf Start</th>
                            <th colSpan={1}>{dayjs(new Date()).format('YYYY/MM/DD')}</th>
                        </tr>
                        <tr>
                            <td>PARTICULAR</td>

                            <td>AMOUNT</td>

                            <td>PARTICULAR</td>

                            <td>AMOUNT</td>
                        </tr>
                        <tr>
                            <td>OPENING BALANCE</td>

                            <td>AMOUNT</td>

                            <td>CLOSING BALANCE</td>

                            <td>AMOUNT</td>
                        </tr>
                        <tr>
                            <td>BANK WITHDRAWAL</td>

                            <td>AMOUNT</td>

                            <td>BANK DEPOSIT</td>

                            <td>AMOUNT</td>
                        </tr>
                        <tr>
                            <td>ADVANCE RECEIVED</td>

                            <td>AMOUNT</td>

                            <td>ADVANCE PAID</td>

                            <td>AMOUNT</td>
                        </tr>
                        <tr>
                            <td>STAFF ADVANCE</td>

                            <td>AMOUNT</td>

                            <td>STAFF ADVANCE</td>

                            <td>AMOUNT</td>
                        </tr>
                        <tr>
                            <td>COMPANY EXP ADV REFUND</td>

                            <td>AMOUNT</td>

                            <td>COMPANY EXP ADV PAID</td>

                            <td>AMOUNT</td>
                        </tr>
                        <tr>
                            <td>SALES</td>

                            <td>AMOUNT</td>

                            <td>PURCHASE</td>

                            <td>AMOUNT</td>
                        </tr>
                        <tr>
                            <td>OTHER INCOME</td>

                            <td>AMOUNT</td>

                            <td>EXPENSES</td>

                            <td>AMOUNT</td>
                        </tr>
                        <tr>
                            <td>EXCESS</td>

                            <td>AMOUNT</td>

                            <td>SHORTAGE</td>

                            <td>AMOUNT</td>
                        </tr>
                        <tr>
                            <td>OPENING EXCESS</td>

                            <td>AMOUNT</td>

                            <td>OPENING SHORT</td>

                            <td>AMOUNT</td>
                        </tr>
                        <tr>
                            <td>TOTAL</td>

                            <td>AMOUNT</td>

                            <td>TOTAL</td>

                            <td>AMOUNT</td>
                        </tr>
                    </tbody>
                </table>
                <table className='table-4'>
                    <tbody>
                        <tr />
                        <tr>
                            <th colSpan={3}>Daily Summary Gulf Start</th>
                            <th colSpan={1}>{dayjs(new Date()).format('YYYY/MM/DD')}</th>
                        </tr>
                        <tr>
                            <td>PARTICULAR</td>

                            <td>AMOUNT</td>

                            <td>PARTICULAR</td>

                            <td>AMOUNT</td>
                        </tr>
                        <tr>
                            <td>OPENING BALANCE</td>

                            <td>AMOUNT</td>

                            <td>CLOSING BALANCE</td>

                            <td>AMOUNT</td>
                        </tr>
                        <tr>
                            <td>BANK WITHDRAWAL</td>

                            <td>AMOUNT</td>

                            <td>BANK DEPOSIT</td>

                            <td>AMOUNT</td>
                        </tr>
                        <tr>
                            <td>ADVANCE RECEIVED</td>

                            <td>AMOUNT</td>

                            <td>ADVANCE PAID</td>

                            <td>AMOUNT</td>
                        </tr>
                        <tr>
                            <td>STAFF ADVANCE</td>

                            <td>AMOUNT</td>

                            <td>STAFF ADVANCE</td>

                            <td>AMOUNT</td>
                        </tr>
                        <tr>
                            <td>COMPANY EXP ADV REFUND</td>

                            <td>AMOUNT</td>

                            <td>COMPANY EXP ADV PAID</td>

                            <td>AMOUNT</td>
                        </tr>
                        <tr>
                            <td>SALES</td>

                            <td>AMOUNT</td>

                            <td>PURCHASE</td>

                            <td>AMOUNT</td>
                        </tr>
                        <tr>
                            <td>OTHER INCOME</td>

                            <td>AMOUNT</td>

                            <td>EXPENSES</td>

                            <td>AMOUNT</td>
                        </tr>
                        <tr>
                            <td>EXCESS</td>

                            <td>AMOUNT</td>

                            <td>SHORTAGE</td>

                            <td>AMOUNT</td>
                        </tr>
                        <tr>
                            <td>OPENING EXCESS</td>

                            <td>AMOUNT</td>

                            <td>OPENING SHORT</td>

                            <td>AMOUNT</td>
                        </tr>
                        <tr>
                            <td>TOTAL</td>

                            <td>AMOUNT</td>

                            <td>TOTAL</td>

                            <td>AMOUNT</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default MonthlySummaryReport;
