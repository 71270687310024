import { useMutation, useQuery, useQueryClient } from "react-query";
import { fetchTransactions, addTransaction, updateTransaction, deleteTransaction, fetchCustomerTransactions } from "../APICalls/TransactionService";

export const useAddTransaction = () => {
    const queryClient = useQueryClient();
    return useMutation( addTransaction, {
        onSuccess: () => {
            // After a successful mutation, invalidate the 'transactions' query to refetch the data
            queryClient.invalidateQueries( 'transactions' );
        }
    } );
}
export const useGetTransactions = () => {
    return useQuery( 'transactions', fetchTransactions );
}

export const useUpdateTransaction = () => {
    const queryClient = useQueryClient();

    return useMutation( ( data ) => updateTransaction( data.transactionId, data.transactionData ), {
        onSuccess: () => {
            // Invalidate the 'transactions' query to refetch data after updating
            queryClient.invalidateQueries( 'transactions' );
        }
    } );
}

export const useDeleteTransaction = () => {
    const queryClient = useQueryClient();

    return useMutation(
        transactionId => deleteTransaction( transactionId ),
        {
            onSuccess: () => {
                // Invalidate the 'transactions' query to refetch data after deletion
                queryClient.invalidateQueries( 'transactions' );
            }
        }
    );
}

export const useGetFetchCustomerTransactions = ( customerId, companyIds, searchParams ) => {
    return useQuery( ['customerTransactions', customerId, companyIds, searchParams], () => fetchCustomerTransactions( customerId, companyIds, searchParams ) );
};
