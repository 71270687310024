import axios from "axios";
import api from "../../utils/interceptors/api";

let baseUrl = process.env.REACT_APP_BASE_URL

export const addProduct = ( data ) => {
    return api.post( `/products`, data );
}

export const fetchProducts = async ( searchTerm ) => {
    const response = await api.get( `/products?search=${ searchTerm }` );
    return response.data;
};

export const updateProduct = ( productId, productData ) => {
    return api.put( `/products/${ productId }`, productData );
}
export const deleteProduct = async ( productId ) => {
    const response = await api.delete( `/products/${ productId }` );
    return response.data;
}