import { useMutation, useQuery, useQueryClient } from "react-query";
import { addReceiptCategory, fetchReceiptCategories } from "../APICalls/ReceiptCategory";

export const useAddReceiptCategory = () => {
    const queryClient = useQueryClient();

    return useMutation( addReceiptCategory, {
        onSuccess: () => {
            queryClient.invalidateQueries( 'receiptCategories' );
        }
    } );
};

export const useFetchReceiptCategories = () => {
    return useQuery( 'receiptCategories', fetchReceiptCategories );
};
