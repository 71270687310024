import React, { useState } from 'react';
import { Button, message } from 'antd';
import ExpenseForm from './components/ExpenseFrom';
import ExpenseTable from './components/ExpenseTable';
import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import CustomCard from '../../components/card/CustomCard';
import { useAddExpense, useDeleteExpense, useGetExpenses, useUpdateExpense } from '../../services/Query/ExpenseQuery';
import './Expenses.css'
const Expense = () => {
    const [expenses, setExpenses] = useState( [] );
    const [selectedExpense, setSelectedExpense] = useState()
    const [isEdited, setIsEdited] = useState( false )
    const createExpenceMutation = useAddExpense();
    const updateExpenceMutation = useUpdateExpense();
    const deleteExpenceMutation = useDeleteExpense();

    const handleAddExpense = ( expense ) => {
        console.log( expense )
        if ( isEdited ) {
            // If in edit mode, update the expense
            updateExpenceMutation.mutate( expense, {

                onSuccess: () => {
                    message.success( "Expense updated successfully" );
                    setIsEdited( false )
                    // closeModal();
                },
                onError: () => {
                    message.error( "Something went wrong while updating data" );
                }
            } );
        } else {
            // If not in edit mode, add a new expense
            createExpenceMutation.mutate( expense, {
                onSuccess: () => {
                    message.success( "Expense added successfully" );
                    // closeModal();
                },
                onError: () => {
                    message.error( "Something went wrong while adding data" );
                }
            } );
            // setExpenses([...expenses, { key: expenses.length, ...expense }]);
        }
    };

    const handleRemoveExpense = ( record ) => {
        // Typically, you'd make an API call here to delete the expense
        deleteExpenceMutation.mutate( record._id, {
            onSuccess: () => {
                message.success( "Expense deleted successfully" );
            },
            onError: () => {
                message.error( "Something went wrong while deleting data" );
            }
        } );
        // Optimistically update the state
        setExpenses( expenses.filter( expense => expense._id !== record._id ) );
    };

    const handleUpdate = ( rec ) => {
        setSelectedExpense( rec )
        setIsEdited( true )
    }
    console.log( "selected Expenses==========>", selectedExpense )
    return (
        <div>
            <CustomCard>
                <ExpenseForm
                    onAddExpense={handleAddExpense}
                    selectedExpense={selectedExpense}
                    isEdited={isEdited}
                />
            </CustomCard>
            <CustomCard>
                <ExpenseTable
                    onRemove={( rec ) => handleRemoveExpense( rec )}
                    onUpdate={( rec ) => handleUpdate( rec )}
                />
            </CustomCard>
        </div>
    );
};

export default Expense;
