import api from "../../utils/interceptors/api";

export const addRole = (data) => {
    return api.post(`/roles`, data);
}

export const fetchRoles = async () => {
    const response = await api.get('/roles');
    return response.data;
};

export const updateRole = (roleId, roleData) => {
    return api.put(`/roles/${roleId}`, roleData);
}

export const deleteRole = async (roleId) => {
    const response = await api.delete(`/roles/${roleId}`);
    return response.data;
}
