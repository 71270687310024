import React, { useEffect, useState } from 'react';
import { Form, Input, Button, DatePicker, InputNumber, Select, Modal, Space, Row, message, Col, Tag } from 'antd';
import { PlusOutlined, PlusCircleOutlined } from '@ant-design/icons';
import FileUploadComponent from '../../../components/upload/FileUploadComponent';
import { useAddExpenseCategory, useFetchExpenseCategories } from '../../../services/Query/ExpenseCategoryQuery';
import { useAddCompany, useGetCompanies } from '../../../services/Query/CompanyQuery';
import { useAddExpenseSubCategory, useFetchExpenseSubCategories } from '../../../services/Query/ExpenseSubCategoryQuery';
import { useAddCustomer, useGetAllCustomers } from '../../../services/Query/CustomerQuery';
import CustomerModal from '../../customer/Modal/CustomerModal';
import CompanyForm from '../../company/components/CompanyForm';
import dayjs from 'dayjs';

const { Option } = Select;

const ExpenseForm = ( { onAddExpense, onAddCategory, selectedExpense, isEdited } ) => {
    const [form] = Form.useForm();
    const [isModalVisible, setModalVisible] = useState( false );
    const [newCategory, setNewCategory] = useState( '' );
    const [description, setDescription] = useState( '' );
    const [attachments, setAttachments] = useState( [] );
    const { data: categories } = useFetchExpenseCategories();
    const { data: subCategories } = useFetchExpenseSubCategories();
    const useAddCategoryMutate = useAddExpenseCategory();
    const useAddSubCategoryMutate = useAddExpenseSubCategory();

    const [transactionType, setTransactionType] = useState( null );
    const [companyIdCat, setCompanyIdCat] = useState( null );
    const [isSubcategoryModalVisible, setSubcategoryModalVisible] = useState( false );
    const [newSubcategory, setNewSubcategory] = useState( '' );
    const [show, setShow] = useState( false )
    const [searchTerm, setSearchTerm] = useState( "" );
    const { data: customers } = useGetAllCustomers( searchTerm );
    const createCustomerMutation = useAddCustomer();
    const createCompanyMutation = useAddCompany();
    const [isCompanyModal, setCompanyModal] = useState( false );
    const [isDefaultAmountSet, setIsDefaultAmountSet] = useState( false );
    const [selectedCustomer, setSelectedCustomer] = useState()
    const paymentTypes = ['Cash', 'Bank'];
    const handleSubmitCompany = ( formData ) => {
        createCompanyMutation.mutate( formData, {
            onSuccess: () => {
                message.success( "Company added successfully" );
                closeModal();
            },
            onError: () => {
                message.error( "Something went wrong while adding data" );
            }
        } );

    };
    const handleCustomerSearchChange = async ( customerId ) => {
        setSelectedCustomer( customers?.find( rec => rec._id == customerId ) )
        console.log( "CustomerId====>", customers?.find( rec => rec._id == customerId ) )
    };
    const closeModal = () => {
        setCompanyModal( false )
    }
    const handleSubmit = ( formData ) => {
        createCustomerMutation.mutate( formData, {
            onSuccess: () => {
                message.success( "Customer added successfully" )
                onClose();
            },
            onError: () => {
                message.error( "Some thing wrong while adding data" )
            }
        } )
    }
    const onClose = () => {
        setShow( false )
    }

    const { data: companies } = useGetCompanies();
    const handleSearchChange = ( e ) => {
        setSearchTerm( e );
    };
    const handleFormSubmit = ( values ) => {
        if ( isEdited ) {
            onAddExpense( { expenseId: selectedExpense._id, expenseData: { ...values, attachments } } );
            setSelectedCustomer( {} )
        } else {
            onAddExpense( { ...values, attachments } );
            setSelectedCustomer( {} )
        }
        form.resetFields();
        setAttachments( [] );
    };
    const handleAddNewSubcategory = () => {
        useAddSubCategoryMutate.mutate( { name: newSubcategory }, {
            onSuccess: () => {
                // message.success( "Category added successfully" );
                message.success( "Subcategory added successfully!" );
                setModalVisible( false );
                setNewSubcategory( '' );

            },
            onError: () => {
                message.error( "Something went wrong while adding data" );
                setModalVisible( false );
                setNewSubcategory( '' );

            }
        } );
        // Logic to add new subcategory
        // Just a dummy for now
        // message.success( "Subcategory added successfully!" );
        // setSubcategoryModalVisible( false );
        // setNewSubcategory( '' );

    };
    const handleAddNewCategory = () => {
        useAddCategoryMutate.mutate( { name: newCategory, description, transactionType, companyId: companyIdCat }, {
            onSuccess: () => {
                message.success( "Category added successfully" );
                setModalVisible( false );
                setNewCategory( '' );
                setDescription( '' );
                setCompanyIdCat( '' )
                setTransactionType( '' )
            },
            onError: () => {
                message.error( "Something went wrong while adding data" );
                setModalVisible( false );
                setNewCategory( '' );
                setDescription( '' );
                setCompanyIdCat( '' )
                setTransactionType( '' )
            }
        } );
    };
    // Callback function triggered on form values change
    const onValuesChange = ( a, values ) => {
        console.log( "a========>", a, values )
        const { totalAmount, amountPaid } = values;
        if ( "totalAmount" in a ) {
            form.setFieldsValue( {
                amountPaid: totalAmount,
                amountRemaining: 0
            } );
        } else if ( "amountPaid" in a ) {
            const remainingAmount = totalAmount - amountPaid;
            form.setFieldsValue( {
                amountRemaining: remainingAmount,
            } );
        }


    };
    useEffect( () => {
        // console.log("initialFormValues.paymentType:", initialFormValues.paymentType);
        form.setFieldsValue( { paymentType: 'Cash' } );
    }, [form] );
    useEffect( () => {
        console.log( "isEdited", isEdited, selectedExpense );
        if ( isEdited && selectedExpense ) {
            setAttachments( selectedExpense.attachments )
            // Set initial values for the form fields based on selectedExpense
            form.setFieldsValue( {
                expenseDate: dayjs( selectedExpense.expenseDate ),
                companyId: selectedExpense.companyId,
                customerId: selectedExpense.customerId,
                refNumber: selectedExpense.refNumber,
                expenseCategoryId: selectedExpense.expenseCategoryId,
                expenseSubCategoryId: selectedExpense.expenseSubCategoryId,
                expenseDetails: selectedExpense.expenseDetails,
                totalAmount: selectedExpense.totalAmount,
                amountPaid: selectedExpense.amountPaid,
                amountRemaining: selectedExpense.amountRemaining,
                paymentType: selectedExpense.paymentType,

            } );
        }
    }, [isEdited, selectedExpense] );
    const today = dayjs();

    function disabledDate( current ) {
        return current && current.isAfter( today, 'day' );
    }
    console.log( "form=======>", isEdited );
    const customFilterCategory = ( input, option ) => {
        // console.log( "opetion", option )
        const optionLabel = option.label?.toLowerCase();
        const inputLower = input?.toLowerCase();
        return optionLabel && inputLower ? optionLabel.includes( inputLower ) : false;
    };
    const customFilterSubCategory = ( input, option ) => {
        const optionLabel = option.label?.toLowerCase();
        const inputLower = input?.toLowerCase();
        return optionLabel && inputLower ? optionLabel.includes( inputLower ) : false;
    };
    return (
        <>
            {/* <Space direction="vertical" size="large"> */}
            <Form form={form} onFinish={handleFormSubmit} onValuesChange={onValuesChange}>

                <CustomerModal
                    isVisible={show}
                    onUpdate={handleSubmit}
                    onClose={onClose}

                />
                <CompanyForm
                    isVisible={isCompanyModal}
                    handleSubmit={handleSubmitCompany}
                    closeModal={closeModal}
                />
                <Row gutter={16}>
                    {/* Row 1 */}
                    <Col lg={8}>
                        <Form.Item name="expenseDate">
                            <DatePicker style={{ width: '100%' }} defaultValue={today} disabledDate={disabledDate} />
                        </Form.Item>
                    </Col>
                    <Col lg={8}>

                        <Form.Item
                            name="companyId"
                            rules={[{ required: true, message: 'Please select a company!' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Select Company"
                                optionFilterProp="children" // This is important for filtering options
                                options={
                                    Array.isArray( companies )
                                        ? companies.map( company =>
                                            company
                                                ? {
                                                    value: company._id,
                                                    label: company.companyName,
                                                }
                                                : null
                                        ).filter( Boolean )
                                        : []
                                }
                                onSearch={handleSearchChange}
                                defaultValue={isEdited ? selectedExpense.companyId : undefined}
                                filterOption={false}
                                suffixIcon={
                                    <PlusCircleOutlined
                                        style={{
                                            cursor: 'pointer',
                                            color: '#1890ff',
                                        }}
                                        onClick={() => setCompanyModal( true )}
                                    />
                                }
                            />
                        </Form.Item>

                    </Col>
                    <Col lg={8}>
                        <Form.Item
                            label={selectedCustomer?.balance && <Col lg={4}>
                                {selectedCustomer && ( selectedCustomer?.balance > 0 ) ? <Tag color='red'>{selectedCustomer?.balance}</Tag> : <Tag color='green'>{Math.abs( selectedCustomer?.balance )}</Tag>}

                            </Col>}
                            name="customerId"
                            rules={[{ required: false, message: 'Please select a customer!' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Select a customer"
                                options={Array.isArray( customers )
                                    ? customers.map( ( customer ) => ( customer ? { value: customer._id, label: customer.name } : null ) ).filter( Boolean )
                                    : []
                                }
                                onSearch={handleSearchChange}
                                onChange={handleCustomerSearchChange}
                                filterOption={false}
                                suffixIcon={
                                    <PlusCircleOutlined
                                        style={{
                                            cursor: 'pointer',
                                            color: '#1890ff',
                                        }}
                                        onClick={() => setShow( true )}
                                    />
                                }
                            />

                        </Form.Item>

                    </Col>

                </Row>

                <Row gutter={16}>
                    {/* Row 2 */}
                    <Col lg={6}>
                        <Form.Item name="refNumber">
                            <Input placeholder="Reference Number" />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item
                            name="expenseCategoryId"
                            rules={[{ required: true, message: 'Please select an expense category!' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Select an expense category"
                                filterOption={customFilterCategory}
                                options={categories?.map( cat => ( { value: cat._id, label: cat.name } ) )}
                                suffixIcon={
                                    <PlusCircleOutlined
                                        style={{ cursor: 'pointer', color: '#1890ff' }}
                                        onClick={() => setModalVisible( true )}
                                    />
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item
                            name="expenseSubCategoryId"
                            rules={[{ required: true, message: 'Please select an expense subcategory!' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Select an expense subcategory"
                                filterOption={customFilterSubCategory}
                                options={subCategories?.map( cat => ( { value: cat._id, label: cat.name } ) )}
                                suffixIcon={
                                    <PlusCircleOutlined
                                        style={{ cursor: 'pointer', color: '#1890ff' }}
                                        onClick={() => setSubcategoryModalVisible( true )}
                                    />
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item
                            name="expenseDetails"
                            rules={[{ required: true, message: 'Please provide detailed info about the expense!' }]}
                        >
                            <Input placeholder="Expense Details (e.g. Car with plate 88237283 got fuel from XYZ pump)" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    {/* Row 3 */}
                    <Col lg={6}>
                        <Form.Item name="totalAmount" rules={[{ required: true, message: 'Please input the expense amount!' }]}>
                            <InputNumber placeholder="Total Amount" style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="amountPaid" rules={[{ required: true, message: 'Please input the amount paid!' }]}>
                            <InputNumber placeholder="Amount Paid" style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="amountRemaining">
                            <InputNumber placeholder="Remaining Amount" style={{ width: '100%' }} disabled />
                        </Form.Item>
                    </Col>
                    <Col lg={6} md={12} sm={24} xs={24}>
                        <Form.Item
                            name="paymentType"
                            // label="Payment Type"
                            rules={[{ required: true, message: 'Please select a payment type!' }]}
                        // style={formItemStyle}
                        >
                            {/* <Select style={fullWidthStyle}
                            placeholder="Select payment type"
                            
                        // defaultValue="Cash"
                        > */}
                            <Select
                                placeholder="Select payment type"
                                defaultValue="Cash" // Set "Cash" as the default value
                            // onChange={handlePaymentTypeChange}
                            >
                                {paymentTypes.map( type => (
                                    <Option key={type} value={type}>
                                        {type}
                                    </Option>
                                ) )}
                            </Select>

                        </Form.Item>

                    </Col>
                </Row>
                <Row gutter={16}>
                    {/* Row 4 */}
                    <Col lg={18}>
                        <FileUploadComponent
                            attachments={attachments}
                            setAttachments={setAttachments}
                            isEdited={isEdited}
                        />
                    </Col>
                    <Col lg={6}>
                        <Form.Item style={{ display: "flex", justifyContent: "end" }}>
                            <Button type="primary" htmlType="submit">
                                {isEdited ? "Update Expense" : "Add Expense"}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>


            </Form >

            <Modal
                title="Add new category"
                visible={isModalVisible}
                onCancel={() => setModalVisible( false )}
                footer={null}
            >
                <Form layout="vertical">
                    <Form.Item label="Category Name" required>
                        <Input
                            placeholder="Enter category name"
                            value={newCategory}
                            onChange={e => setNewCategory( e.target.value )}
                        />
                    </Form.Item>
                    <Space align="end">
                        <Button onClick={() => setModalVisible( false )}>
                            Cancel
                        </Button>
                        <Button type="primary" onClick={handleAddNewCategory}>
                            Add Category
                        </Button>
                    </Space>
                </Form>
            </Modal>
            <Modal
                title="Add new subcategory"
                visible={isSubcategoryModalVisible}
                onCancel={() => setSubcategoryModalVisible( false )}
                footer={null}
            >
                <Form layout="vertical">
                    <Form.Item label="Subcategory Name" required>
                        <Input
                            placeholder="Enter subcategory name"
                            value={newSubcategory}
                            onChange={e => setNewSubcategory( e.target.value )}
                        />
                    </Form.Item>

                    {/* Add more fields if necessary for the subcategory */}

                    <Space align="end">
                        <Button onClick={() => setSubcategoryModalVisible( false )}>
                            Cancel
                        </Button>
                        <Button type="primary" onClick={handleAddNewSubcategory}>
                            Add Subcategory
                        </Button>
                    </Space>
                </Form>
            </Modal>
            {/* </Space> */}
        </>
    );
}

export default ExpenseForm;
