import React from 'react';
import { Card, Row, Col, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;

const statusColors = {
    "Valid": "#4CAF50", // green
    "Expires Soon": "#FFC107", // amber
    "Expired": "#F44336", // red
};

const EIDStatus = ( { title, value, status } ) => {
    const navigate = useNavigate();
    const handleClick = ( text ) => {
        let validity = ""
        switch ( text ) {
            case "Valid":
                validity = "valid"
                break;
            case "Expires Soon":
                validity = "expiringSoon"
                break;
            case "Expired":
                validity = "expired"
                break;
            default:
                break;
        }
        navigate( "/customers", { state: { validity } } )

    }
    const cardStyles = {
        borderRadius: '50%',
        width: '160px',
        background: "linear-gradient(190deg, #e5e5e5, #f5f5f5)", // Gradient background
        height: '160px',
        border: "none",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: "center",
        boxShadow: "5px 5px 10px #b8b8b8, -5px -5px 10px #ffffff",
        outline: "none",
        cursor: "pointer",
        transition: "all 0.3s ease-in-out",
    };

    return (
        <Col xs={24} sm={12} md={8} lg={8} xl={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
            <Card style={cardStyles} bordered={false} onClick={() => { handleClick( status ) }}>
                <Title level={5} style={{ margin: '5px 0', fontWeight: 'bold', color: statusColors[status] }}>{title}</Title>
                <Text style={{ fontSize: '16px', margin: '5px 0' }}>{value}</Text>
            </Card>
        </Col>
    );
}

const EIDStatusGroup = ( { data } ) => {
    return (
        <div style={{ height: "40vh", display: "flex", justifyItems: "center" }}>
            <Row gutter={[16, 16]} style={{ display: "flex", alignItems: 'center' }}>
                {data.map( ( item, index ) => (
                    <EIDStatus key={index} title={item.title} value={item.value} status={item.status} />
                ) )}
            </Row>
        </div>
    );
}

export default EIDStatusGroup;
