// src/screens/Companies.js

import React, { useState } from 'react';
import { Table, Input, Button, Space, Popconfirm, Form, Modal, message } from 'antd';
import { SearchOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useMutation } from 'react-query';
import axios from 'axios'; // assuming you use axios for API calls
import CustomCard from '../../components/card/CustomCard';
import CompanyForm from './components/CompanyForm'; // You'll need to create this
import { useAddCompany, useDeleteCompany, useGetCompanies, useUpdateCompany } from '../../services/Query/CompanyQuery';

const Companies = () => {
    const [isVisible, setIsVisible] = useState( false );
    const [formData, setFormData] = useState( {} );
    const [isEdit, setIsEdit] = useState( false );
    const [companyId, setCompanyId] = useState( "" );
    const [serialNumber, setSerialNumber] = useState( 0 );
    // Assuming similar hooks like for products
    const { data: companies, isLoading, isError } = useGetCompanies();
    const createCompanyMutation = useAddCompany();
    const updateCompanyMutation = useUpdateCompany();
    const deleteCompanyMutation = useDeleteCompany();

    const [form] = Form.useForm();

    const closeModal = () => {
        setIsVisible( false );
        setIsEdit( false );
    };

    const handleEditSubmit = ( companyData ) => {
        updateCompanyMutation.mutate( { companyId, companyData }, {
            onSuccess: () => {
                // Handle success
                message.success( "Company Updated successfully" )
                closeModal();
                // You might want to reset form, refetch companies, or show a success message
            },
            onError: () => {

                // Handle error
                // You might want to show an error message
                message.error( "Something went wrong while updating data" )
            }
        } );
    };

    const handleSubmit = (companyData) => {
        form.validateFields().then( values => {
            console.log("value-===========>",values)
            createCompanyMutation.mutate( companyData, {
                onSuccess: () => {
                    message.success( "Company added successfully" );
                    closeModal();
                },
                onError: ( error ) => {
                    console.log( error )
                    message.error( "Something went wrong while adding data" );
                    closeModal();

                }
            } );
        } );
    };


    const columns = [
        {
            title: 'SN',
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            render: ( text, record, index ) => index + 1 + serialNumber,
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
        },
        {
            title: 'Company Number',
            dataIndex: 'companyNumber',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            render: ( address ) => {
                return `${ address.street }, ${ address.area }, ${ address.city }`;
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: ( text, record ) => (
                <Space size="middle">
                    <EditOutlined
                        style={{ color: 'orange', cursor: 'pointer' }}
                        onClick={() => openModal( record )}
                    />
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete( record._id )}>
                        <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
                    </Popconfirm>
                </Space>
            ),
        },
    ];


    const openModal = ( record ) => {
        if ( record ) {
            setCompanyId( record._id )          // setCompanyId instead of setProductId
            setFormData( record );
            setIsEdit( true )
        } else {
            setFormData( {} );
        }
        setIsVisible( true );
    };

    const handleDelete = ( key ) => {
        deleteCompanyMutation.mutate( key, {   // deleteCompanyMutation instead of deleteProductMutation
            onSuccess: () => {
                message.success( "Company deleted successfully!" );
            },
            onError: ( error ) => {
                message.error( "Failed to delete company." ); // Updated message
            }
        } );
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                <Input placeholder="Search Company" prefix={<SearchOutlined />} style={{ width: 200 }} />
                <Button type="primary" onClick={() => openModal()}>Add Company</Button>
            </div>
            {isLoading ? <p>Loading....</p> : <CustomCard>
                <Table
                    columns={columns}
                    dataSource={companies}
                    pagination={{
                        onChange: ( page, pageSize ) => {
                            // Calculate the starting serial number for the current page
                            const startSerial = ( page - 1 ) * pageSize;

                            // Update the serial number in the state or wherever you store it
                            // This assumes you have a state variable for serialNumber
                            // Make sure to handle state appropriately (use useState, etc.)
                            setSerialNumber( startSerial );
                        },
                    }}

                />
            </CustomCard>}
            {/* <Modal
                title={isEdit ? "Edit Company" : "Add Company"}
                open={isVisible}
                onOk={isEdit ? () => handleEditSubmit( form.getFieldsValue() ) : handleSubmit}
                onCancel={closeModal}
            >
                <CompanyForm 
                formData={formData} 
                form={form} 
                handleSubmit={isEdit ? handleEditSubmit : handleSubmit} />
            </Modal> */}


            <CompanyForm
                isVisible={isVisible}
                formData={formData}
                handleSubmit={isEdit ? handleEditSubmit : handleSubmit}
                closeModal={closeModal}
                isEdit={isEdit}
            />
        </div>
    );
};

export default Companies;
