import { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { Table, Row, Col, Select, Alert, DatePicker, Button, Typography } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import CustomCard from '../../components/card/CustomCard';
import { useGetCompanies } from '../../services/Query/CompanyQuery';
import { useGetCustomers } from '../../services/Query/CustomerQuery';
import { useGetProducts } from '../../services/Query/ProductsQuery';
import { getExpenseReport } from '../../services/APICalls/ExpenseService'; // Assuming you have an ExpenseService
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text } = Typography;

const ExpenseReport = () => {
    const [customerFilter, setCustomerFilter] = useState( {
        name: '',
        id: '',
        validity: '',
    } );

    const [firstLoad, setFirstLoad] = useState( true );
    const [searchParams, setSearchParams] = useState( {
        dateFrom: '',
        dateTo: '',
        companyId: '',
        expenseCategoryId: '', // Assuming you have an expense category in your parameters
        paymentType: '',
        customerId: '',
    } );
    const { data: companies } = useGetCompanies();
    const { data: customers } = useGetCustomers();
    const { data: products } = useGetProducts();
    const [expenses, setExpenses] = useState( [] );
    let total = 0;
    let remaining = 0;
    let paid = 0;

    for ( const expense of expenses ) {
        total += expense.totalAmount;
        remaining += expense.amountRemaining;
        paid += expense.amountPaid;
    }

    useEffect( () => {
        const timeout = setTimeout( () => {
            setFirstLoad( false );
            getExpenseReport( searchParams )
                .then( ( res ) => {
                    setExpenses( res.data );
                } )
                .catch( ( err ) => {
                    return <Alert message="Error fetching Expense Report. Please try again later." type="error" />;
                } );
        }, firstLoad ? 0 : 1000 );
        return () => {
            clearTimeout( timeout );
        };
    }, [searchParams] );

    const exportReport = () => {
        getExpenseReport( searchParams, 1 )
            .then( ( res ) => {
                const blob = new Blob( [res.data], {
                    type: 'text/csv',
                } );
                const link = document.createElement( 'a' );
                link.setAttribute( 'target', '_blank' );
                link.href = window.URL.createObjectURL( blob );
                link.setAttribute( 'download', `expense_report_${ new Date().getTime() }.csv` );
                document.body.appendChild( link );
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild( link );
            } )
            .catch( ( err ) => {
                return <Alert message="Failed to Export" type="error" />;
            } );
    };

    const columns = [
        // Adjust column names and data index according to your Expense schema
        {
            title: 'SN',
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            render: ( text, record, index ) => index + 1, // Render the serial number starting from 1
        },
        {
            title: 'Ref Number',
            dataIndex: 'refNumber',
            key: 'refNumber',
        },
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            key: 'customerName',
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName',
        },
        {
            title: 'Expense Date',
            dataIndex: 'expenseDate',
            key: 'expenseDate',
            render: ( date ) => moment( date ).format( 'MMMM D, YYYY, h:mm A' ),
        },
        {
            title: 'Expense Type',
            dataIndex: 'expenseType',
            key: 'expenseType',
        },
        {
            title: 'Sub Category',
            dataIndex: 'subCategory',
            key: 'subCategory',
        },
        {
            title: 'Total',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
        },
        {
            title: 'Remaining',
            dataIndex: 'amountRemaining',
            key: 'amountRemaining',
        },
        {
            title: 'Paid',
            dataIndex: 'amountPaid',
            key: 'amountPaid',
        },
    ];

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <Row gutter={12} style={{ gap: 2 }}>
                    {/* Adjust filters according to your Expense schema */}
                    <Col>
                        <Select
                            style={{ width: 150, marginBottom: 10 }}
                            value={searchParams?.companyId}
                            onChange={( value ) => {
                                setSearchParams( { ...searchParams, companyId: value } );
                            }}
                        >
                            <Option value="">Filter By Company</Option>
                            {companies?.map( ( v ) => (
                                <Option key={v?._id} value={v?._id}>
                                    {v?.companyName}
                                </Option>
                            ) )}
                        </Select>
                    </Col>
                    <Col>
                        <Select
                            style={{ width: 150 }}
                            value={searchParams?.customerId}
                            onChange={( value ) => {
                                setSearchParams( { ...searchParams, customerId: value } );
                            }}
                        >
                            <Option value="">Filter By Customer</Option>
                            {customers?.data?.map( ( v ) => (
                                <Option key={v?._id} value={v?._id}>
                                    {v?.name}
                                </Option>
                            ) )}
                        </Select>
                    </Col>
                    {/* Add more filter components as needed */}
                    <Col>
                        <RangePicker
                            format="YYYY-MM-DD"
                            onChange={( dates, dateStrings ) => {
                                setSearchParams( {
                                    ...searchParams,
                                    dateFrom: dateStrings[0],
                                    dateTo: dateStrings[1],
                                } );
                            }}
                            style={{ width: '300px' }}
                        />
                    </Col>
                    <Col>
                        <Button icon={<FileExcelOutlined />} style={{ width: 150 }} type="primary" onClick={exportReport}>
                            Export
                        </Button>
                    </Col>
                </Row>
            </div>
            <CustomCard>
                <Table
                    columns={columns}
                    dataSource={expenses}
                    rowKey="_id"
                    summary={() => (
                        <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={1} />
                                <Table.Summary.Cell index={0} colSpan={1} />
                                <Table.Summary.Cell index={0} colSpan={1} />
                                <Table.Summary.Cell index={0} colSpan={1} />
                                <Table.Summary.Cell index={0} colSpan={1} />
                                <Table.Summary.Cell index={0} colSpan={1} />
                                <Table.Summary.Cell index={0} colSpan={1} />
                                <Table.Summary.Cell index={1} colSpan={1}>
                                    {total &&      <Text style={{ color: "#262622", fontWeight: "bold", fontSize: "16px", width: "400px", wordBreak: "unset" }}>{total.toFixed( 2 )}</Text>}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} colSpan={1}>
                                         <Text style={{ color: "#262622", fontWeight: "bold", fontSize: "16px", width: "400px", wordBreak: "unset" }}>{remaining.toFixed( 2 )}</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} colSpan={1}>
                                         <Text style={{ color: "#262622", fontWeight: "bold", fontSize: "16px", width: "400px", wordBreak: "unset" }}>{paid.toFixed( 2 )}</Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    )}
                />
            </CustomCard>
        </div>
    );
};

export default ExpenseReport;
