import { Card } from "antd";
import  "./Card.css";
function CustomCard( props ) {
    return (
        <Card className={`card-shadow ${props.className}`}>
            {props.children}
        </Card>
    );
}

export default CustomCard;