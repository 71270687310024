import React, { useEffect, useState } from 'react';
import { Table, Input, Button, Space, Popconfirm, Modal, message, Form } from 'antd';
import { SearchOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useMutation } from 'react-query';
import axios from 'axios'; // if you are using axios
import CustomCard from '../../components/card/CustomCard';
import RoleForm from './components/RoleForm';
import { useAddRole, useDeleteRole, useGetRoles, useUpdateRole } from '../../services/Query/RoleQuery';
import './Role.css'
const Roles = () => {
    const [isVisible, setIsVisible] = useState( false );
    const [formData, setFormData] = useState( {} );
    const [isEdit, setIsEdit] = useState( false );
    const [roleId, setRoleId] = useState( "" );
    // Implement useGetRoles, useAddRole, useUpdateRole, and useDeleteRole hooks.
    const { data: roles, isLoading, isError } = useGetRoles();
    const createRoleMutation = useAddRole();
    const updateRoleMutation = useUpdateRole();
    const deleteRoleMutation = useDeleteRole();
    const [form] = Form.useForm();

    const openModal = ( record ) => {
        if ( record ) {
            setRoleId( record._id );
            setFormData( record );
            setIsEdit( true );
            setIsVisible( true );

        } else {
            setIsEdit( false );
            setFormData( {} );
            setIsVisible( true );

        }

    };
    // useEffect(() => {
    //     if (Object.keys(formData).length) {
    //         setIsVisible(true);
    //     }
    // }, [formData]);
    const transformPermissions = ( currentPermissions, allPermissions ) => {
        const nestedPermissions = {};

        Object.entries( allPermissions ).forEach( ( [category, actions] ) => {
            nestedPermissions[category] = {};

            Object.keys( actions ).forEach( action => {
                const key = `${ category }.${ action }`;

                // If the current permission exists in the form data, use that, otherwise default to the original value
                nestedPermissions[category][action] = currentPermissions[key] !== undefined ? currentPermissions[key] : allPermissions[category][action];
            } );
        } );

        return nestedPermissions;
    }

    const handleEditSubmit = ( { title, ...rest } ) => {
        const nestedPermissions = transformPermissions( rest, formData.permissions );
        updateRoleMutation.mutate( { roleId, roleData: { title, permissions: nestedPermissions } }, {
            onSuccess: () => {
                message.success( "Role Updated successfully" );
                closeModal();
                form.resetFields()
            },
            onError: () => {
                message.error( "Something went wrong while updating role" );
            }
        } );
    }

    const handleSubmit = () => {
        form.validateFields().then( values => {
            createRoleMutation.mutate( values, {
                onSuccess: () => {
                    message.success( "Role added successfully" );
                    closeModal();
                },
                onError: () => {
                    message.error( "Something went wrong while adding role" );
                }
            } );
        } );
    };
    const closeModal = () => {
        setIsVisible( false )
        setIsEdit( false )
        form.resetFields()
    }
    const handleDelete = ( key ) => {
        deleteRoleMutation.mutate( key, {
            onSuccess: () => {
                message.success( "Role deleted successfully!" );
            },
            onError: ( error ) => {
                message.error( "Failed to delete role." );
            }
        } );
    };

    const columns = [
        {
            title: 'Role Name',
            dataIndex: 'title',
        },
        {
            title: 'Action',
            key: 'action',
            render: ( text, record ) => (
                <Space size="middle">
                    <EditOutlined
                        style={{ color: 'orange', cursor: 'pointer' }}
                        onClick={() => openModal( record )}
                    />
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete( record._id )}>
                        <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                <Input placeholder="Search Role" prefix={<SearchOutlined />} style={{ width: 200 }} />
                <Button type="primary" onClick={() => openModal()}>Add Role</Button>
            </div>

            {isLoading ? <p>Loading....</p> : <CustomCard>
                <Table columns={columns} dataSource={roles} />
            </CustomCard>
            }

            <Modal
                title={isEdit ? "Edit Role" : "Add Role"}
                open={isVisible}
                onOk={isEdit ? () => handleEditSubmit( form.getFieldsValue() ) : handleSubmit}
                onCancel={closeModal}

            >
                <RoleForm
                    formData={formData}
                    closeModal={() => closeModal()}
                    form={form}
                    isEdit={isEdit}
                // handleSubmit={isEdit ? handleEditSubmit : handleSubmit}
                />
            </Modal>
        </div>
    );
};

export default Roles;
