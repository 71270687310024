import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Select, Table, message } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import CustomCard from '../../components/card/CustomCard';
import BalanceForm from './components/BalanceForm';
import { useCreateOrUpdateMetrics, useGetMetricsByCompanyId } from '../../services/Query/MetricsQuery'
import { useGetCompanies } from '../../services/Query/CompanyQuery';
const { Option } = Select;
const logs = [
    {
        id: 1,
        date: new Date(),
        openingBalance: '1000',
        closingBalance: '1100',
    },

];
const columns = [
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: text => new Date( text ).toLocaleDateString()
    },
    {
        title: 'Opening Balance',
        dataIndex: 'openingBalance',
        key: 'openingBalance',
    },
    {
        title: 'Closing Balance',
        dataIndex: 'closingBalance',
        key: 'closingBalance',
    }
];
const BalanceSetting = () => {
    const [balances, setBalances] = useState( {
        openingBalance: 0,
        closingBalance: 0,
        companyId: "",
    } );
    const [selectedCompanyId, setSelectedCompanyId] = useState( null );  // to store the selected company id

    const { data: companies } = useGetCompanies();  // fetch the list of companies

    useEffect( () => {
        // Set the first company as the default one when the companies are fetched
        if ( companies && companies.length > 0 ) {
            setSelectedCompanyId( companies[0]._id );
        }
    }, [companies] );
    const metricsQuery = useGetMetricsByCompanyId( selectedCompanyId );

    // const createBalanceMutation = useAddBalance();
    // const updateBalanceMutation = useUpdateBalance();
    const createOrUpdateMetricsMutation = useCreateOrUpdateMetrics();


    const handleSetBalance = ( newBalances ) => {

        createOrUpdateMetricsMutation.mutate( newBalances, {
            onSuccess: () => {
                message.success( "Metrics added/updated successfully" );
            },
            onError: ( error ) => {
                message.error( "Something went wrong while adding/updating metrics" );
            }
        } );
        // updateBalanceMutation.mutate(newBalances, {
        //     onSuccess: () => {
        //         message.success("Balances updated successfully");
        //         setBalances(newBalances);  // Update local state
        //     },
        //     onError: () => {
        //         message.error("Something went wrong while updating balances");
        //     }
        // });
    };

    return (
        <div>
            <CustomCard>
                <h2><SettingOutlined /> Balance Settings</h2>
                <BalanceForm currentBalances={balances} onSetBalance={handleSetBalance} />
            </CustomCard>
            <Row>
                <Col lg={6} md={12} sm={24}>
                    <Select
                        style={{ width: '100%', marginTop: '1rem' }}
                        placeholder="Select a company"
                        value={selectedCompanyId}
                        onChange={setSelectedCompanyId}  // update the state when a company is selected
                    >
                        {companies && companies.map( company => (
                            <Option key={company._id} value={company._id}>
                                {company.companyName}
                            </Option>
                        ) )}
                    </Select>
                </Col>

            </Row>
            <CustomCard>
                <h2>Balance Change Log</h2>
                <Table columns={columns} dataSource={[{ ...metricsQuery.data, date: new Date() }] || logs} rowKey="id" />
                {/* <BalanceLog />  // This can display historical data or changes regarding the balances */}
            </CustomCard>
        </div>
    );
};

export default BalanceSetting;
