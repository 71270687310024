import { useMutation, useQuery, useQueryClient } from "react-query";
import { createOrUpdateMetrics, fetchMetricsByCompanyId } from "../APICalls/MetricsService";

export const useCreateOrUpdateMetrics = () => {
    const queryClient = useQueryClient();
    return useMutation( createOrUpdateMetrics, {
        onSuccess: () => {
            queryClient.invalidateQueries( 'metrics' );

        }
    } );
}

export const useGetMetricsByCompanyId = ( companyId ) => {
    return useQuery( ['metrics', companyId], () => fetchMetricsByCompanyId( companyId ) );
}
