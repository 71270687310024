import React from 'react';
import { Button, Tag } from 'antd';
import CustomCard from '../../components/card/CustomCard';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import DetailTable from './components/DetailTable';

const LedgerDetail = () => {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const balance = location?.state?.balance;
    const openingBalance = location?.state?.openingBalance;

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                <Button
                    type="primary"
                    icon={<ArrowLeftOutlined />}
                    onClick={() => navigate( -1 )}
                >
                    Back
                </Button>

                {/* Optionally, display the balance in a visually appealing way */}
                {typeof balance !== 'undefined' && (
                    <Tag color={balance < 0 ? 'green' : 'red'}>
                        {balance > 0 ? 'Payable' : 'Receivable'}: {Math.abs( balance )}
                    </Tag>
                )}

                {/* Uncomment the following lines if you want to add a button */}
                {/* <Button type="primary" onClick={() => { }}>Add LedgerDetail Entry</Button> */}
            </div>
            <CustomCard>
                <DetailTable id={id} balance={balance} openingBalance={openingBalance} />
            </CustomCard>
        </div>
    );
};

export default LedgerDetail;
