import { useMutation, useQuery, useQueryClient } from "react-query";
import { fetchOrders, addOrder, updateOrder, deleteOrder, fetchLedger, getSalesPurchase, salesPurchaseLastWeek, getDashboardKpis, getOrderReport, fetchTodayRecords, checkOrderNumber, fetchOrderById } from "../APICalls/OrderService";

export const useAddOrder = () => {
    const queryClient = useQueryClient();
    return useMutation( addOrder, {
        onSuccess: () => {
            queryClient.invalidateQueries( 'orders' );
        }
    } );
}
export const useGetOrders = (searchText) => {
    const { data: orders, isLoading: orderLoading, isError: orderError } = useQuery(
      ['orders', searchText],
      () => fetchOrders(searchText)
    );
  
    return {
      orders,
      orderLoading,
      orderError,
    };
  };
export const useUpdateOrder = () => {
    const queryClient = useQueryClient();

    return useMutation( ( data ) => updateOrder( data.orderId, data.orderData ), {
        onSuccess: () => {
            // Invalidate the 'Orders' query to refetch data after updating
            queryClient.invalidateQueries( 'orders' );
        }
    } );
}

export const useDeleteOrder = () => {
    const queryClient = useQueryClient();

    return useMutation(
        orderId => deleteOrder( orderId ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries( 'orders' );
            }
        }
    );
}
export const useGetLedger = (id) => {
    // return useQuery( "ledger", fetchLedger );
    return useQuery( ['ledger', id], () => fetchLedger( id ) );
}

export const useGetSalesPurchase = () => {
    return useQuery( "salesPurchase", getSalesPurchase );
}

export const useGetSalesPurchaseLastWeek = () => {
    return useQuery( "salesPurchaseLastWeek", salesPurchaseLastWeek );
}
export const useGetDashboardKpis = () => {
    return useQuery( "dashbaordKpis", getDashboardKpis );
}
export const useGetTodayRecords = ( id ) => {
    return useQuery( ['dailySummeriesData', id], () => fetchTodayRecords( id ) );

}


export const useGetOrderReport = ( page = undefined, searchParams = { dateFrom: '', dateTo: '', companyId: '', productId: '', orderType: '', paymentType: '', customerId: '', export: 0 } ) => {
    return useQuery(
        ['orders', page, searchParams],
        () => getOrderReport( page, searchParams )
    );
}

export const useCheckOrderNumber = ( orderNumber ) => {
    return useQuery( ['checkOrderNumber', orderNumber], () => checkOrderNumber( orderNumber ) );
};
export const useGetOrderById = ( id ) => {
    return useQuery( ['order', id], () => fetchOrderById( id ) );
};