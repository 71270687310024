import React, { useEffect, useState } from 'react';
import { Table, Input, Button, Space, Popconfirm, Popover, message, Select, Row, Col, Tag } from 'antd';
import { SearchOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import CustomerModal from './Modal/CustomerModal';
import { useAddCustomer, useDeleteCustomer, useGetCustomers, useUpdateCustomer } from '../../services/Query/CustomerQuery';
import CustomCard from '../../components/card/CustomCard';
import { debounce } from '../../utils/debounce';
import { useLocation } from 'react-router-dom';
const { Option } = Select;

const Customers = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useState( {
    name: '',
    id: '',
    validity: '',
  } );
  const [pagination, setPagination] = useState( {
    current: 1,
    pageSize: 10,
  } );
  const [expandedRowKeys, setExpandedRowKeys] = useState( [] );
  const [show, setShow] = useState( false )
  const [isEdit, setIsEdit] = useState( false )

  const [selectedRow, setSelectedRow] = useState( {} )
  const { data, isLoading, isError, refetch } = useGetCustomers( pagination.current, pagination.pageSize, searchParams );
  const customers = data?.data;
  const total = data?.total;
  const createCustomerMutation = useAddCustomer();
  const updateCustomerMutation = useUpdateCustomer();
  const deleteCustomerMutation = useDeleteCustomer();

  const handleSubmit = ( formData ) => {
    createCustomerMutation.mutate( formData, {
      onSuccess: () => {
        message.success( "Customer added successfully" )
        onClose();
      },
      onError: () => {
        message.error( "Some thing wrong while adding data" )
        onClose();

      }
    } )
  }
  const handleEditSubmit = ( formData ) => {
    updateCustomerMutation.mutate( { customerId: selectedRow._id, customerData: formData }, {
      onSuccess: () => {
        message.success( "Cutomer Updated successfully" )
        onClose();
      },
      onError: () => {
        message.error( "Some thing wrong while Updating data" )
      }
    } );

  }

  const onClose = () => {
    setShow( false )
    setIsEdit( false )
  }
  const handleExpand = ( expanded, record ) => {
    if ( expanded ) {
      setExpandedRowKeys( [record._id] );
    } else {
      setExpandedRowKeys( [] );
    }
  };
  const columns = [
    {
      title: 'SN',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      render: ( text, record, index ) => index + 1, // Render the serial number starting from 1
      fixed: 'left',
      width: 70

    },
    {
      title: 'Name',
      dataIndex: 'name',
      fixed: 'left',
      width: 200
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    Table.EXPAND_COLUMN,
    {
      title: 'Contacts',
      dataIndex: 'contacts',
      render: contacts => contacts[0]
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      render: ( rec, record ) => {
        console.log( "rec========>", rec, record )
        if ( rec > 0 ) {
          return <Tag color="red">{rec}</Tag>
        } else {

          return <Tag color="green">{Math.abs( rec )}</Tag>
        }
      }
    },
    {
      title: 'Opening Balance',
      dataIndex: 'openingBalance',
      render: ( rec, record ) => {
        console.log( "rec========>", rec, record )
        if ( rec > 0 ) {
          return <Tag color="red">{rec}</Tag>
        } else {

          return <Tag color="green">{Math.abs( rec )}</Tag>
        }
      }
    },
    {
      title: 'Address',
      dataIndex: 'address',
    },
    {
      title: 'Customer Type',
      dataIndex: 'customerType',
    },
    {
      title: 'Emirates ID',
      dataIndex: 'emiratesId',
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      render: date => new Date( date ).toLocaleDateString()  // format the date
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 100,
      render: ( text, record ) => (
        <Space size="middle">
          <EditOutlined
            style={{ color: 'orange', cursor: 'pointer' }}
            onClick={() => {
              setSelectedRow( record );
              setIsEdit( true );
              setShow( true );
            }}
          />
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete( record._id )}>
            <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect( () => {
    if ( location.state?.validity ) {
      setSearchParams( ( prev ) => {
        return {
          ...prev,
          validity: location.state.validity
        }
      } )
    }
  }, [location.state?.validity] )
  const handleDelete = ( key ) => {
    // Implement delete functionality here
    deleteCustomerMutation.mutate( key, {
      onSuccess: () => {
        message.success( "Product deleted successfully!" );
      },
      onError: ( error ) => {
        message.error( "Failed to delete product:" );
      }
    } );
  };
  const delayedSearch = debounce( () => {
    refetch()
  }, 500 );
  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Row gutter={16} justify="space-between">
          <Col xs={24} md={18}>
            <Row gutter={16}>
              <Col xs={24} sm={12} md={8}>
                <Input
                  style={{ width: '100%' }}
                  placeholder="Search by Name"
                  value={searchParams.name}
                  onChange={e => {
                    setSearchParams( prev => ( { ...prev, name: e.target.value } ) );
                    delayedSearch();
                  }}
                />
              </Col>

              <Col xs={24} sm={12} md={8}>
                <Input
                  style={{ width: '100%' }}
                  placeholder="Search by E-ID"
                  value={searchParams.id}
                  onChange={e => {
                    setSearchParams( prev => ( { ...prev, id: e.target.value } ) );
                    delayedSearch();
                  }}
                />
              </Col>

              <Col xs={24} sm={12} md={6}>
                <Select
                  style={{ width: '100%' }}
                  value={searchParams.validity}
                  onChange={value => {
                    setSearchParams( prev => ( { ...prev, validity: value } ) );
                    delayedSearch();
                  }}
                >
                  <Option value="">Select Validity</Option>
                  <Option value="all">All</Option>
                  <Option value="valid">Valid</Option>
                  <Option value="expiringSoon">Expiring Soon</Option>
                  <Option value="expired">Expired</Option>
                </Select>
              </Col>
            </Row>
          </Col>

          <Col xs={24} md={4} lg={4}>
            <Button
              type="primary"
              style={{ width: '100%' }}
              onClick={() => {
                setShow( true )
              }}>
              Add Customer
            </Button>
          </Col>
        </Row>
      </div>
      <CustomerModal
        isVisible={show}
        onUpdate={isEdit ? handleEditSubmit : handleSubmit}
        onClose={onClose}
        data={selectedRow}

      />
      <CustomCard>
        <Table
          columns={columns}
          dataSource={customers}
          scroll={{
            x: 1500
          }}
          expandable={{
            expandedRowRender: ( record ) => {
              return expandedRowKeys.includes( record._id ) && (
                <Popover
                  content={
                    <>
                      {record.contacts.map( ( rec, index ) => (
                        <p key={index} style={{ margin: '10px 0', color: '#333' }}>
                          {rec}
                        </p>
                      ) )}
                    </>
                  }
                  title="Contact Details"
                  open={true}
                >
                  <div style={{ width: '100%' }} />
                </Popover>
              )
            },
            rowExpandable: ( record ) => record.contacts && record.contacts.length > 1,
            onExpand: handleExpand,
          }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: total, // This assumes your backend sends the total number of items customers?.total
            onChange: ( page, pageSize ) => {
              setPagination( {
                current: page,
                pageSize: pageSize,
              } );
            }
          }}
        />
      </CustomCard>

    </div>
  );
};

export default Customers;
