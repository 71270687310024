import api from "../../utils/interceptors/api";

export const addReceiptCategory = (data) => {
    return api.post(`/receiptCategories`, data);
}

export const fetchReceiptCategories = async () => {
    const response = await api.get('/receiptCategories');
    return response.data;
};
