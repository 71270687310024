import React, { useState } from "react";
import {
  Table,
  Input,
  Button,
  Space,
  Popconfirm,
  Form,
  message,
} from "antd";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  useAddProduct,
  useDeleteProduct,
  useGetProducts,
  useUpdateProduct,
} from "../../services/Query/ProductsQuery";
import CustomCard from "../../components/card/CustomCard";
import ProductForm from "./components/ProductForm";

// Dummy data for the table

const Products = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isVisible, setIsVisible] = useState( false );
  const [formData, setFormData] = useState( {} );
  const [isEdit, setIsEdit] = useState( false );
  const [productId, setProductId] = useState( "" );
  const { data: products, isLoading, isError } = useGetProducts(searchTerm);
  const createProductMutation = useAddProduct();
  const updateProductMutation = useUpdateProduct();
  const deleteProductMutation = useDeleteProduct();
  const [form] = Form.useForm();

  const closeModal = () => {
    setIsVisible( false );
    setIsEdit( false );
  };
  const handleEditSubmit = ( productData ) => {
    // console.log("record___", productData);
    updateProductMutation.mutate(
      { productId, productData },
      {
        onSuccess: () => {
          // Handle success
          message.success( "Product Updated successfully" );
          closeModal();
          // You might want to reset form, refetch products, or show a success message
        },
        onError: () => {
          // Handle error
          // You might want to show an error message
          message.error( "Some thing wrong while adding data" );
        },
      }
    );
  };

  const handleSubmit = (values) => {
    // console.log("values___", values);
    createProductMutation.mutate(values, {
      onSuccess: () => {
        message.success("Product added successfully");
        closeModal();
      },
      onError: (error) => {
        if (error.response && error.response.data && error.response.data.error) {
          // Check if the error message contains specific information about the duplicate name
          if (error.response.data.error.includes("Product validation failed")) {
            message.error("Product name is already in use. Please choose a different name.");
          } else {
            message.error("Something went wrong while adding data");
          }
        } else {
          message.error("Something went wrong while adding data");
        }
      },
    });
  };
  

  const handleInputChange = ( e ) => {
    const { name, value } = e.target;
    setFormData( ( prev ) => ( { ...prev, [name]: value } ) );
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
};

  const columns = [
    {
      title: 'SN',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      render: ( text, record, index ) => index + 1, // Render the serial number starting from 1


  },
    {
      title: "Product Name",
      dataIndex: "name",
    },
    {
      title: "Product Code",
      dataIndex: "code",
    },
    {
      title: "Sale Price",
      dataIndex: "salePrice",
    },
    {
      title: "Purchase Price",
      dataIndex: "purchasePrice",
    },
    {
      title: "Action",
      key: "action",
      render: ( text, record ) => (
        <Space size="middle">
          <EditOutlined
            style={{ color: "orange", cursor: "pointer" }}
            onClick={() => {
              setProductId( record._id );
              setFormData( record );
              setIsEdit( true );
              setIsVisible( true );
            }}
          />
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete( record._id )}
          >
            <DeleteOutlined style={{ color: "red", cursor: "pointer" }} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // const openModal = (record) => {
  //   if (record) {
  //     setProductId(record._id);
  //     setFormData(record);
  //     setIsEdit(true);
  //   } else {
  //     setFormData({});
  //   }
  //   setIsVisible(true);
  // };
  const handleDelete = ( key ) => {
    deleteProductMutation.mutate( key, {
      onSuccess: () => {
        // Handle successful deletion, e.g. show a notification or refresh data
        message.success( "Product deleted successfully!" );
      },
      onError: ( error ) => {
        // Handle error, e.g. show a notification
        message.error( "Failed to delete product:" );
      },
    } );
    // Implement delete functionality here
  };

  // function handleSelectChange(fieldName, value) {
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     [fieldName]: value,
  //   }));
  // }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 16,
        }}
      >
        <Input
          placeholder="Search Product"
          prefix={<SearchOutlined />}
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ width: 200 }}
        />
        <Button
          type="primary"
          onClick={() => {
            setFormData( {} );
            setIsVisible( true );
          }}
        >
          Add Product
        </Button>
      </div>
      {isLoading ? (
        <p>Loading....</p>
      ) : (
        <CustomCard>
          <Table columns={columns} dataSource={products} />
        </CustomCard>
      )}

      <ProductForm
        isEdit={isEdit}
        open={isVisible}
        onOk={
          isEdit ? () => handleEditSubmit( form.getFieldValue() ) : handleSubmit
        }
        onCancel={closeModal}
        formData={formData}
        handleEditSubmit={handleEditSubmit}
      />
    </div>
  );
};

export default Products;
