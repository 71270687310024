import { Button, Space, Table, Spin, Alert, Tag, DatePicker, Row, Col, Form, Select } from "antd";
import { useGetLedger } from "../../../services/Query/OrderQuery";
import { useNavigate } from "react-router-dom";
import { useGetFetchCustomerTransactions } from "../../../services/Query/TransactionQuery";
import moment from "moment";
import { FilterOutlined, PrinterOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import ViewOrder from "../../../components/ledger/ViewOrder";
import ViewPayment from "../../../components/payment/ViewPayment";
import ViewReceipt from "../../../components/reciept/ViewReceipt";
import ViewExpense from "../../../components/expense/ViewExpense";
import html2canvas from "html2canvas"; // Import html2canvas
import jsPDF from "jspdf"; // Import jsPDF
import logo from '../../../assets/logo.png'
import { numberToWords } from "../../../utils/numbertowords";
import { useGetProducts } from "../../../services/Query/ProductsQuery";
import { useGetCompanies } from "../../../services/Query/CompanyQuery";
import { useGetCustomer } from "../../../services/Query/CustomerQuery";
import dayjs from "dayjs";
// import XLSX from 'xlsx';
import * as XLSX from 'xlsx';
const { RangePicker } = DatePicker;

function DetailTable( { id, balance, openingBalance } ) {
    // const { data, isLoading, isError } = useGetFetchCustomerTransactions( id );
    const [showOrderDetails, setShowOrderDetails] = useState( false )
    const [showPaymentDetails, setShowPaymentDetails] = useState( false )
    const [showReceiptDetails, setShowReceiptDetails] = useState( false )
    const [showExpenseDetails, setShowExpenseDetails] = useState( false )
    const [expenseId, setExpenseId] = useState( "" )
    const [receiptId, setReceiptId] = useState( "" )
    const [orderId, setOrderId] = useState( "" )
    const [paymentId, setPaymentId] = useState( "" )
    const [serialNumber, setSerialNumber] = useState( 0 );
    const [filteredData, setFilteredData] = useState( [] );
    const { data: products } = useGetProducts();
    const { data: companies } = useGetCompanies()
    const [searchParams, setSearchParams] = useState( {
        dateFrom: '',
        dateTo: '',
    } );
    const [selectedCompanies, setSelectedCompanies] = useState( [] );
    const [allCompanies, setAllCompanies] = useState( [] );
    const { data, isLoading, isError } = useGetFetchCustomerTransactions( id, [...selectedCompanies], searchParams );
    const { data: customer } = useGetCustomer( id );

    // useEffect( () => {
    //     if ( companies && companies.length > 0 ) {
    //         setAllCompanies( companies.map( company => ( { value: company._id, label: company.companyName } ) ) );
    //     }
    // }, [companies] );

    // useEffect( () => {
    //     if ( allCompanies && allCompanies.length > 0 ) {
    //         setSelectedCompanies( allCompanies );
    //     }
    // }, [allCompanies] );

    const columns = [
        {
            title: 'SN',
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            render: ( text, record, index ) => index + 1 + serialNumber,
        },
        {
            title: 'Date || Time',
            dataIndex: 'paymentDate',
            key: 'paymentDate',
            render: ( timestamp ) => moment( timestamp ).format( 'YYYY-MM-DD HH:mm:ss' ),

        },
        {
            title: 'company',
            dataIndex: 'companyName',
            key: 'companyName',
        },
        {
            title: 'Transaction Category',
            dataIndex: 'transactionCategory',
            key: 'transactionCategory',
            filters: [
                { text: 'Payment', value: 'Payment' },
                { text: 'Receipt', value: 'Receipt' },
                { text: 'Order', value: 'Order' },
                { text: 'Expense', value: 'Expense' },
            ],
            onFilter: ( value, record ) => {
                return record.transactionCategory === value
            },
        },

        {
            title: 'Order Type',
            dataIndex: 'orderType',
            key: 'orderType',
            render: text => text || "N/A",
            filters: [
                { text: 'Purchase', value: 'Purchase' },
                { text: 'Sale', value: 'Sale' },

            ],
            onFilter: ( value, record ) => record.orderType === value,


        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: ( text, record ) => {
                if ( record.transactionCategory === 'Order' ) {
                    const order = record?.orders[0];
                    if ( order ) {
                        const items = order?.items?.map( ( item, index ) => (
                            <div key={index}>
                                {products?.find( ( rec ) => rec._id === item.product )?.name}
                            </div>
                        ) );

                        return <div>{items}</div>;
                    }
                } else if ( record.transactionCategory === 'Payment' ) {
                    const payment = record?.payments[0];
                    return <div>{payment?.description}</div>;

                }

                return 'N/A';
            },
        },

        {
            title: 'Payment Type',
            dataIndex: 'paymentType',
            key: 'paymentType',
            filters: [
                { text: 'Cash', value: 'Cash' },
                { text: 'Credit', value: 'Credit' },
                { text: 'Bank', value: 'Bank' }
            ],
            onFilter: ( value, record ) => record.paymentType === value,

        },


        // {
        //   title: 'Update Date',
        //   dataIndex: 'updateDate',
        //   key: 'updateDate',
        // },
        {
            title: 'Total',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
        },
        {
            title: 'Paid/Reciept',
            dataIndex: 'amountPaid',
            key: 'amountPaid',
            render: ( rec, record ) => {
                if ( record.transactionCategory === "Receipt" ) {
                    return <Tag color={'red'}>
                        {rec}
                    </Tag>

                } else if ( record.transactionCategory === "Payment" ) {
                    return <Tag color={'green'}>
                        {rec}
                    </Tag>

                } else {
                    return rec
                }
            }
        },
        // {
        //     title: 'Balance',
        //     dataIndex: 'amountRemaining',
        //     key: 'amountRemaining',
        //     render: ( rec, record, index, dataSource ) => {
        //         console.log( "rec========>", rec, record, index )
        //         if ( record.transactionCategory === "Receipt" ) {
        //             return <Tag color="red">{record.totalAmount}</Tag>
        //         } else if ( record.transactionCategory === "Payment" ) {
        //             return <Tag color="green">{record.totalAmount}</Tag>

        //         } else if ( record.transactionCategory === "Order" ) {
        //             if ( record.orderType == "Sale" ) {
        //                 return <Tag color="green">{rec}</Tag>
        //             } else {
        //                 return <Tag color="red">{rec}</Tag>
        //             }
        //         } else {
        //             return <Tag color="red">{rec}</Tag>
        //         }
        //     }
        // },
        {
            title: 'Balance',
            dataIndex: 'amountRemaining',
            key: 'amountRemaining',
            render: ( rec, record, index, dataSource ) => {
                console.log( "Data array:", data );
                let balance = openingBalance;
                if ( index == 0 ) {
                    return <Tag color={balance > 0 ? 'red' : 'green'}>
                        {Math.abs( balance ).toFixed( 2 )}
                    </Tag>
                } else {

                    let resultTags = [];

                    for ( let i = 0; i <= index; i++ ) {
                        const transaction = data[i];

                        console.log( "Processing transaction at index", i, ":", transaction );

                        switch ( transaction?.transactionCategory ) {
                            case 'Payment':
                                balance -= parseFloat( transaction.totalAmount );
                                break;
                            case 'Receipt':
                                balance += parseFloat( transaction.totalAmount );
                                break;
                            case 'Expense':
                                balance += parseFloat( transaction.amountRemaining );
                                break;
                            case 'Order':
                                if ( transaction.orderType === 'Sale' ) {
                                    balance -= parseFloat( transaction.amountRemaining );
                                } else {
                                    balance += parseFloat( transaction.amountRemaining );
                                }
                                break;
                            // Add more cases if needed
                            default:
                                break;
                        }

                        console.log( "Updated balance:", balance );

                        // Push the JSX element for the current iteration into the resultTags array
                        resultTags.push(
                            <Tag key={i} color={balance > 0 ? 'red' : 'green'}>
                                {Math.abs( balance ).toFixed( 2 )}
                            </Tag>
                        );
                    }

                    console.log( "Final balance:", balance );

                    // Return the array of JSX elements after processing all transactions

                    return resultTags[index - 1];
                }
            }





        }

        // {
        //     title: 'Customer Balance',
        //     dataIndex: 'customerBalance',
        //     key: 'customerBalance',
        //     render: ( rec ) => {
        //         if ( rec > 0 ) {
        //             return <Tag color="red">{rec}</Tag>
        //         } else {
        //             return <Tag color="green">{rec}</Tag>

        //         }
        //     }
        // },

    ]
    function colorToRgb( colorName ) {
        switch ( colorName ) {
            case 'green':
                return '#FF00FF00'; // Green
            case 'red':
                return '#FFFF0000'; // Red
            // Add more color mappings if needed
            default:
                return '#FFFFFFFF'; // Default: White
        }
    }
    const handlePrint = () => {
        const fileName = 'table_data.xlsx';

        // Clone the printable area and create a container
        const container = document.createElement( "div" );
        container.innerHTML = document.getElementById( "printable-area" ).innerHTML;
        // Extract the table data
        const tableRows = container.querySelectorAll( 'tr' );


        const data = Array.from( tableRows ).map( row => {
            const cells = row.querySelectorAll( 'td' );
            return Array.from( cells ).map( cell => {
                // Check if cell contains a span
                const span = cell.querySelector( 'span' );
                if ( span ) {
                    // If span exists, check its class
                    const cellClass = span.className;
                    const backgroundColor = cellClass.includes( 'ant-tag-red' ) ? 'red' : 'green';
                    return { value: `${ cell.innerText } ${ backgroundColor == "red" ? "Payable" : "Receivable" } `, backgroundColor };
                } else {
                    // If span doesn't exist, set backgroundColor as default or handle it as you want
                    return { value: `${ cell.innerText } `, backgroundColor: '' };
                }
            } );
        } );
        console.log( "data=======>", JSON.stringify( data ) )
        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet( data.map( row => row.map( cell => cell.value ) ) );

        // Add styles to cells
        data.forEach( ( row, rowIndex ) => {
            row.forEach( ( cell, cellIndex ) => {
                if ( cell.backgroundColor ) {
                    // Create cell ref e.g., 'A1', 'B2', etc.
                    const cellRef = XLSX.utils.encode_cell( { r: rowIndex, c: cellIndex } );
                    // Get cell style or create one if not exists
                    const cellStyle = worksheet[cellRef] ? worksheet[cellRef].s || {} : {};
                    // Set background color
                    cellStyle.fill = { bgColor: { rgb: colorToRgb( cell.backgroundColor ) } };
                    worksheet[cellRef] = { ...worksheet[cellRef], s: cellStyle };
                }
            } );
        } );
        // Add the worksheet to the workbook
        console.log( "shettttttttt", JSON.stringify( worksheet ) )
        XLSX.utils.book_append_sheet( workbook, worksheet, 'Sheet1' );

        // Export the workbook
        XLSX.writeFile( workbook, 'output.xlsx' );
    };


    // const handlePrint = () => {
    //     const fileName = 'table_data.xlsx';

    //     // Clone the printable area and create a container
    //     const container = document.createElement( "div" );
    //     container.innerHTML = document.getElementById( "printable-area" ).innerHTML;

    //     // Extract the table data
    //     const tableRows = container.querySelectorAll( 'tr' );
    //     const data = Array.from( tableRows ).map( row => {
    //         const cells = row.querySelectorAll( 'td' );
    //         return Array.from( cells ).map( cell => cell.innerText );
    //     } );

    //     // Create a new workbook
    //     const workbook = XLSX.utils.book_new();
    //     const worksheet = XLSX.utils.aoa_to_sheet( data );

    //     // Add the worksheet to the workbook
    //     XLSX.utils.book_append_sheet( workbook, worksheet, 'Table Data' );

    //     // Write the workbook to an Excel file
    //     XLSX.writeFile( workbook, fileName );
    // };
    console.log( "openingBalance", openingBalance )


    // const handlePrint = () => {
    //     const input = document.getElementById( "printable-area" );
    //     const scale = 2; // Adjust the scale factor as needed
    //     const dpi = 300; // Adjust the DPI value for better resolution

    //     html2canvas( input, { scale: scale, dpi: dpi } ).then( ( canvas ) => {
    //         const imgData = canvas.toDataURL( "image/png" );
    //         const pdf = new jsPDF( "p", "mm", "a4" );
    //         pdf.addImage( imgData, "PNG", 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, "", "FAST" );
    //         pdf.save( "table.pdf" );
    //     } );
    // };


    // if ( isLoading ) {
    //     return <Spin size="large" />;
    // }

    if ( isError ) {
        return <Alert message="Error fetching ledger data" type="error" />;
    }
    const handleClick = ( record ) => {
        console.log( "records========>", record )
        // Assuming `record.orderId` is the property containing the orderId in the data
        if ( record && record.orderId ) {
            setShowOrderDetails( true );
            setOrderId( record.orderId );
        } else if ( record && record.paymentId ) {
            setShowPaymentDetails( true );
            setPaymentId( record.paymentId );
        } else if ( record && record.receiptId ) {
            setShowReceiptDetails( true );
            setReceiptId( record.receiptId )
        }
        else if ( record && record.expenseId ) {
            setShowExpenseDetails( true );
            setExpenseId( record.expenseId )
        }

        else {
            // If no orderId is present, you might want to handle this case or show an error message
            console.error( 'No orderId found in the clicked row.' );
        }
    }
    // const summaryRow = () => {
    //     const totalAmount = data.reduce( ( sum, record ) => sum + parseFloat( record.totalAmount ), 0 );
    //     const amountPaid = data.reduce( ( sum, record ) => sum + parseFloat( record.amountPaid ), 0 );
    //     const amountRemaining = data.reduce( ( sum, record ) => sum + parseFloat( record.amountRemaining ), 0 );

    //     return (
    //         <>
    //             <Table.Summary.Row>
    //                 <Table.Summary.Cell index={0} colSpan={6}>Total</Table.Summary.Cell>
    //                 <Table.Summary.Cell index={1}>{totalAmount.toFixed( 2 )}</Table.Summary.Cell>
    //                 <Table.Summary.Cell index={2}>{amountPaid.toFixed( 2 )}</Table.Summary.Cell>
    //                 <Table.Summary.Cell index={3}>{amountRemaining.toFixed( 2 )}</Table.Summary.Cell>
    //             </Table.Summary.Row>
    //         </>
    //     );
    // };
    const summaryRow = () => {
        const filteredData = data; // Replace this with your logic to get the filtered data based on the current filters

        const totalAmount = filteredData.reduce( ( sum, record ) => sum + parseFloat( record.totalAmount ), 0 );
        const amountPaid = filteredData.reduce( ( sum, record ) => sum + parseFloat( record.amountPaid ), 0 );
        const amountRemaining = filteredData.reduce( ( sum, record ) => sum + parseFloat( record.amountRemaining ), 0 );

        const totalReceivable = filteredData.reduce( ( sum, record ) => {
            if ( record.transactionCategory === "Receipt" ) {
                return sum + parseFloat( record.totalAmount );
            } else if ( record.transactionCategory === "Order" && record.orderType !== "Sale" ) {
                return sum + parseFloat( record.amountRemaining );
            } else if ( record.transactionCategory === "Expense" ) {
                return sum + parseFloat( record.amountRemaining );
            }
            return sum;
        }, 0 );

        const totalPayable = filteredData.reduce( ( sum, record ) => {
            if ( record.transactionCategory === "Payment" ) {
                return sum + parseFloat( record.totalAmount );
            } else if ( record.transactionCategory === "Order" && record.orderType === "Sale" ) {
                return sum + parseFloat( record.amountRemaining );
            }
            return sum;
        }, 0 );

        return (
            <>
                <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={7}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>{totalAmount.toFixed( 2 )}</Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>{amountPaid.toFixed( 2 )}</Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                        {totalReceivable < totalPayable ? <Tag color="green">{totalPayable.toFixed( 2 ) - totalReceivable.toFixed( 2 )}</Tag> : <Tag color="red">{totalReceivable.toFixed( 2 ) - totalPayable.toFixed( 2 )}</Tag>}
                    </Table.Summary.Cell>
                    {/* <Table.Summary.Cell index={4}><Tag color="red">{totalReceivable.toFixed( 2 )}</Tag></Table.Summary.Cell>
                <Table.Summary.Cell index={5}><Tag color="green">{totalPayable.toFixed( 2 )}</Tag></Table.Summary.Cell> */}
                </Table.Summary.Row >
            </>
        );
    };
    const today = dayjs();
    function disabledDate( current ) {
        return current && current.isAfter( today, 'day' );
    }

    return <>
        <Row>
            <Col lg={6}  >
                <Form.Item
                    // label="Company"
                    name="companyId"
                    rules={[{ required: true, message: 'Please select a company!' }]}
                >
                    <Select
                        showSearch
                        placeholder="Select Company"
                        mode="multiple"
                        options={companies ? companies.map( company => ( { value: company._id, label: company.companyName } ) ) : []}
                        filterOption={false}
                        onChange={( selectedValues ) => {
                            console.log( "Selected value======>", selectedValues )
                            setSelectedCompanies( selectedValues );
                        }}
                    // defaultValue={allCompanies} // Set default value only if allCompanies is not empty
                    />
                </Form.Item>
            </Col>
            <Col lg={18} >
                <RangePicker
                    format="YYYY-MM-DD"
                    disabledDate={disabledDate}
                    onChange={( dates, dateStrings ) => {
                        setSearchParams( {
                            ...searchParams,
                            dateFrom: dateStrings[0],
                            dateTo: dateStrings[1]
                        } );
                    }}
                    style={{ width: '500px', float: "right" }}
                />
            </Col>
        </Row>
        <div className="model-ledger-sanaullah">
            <ViewOrder
                show={showOrderDetails}
                id={orderId}
                onClose={() => setShowOrderDetails( false )}
            />
            <ViewPayment
                show={showPaymentDetails}
                id={paymentId}
                onClose={() => setShowPaymentDetails( false )}
            />
            <ViewReceipt
                show={showReceiptDetails}
                id={receiptId}
                onClose={() => setShowReceiptDetails( false )}
            />
            <ViewExpense
                show={showExpenseDetails}
                id={expenseId}
                onClose={() => setShowExpenseDetails( false )}
            />

        </div>

        <Space style={{ marginBottom: 16 }}>
            <Button type="primary" icon={<PrinterOutlined />} onClick={handlePrint}>
                Print PDF
            </Button>
        </Space>
        <Row>
            <Col lg={6}>
                Customer: {customer?.name}
            </Col>
            {/* <Col lg={12} style={{ textAlign: 'right' }}>
                Add a div to display today's date
                <div style={{ marginBottom: '8px' }}>Date: {moment().format( 'YYYY-MM-DD' )}</div>
                Add a div to display selected companies
                <div>Selected Companies: {companies
                    .filter( company => selectedCompanies.some( selectedCompany => selectedCompany === company._id ) )
                    .map( company => company.companyName ).join( "," )}</div>
            </Col> */}
        </Row>
        <div id="printable-area">
            {/* <div className="logoContainer">
                <img src={logo} alt="Company Logo" className="logo" />
            </div> */}
            {isLoading ? <div style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center"
            }}><Spin size="large" /></div> : <Table
                dataSource={[{
                    // serialNumber: 0, // You may adjust the serialNumber accordingly
                    // paymentDate: moment().toISOString(),
                    // companyName: 'Opening Balance Company',
                    transactionCategory: 'Opening Balance  ',
                    description: 'Opening Balance Description',
                    // paymentType: 'Credit', // Adjust this as needed
                    totalAmount: openingBalance, // Adjust this as needed
                    amountPaid: 0,
                    amountRemaining: openingBalance, // Adjust this as needed
                    customerBalance: openingBalance
                }, ...data]}
                columns={columns}
                // pagination={{
                //     onChange: ( page, pageSize ) => {
                //         const startSerial = ( page - 1 ) * pageSize;
                //         setSerialNumber( startSerial );
                //     },
                // }}
                onRow={( record ) => ( {
                    onClick: () => handleClick( record ),
                } )}
                virtual
                pagination={false}
                scroll={{
                    y: 400,

                }}
            // summary={summaryRow}
            />
            }
            {typeof balance !== 'undefined' && (
                <Tag id="balance-tag" style={{ float: "right" }} color={balance < 0 ? 'green' : 'red'}>
                    {balance > 0 ? 'Payable' : 'Receivable'}: {Math.abs( balance ).toFixed( 2 )}
                </Tag>
            )}
            {/* {typeof openingBalance !== 'undefined' && (
                <div style={{ float: "left" }}>
                    <span>Opening Balance:</span>
                    <Tag id="openingBalance-tag" color={openingBalance < 0 ? 'green' : 'red'}>
                        {Math.abs( openingBalance ).toFixed( 2 )}
                    </Tag>
                </div>

            )} */}
        </div>

    </>;
}

export default DetailTable;