import { useMutation, useQuery, useQueryClient } from "react-query";
import { fetchReceipts, addReceipt, updateReceipt, deleteReceipt, fetchReceiptById } from "../APICalls/ReceiptService";

export const useAddReceipt = () => {
    const queryClient = useQueryClient();
    return useMutation(addReceipt, {
        onSuccess: () => {
            // After a successful mutation, invalidate the 'receipts' query to refetch the data
            queryClient.invalidateQueries('receipts');
        }
    });
}

export const useGetReceipts = () => {
    return useQuery('receipts', fetchReceipts);
}

export const useUpdateReceipt = () => {
    const queryClient = useQueryClient();

    return useMutation((data) => updateReceipt(data.receiptId, data.receiptData), {
        onSuccess: () => {
            // Invalidate the 'receipts' query to refetch data after updating
            queryClient.invalidateQueries('receipts');
        }
    });
}

export const useDeleteReceipt = () => {
    const queryClient = useQueryClient();

    return useMutation(
        receiptId => deleteReceipt(receiptId),
        {
            onSuccess: () => {
                // Invalidate the 'receipts' query to refetch data after deletion
                queryClient.invalidateQueries('receipts');
            }
        }
    );
}

export const useGetReceiptById = ( id ) => {
    return useQuery( ['receipt', id], () => fetchReceiptById( id ) );
};

