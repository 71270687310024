import api from "../../utils/interceptors/api";

export const addExpenseCategory = (data) => {
    return api.post(`/expenseCategories`, data);
}

export const fetchExpenseCategories = async () => {
    const response = await api.get('/expenseCategories');
    return response.data;
};
