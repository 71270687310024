import React, { useState } from 'react';
import {
    Table, Input, Button, Modal, Form, Select, Badge, Card, Row, Col, Checkbox
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const data = [
    {
        key: '1',
        name: 'Product 1',
        code: '001',
        price: 100
    },
    {
        key: '2',
        name: 'Product 2',
        code: '002',
        price: 200
    },
    {
        key: '3',
        name: 'Product 3',
        code: '003',
        price: 300
    },
    {
        key: '4',
        name: 'Product 4',
        code: '004',
        price: 400
    },
];

const Sales = () => {
    const [visible, setVisible] = useState( false );
    const [form] = Form.useForm();
    const [editedProduct, setEditedProduct] = useState( null );
    const [productDetails, setProductDetails] = useState( [] );
    const navigate = useNavigate();
    const [viewMode, setViewMode] = useState( 'table' );

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Code',
            dataIndex: 'code',
        },
        {
            title: 'Price',
            dataIndex: 'price',
        },
    ];

    const rowSelection = {
        onChange: ( selectedRowKeys, selectedRows ) => {
            setProductDetails( selectedRows.map( row => ( { ...row, ...productDetails.find( pd => pd.key === row.key ) } ) ) );
        },
        onSelect: ( record, selected ) => {
            if ( selected ) {
                form.setFieldsValue( productDetails.find( pd => pd.key === record.key ) || { unit: 'MT', price: '', quantity: 1 } );
                setEditedProduct( record.key );
                setVisible( true );
            }
        },
    };


    const handleOk = () => {
        const values = form.getFieldsValue();
        setProductDetails( productDetails.map( pd => pd.key === editedProduct ? { ...pd, ...values } : pd ) );
        setVisible( false );
    };

    const handleCancel = () => {
        setVisible( false );
    };

    const proceed = () => {
        navigate( '/checkout', { state: { productDetails } } );
    };


    const toggleViewMode = () => {
        setViewMode( prevMode => ( prevMode === 'table' ? 'grid' : 'table' ) );
    };
    const selectProduct = product => {
        // Checking if the product is already selected
        const productExists = productDetails.find(pd => pd.key === product.key);
        
        // Add to productDetails if not exists, else remove
        if (!productExists) {
            setProductDetails([...productDetails, product]);
        } else {
            setProductDetails(productDetails.filter(pd => pd.key !== product.key));
        }
    };
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                <Input placeholder="Search Product" prefix={<SearchOutlined />} style={{ width: 200 }} />
                <Button onClick={toggleViewMode}>
                    {viewMode === 'table' ? 'Switch to Grid View' : 'Switch to Table View'}
                </Button>
            </div>

            {viewMode === 'table' ? (
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={data}
                />
            ) : (
                <Row gutter={[16, 16]}>
                    {data.map( product => (
                        <Col key={product.key} xs={24} sm={12} md={8} lg={6}>
                            <Card
                                title={product.name}
                                extra={
                                    <Checkbox
                                        checked={!!productDetails.find( pd => pd.key === product.key )}
                                        onChange={() => selectProduct( product )}
                                    />
                                }
                            >
                                <p>Code: {product.code}</p>
                                <p>Price: {product.price}</p>
                            </Card>
                        </Col>
                    ) )}
                </Row>

            )}

            <Modal
                title="Edit Product"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                destroyOnClose
            >
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Form.Item
                        name="quantity"
                        label="Quantity"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the quantity!',
                            },
                        ]}
                    >
                        <Input
                            type="number"
                            min="0"
                        />
                    </Form.Item>
                    <Form.Item
                        name="price"
                        label="Price"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the price!',
                            },
                        ]}
                    >
                        <Input
                            type="number"
                            min="0"
                            prefix="$"
                        />
                    </Form.Item>
                    <Form.Item
                        name="unit"
                        label="Unit"
                        rules={[
                            {
                                required: true,
                                message: 'Please select a unit!',
                            },
                        ]}
                    >
                        <Select>
                            <Select.Option value="MT">MT</Select.Option>
                            <Select.Option value="KG">KG</Select.Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>

            <Badge count={productDetails.length} style={{ backgroundColor: '#52c41a' }}>
                <Button type="primary" onClick={proceed} style={{ marginTop: 16 }}>Proceed</Button>
            </Badge>
        </div>
    );
};

export default Sales;
