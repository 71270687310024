import React from 'react';
import { Card, Row, Col, Typography } from 'antd';
import { DollarCircleOutlined, ShoppingOutlined, UserOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const cardStyles = {
    background: "linear-gradient(145deg, #e5e5e5, #f5f5f5)", // Gradient background
    border: "none",
    padding: "20px 40px", // Increased padding
    borderRadius: "20px", // Smoother curves
    boxShadow: "5px 5px 10px #b8b8b8, -5px -5px 10px #ffffff", // Softer shadows
    outline: "none",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    '&:hover': {
        background: "linear-gradient(145deg, #f5f5f5, #e5e5e5)", // Gradient inversion on hover
        boxShadow: "3px 3px 8px #b8b8b8, -3px -3px 8px #ffffff" // Slightly closer shadows on hover
    }
};


const iconStyles = {
    fontSize: '24px',
    marginRight: '16px',
    color: '#83a4d4'
};

// const textStyles = {
//     flex: 1
// };

const KPIMetricCard = ( { title, value, icon } ) => {
    return (
        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card style={cardStyles} bordered={false}>
                <diV>
                    {React.createElement( icon, { style: iconStyles } )}
                </diV>
                <div >
                    <Title level={4} style={{ margin: 0, fontWeight: 'bold', color: '#333' }}>{title}</Title>
                    <Text style={{ fontSize: '18px' }}>{typeof value === 'number' && title !== "Customers" ? `AED ${ value.toLocaleString() }` : value}</Text>
                </div>
            </Card>
        </Col>
    );
}

const KpiCardGroup = ( { data } ) => {
    const iconMapping = {
        "Total Sale": DollarCircleOutlined,
        "Total Purchase": ShoppingOutlined,
        "Customers": UserOutlined,
        "Payable": ArrowDownOutlined,
        "Receivable": ArrowUpOutlined
    };

    return (
        <Row gutter={[16, 16]}>
            {data.map( ( item, index ) => (
                <KPIMetricCard key={index} title={item.title} value={item.value} icon={iconMapping[item.title]} />
            ) )}
        </Row>
    );
}

export default KpiCardGroup;
