import api from "../../utils/interceptors/api";

export const fetchSummeryByCompanyId = async ( id ) => {
    const response = await api.get( `/summery/${ id }` );
    return response.data;
};
export const fetchSummeryRemainingByCompanyId = async ( id ) => {
    const response = await api.get( `/summery/remaining/${ id }` );
    return response.data;
};
