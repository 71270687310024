import api from "../../utils/interceptors/api";

export const addPaymentCategory = (data) => {
    return api.post(`/paymentCategories`, data);
}

export const fetchPaymentCategories = async () => {
    const response = await api.get('/paymentCategories');
    return response.data;
};
