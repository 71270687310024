import { useMutation, useQuery, useQueryClient } from "react-query";
import { addExpenseSubCategory, fetchExpenseSubCategories } from "../APICalls/ExpenseSubCategory";

export const useAddExpenseSubCategory = () => {
    const queryClient = useQueryClient();

    return useMutation( addExpenseSubCategory, {
        onSuccess: () => {
            queryClient.invalidateQueries( 'expenseSubCategories' );
        }
    } );
};

export const useFetchExpenseSubCategories = () => {
    return useQuery( 'expenseSubCategories', fetchExpenseSubCategories );
};
