import { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { Table, Row, Col, Select, Alert, DatePicker, Button, Typography } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import CustomCard from '../../components/card/CustomCard';
import { useGetCompanies } from '../../services/Query/CompanyQuery';
import { useGetCustomers } from '../../services/Query/CustomerQuery';
import { useGetProducts } from '../../services/Query/ProductsQuery';
import { getOrderReport } from '../../services/APICalls/OrderService';
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text } = Typography;

const OrderReport = () => {
    const [customerFilter, setCustomerFilter] = useState( {
        name: '',
        id: '',
        validity: '',
    } );

    const [firstLoad, setFirstLoad] = useState( true );
    const [searchParams, setSearchParams] = useState( {
        dateFrom: '',
        dateTo: '',
        companyId: '',
        productId: '',
        orderType: '',
        paymentType: '',
        customerId: '',
    } );
    const { data: companies } = useGetCompanies()
    const { data: customers } = useGetCustomers( "", "", customerFilter )
    const { data: products } = useGetProducts()
    const [orders, setOrders] = useState( [] )
    let total = 0;
    let remaining = 0;
    let paid = 0;



    for ( const order of orders ) {
        total += order.totalAmount;
        remaining += order.amountRemaining;
        paid += order.amountPaid;
    }


    useEffect( () => {
        const timeout = setTimeout( () => {
            setFirstLoad( false )
            getOrderReport( searchParams )
                .then( ( res ) => {
                    // console.log('orders___', res.data);
                    setOrders( res.data )
                } )
                .catch( err => {
                    return <Alert message="Error fetching Order Report. Please try again later." type="error" />
                } )
        }, firstLoad ? 0 : 1000 );
        return () => {
            clearTimeout( timeout )
        }
    }, [searchParams] )

    const exportReport = () => {
        getOrderReport( searchParams, 1 )
            .then( res => {
                const blob = new Blob( [res.data], {
                    type: 'text/csv'
                } );
                const link = document.createElement( 'a' );
                link.setAttribute( 'target', '_blank' );
                link.href = window.URL.createObjectURL( blob );
                link.setAttribute( 'download', `order_report_${ new Date().getTime() }.csv` );
                document.body.appendChild( link );
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild( link );
            } )
            .catch( err => {
                return <Alert message="Failed to Export" type="error" />
            } )
    }

    const columns = [
        {
            title: 'SN',
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            render: ( text, record, index ) => index + 1, // Render the serial number starting from 1


        },
        {
            title: 'Slip Number',
            dataIndex: 'orderNumber',
            key: 'orderNumber',
        },
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            key: 'customerName',
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName',
        },
        {
            title: 'Order Date',
            dataIndex: 'orderDate',
            key: 'orderDate',
            render: date => moment( date ).format( 'MMMM D, YYYY, h:mm A' )
        },
        {
            title: 'Order Type',
            dataIndex: 'orderType',
            key: 'orderType',

        },
        {
            title: 'Payment Type',
            dataIndex: 'paymentType',
            key: 'paymentType',

        },
        {
            title: 'Total',
            dataIndex: 'totalAmount',
            key: 'totalAmount',

        },
        {
            title: 'Paid/Reciept',
            dataIndex: 'amountPaid',
            key: 'amountPaid',

        },
        {
            title: 'Balance',
            dataIndex: 'amountRemaining',
            key: 'amountRemaining',

        },


    ];

    return (
        <div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <Row gutter={12} style={{ gap: 2, }}>
                    <Col >
                        <Select
                            style={{ width: 150, marginBottom: 10 }}
                            value={searchParams?.companyId}
                            onChange={value => {
                                setSearchParams( { ...searchParams, companyId: value } );
                            }}
                        >
                            <Option value="">Filter By Company</Option>
                            {companies?.map( ( v ) => (
                                <Option key={v?._id} value={v?._id}>{v?.companyName}</Option>
                            ) )}
                        </Select>
                    </Col>
                    <Col>
                        <Select
                            style={{ width: 150 }}
                            value={searchParams?.customerId}
                            onChange={value => {
                                setSearchParams( { ...searchParams, customerId: value } );
                            }}
                        >
                            <Option value="">Filter By Customer</Option>
                            {customers?.data?.map( ( v ) => (
                                <Option key={v?._id} value={v?._id}>{v?.name}</Option>
                            ) )}
                        </Select>
                    </Col>
                    <Col>
                        <Select
                            style={{ width: 150 }}
                            value={searchParams?.orderType}
                            onChange={value => {
                                setSearchParams( { ...searchParams, orderType: value } );
                            }}
                        >
                            <Option key={0} value="">Filter By Order Type</Option>
                            <Option key={1} value="Sale">Sale</Option>
                            <Option key={2} value="Purchase">Purchase</Option>
                        </Select>
                    </Col>
                    <Col>
                        <Select
                            style={{ width: 150 }}
                            value={searchParams?.paymentType}
                            onChange={value => {
                                setSearchParams( { ...searchParams, paymentType: value } );
                            }}
                        >
                            <Option key={0} value="">Filter By Payment Type</Option>
                            <Option key={1} value="Cash">Cash</Option>
                            <Option key={2} value="Credit">Credit</Option>
                        </Select>
                    </Col>
                    <Col >
                        <RangePicker
                            format="YYYY-MM-DD"
                            onChange={( dates, dateStrings ) => {
                                setSearchParams( {
                                    ...searchParams,
                                    dateFrom: dateStrings[0],
                                    dateTo: dateStrings[1]
                                } );
                            }}
                            style={{ width: '300px' }}
                        />
                    </Col>
                    <Col>
                        <Button icon={<FileExcelOutlined />} style={{ width: 150 }} type='primary' onClick={exportReport}> Export</Button>
                    </Col>
                </Row>
            </div>
            <CustomCard>
                <Table
                    columns={columns}
                    dataSource={orders}
                    rowKey="_id"
                    summary={() => {
                        return (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={1} />
                                    <Table.Summary.Cell index={0} colSpan={1} />
                                    <Table.Summary.Cell index={0} colSpan={1} />
                                    <Table.Summary.Cell index={0} colSpan={1} />
                                    <Table.Summary.Cell index={0} colSpan={1} />
                                    <Table.Summary.Cell index={0} colSpan={1} />
                                    <Table.Summary.Cell index={0} colSpan={1} />
                                    <Table.Summary.Cell index={1} colSpan={1}>
                                        {total && <Text style={{ color: "#262622", fontWeight: "bold", fontSize: "16px", width: "400px", wordBreak: "unset" }}>{total.toFixed( 2 )}</Text>}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={1} colSpan={1}>
                                        <Text style={{ color: "#262622", fontWeight: "bold", fontSize: "16px", width: "400px", wordBreak: "unset" }}>{remaining.toFixed( 2 )}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={1} colSpan={1}>
                                        <Text style={{ color: "#262622", fontWeight: "bold", fontSize: "16px", width: "400px", wordBreak: "unset" }}>{paid.toFixed( 2 )}</Text>
                                    </Table.Summary.Cell>

                                </Table.Summary.Row>
                            </>
                        )
                    }} />
            </CustomCard>
        </div>
    );
};

export default OrderReport;
