import React from 'react';
import logo from '../../../assets/logo.png'; // replace with your logo's relative path
import './Receipt.css';
import moment from 'moment';

const Receipt = ( { orderData } ) => {
  return (
    <div className="receiptContainer">
      {/* Logo */}
      <div className="logoContainer">
        <img src={logo} alt="Company Logo" className="logo" />
      </div>

      {/* Order Details */}
      <div className="orderDetails">
        <p className="orderType">Order Type: {orderData?.orderType}</p>
        <p className="orderNumber">Order Number: {orderData?.orderNumber}</p>
        <p className="orderDate">Order Date: {moment( orderData?.orderDate ).format( "lll" )}</p>
      </div>

      {/* Item List */}
      <table >
        <thead>
          <tr className="itemRow">
            <th>Product</th>
            <th>Unit</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {orderData?.items?.map( item => (
            <tr key={item.product.$oid} className="itemRow">
              <td>{item.name}</td>
              <td>{item.unit}</td>
              <td>{item.quantity}</td>
              <td>AED{item.price}</td>
              <td>AED{item.total}</td>
            </tr>
          ) )}
        </tbody>
      </table>

      {/* Total */}
      <div className="totals">
        <span>Total Amount: AED {orderData?.totalAmount}</span>
        <span>Amount Paid: AED {orderData?.amountPaid}</span>
        <span>Amount Remaining: AED {orderData?.amountRemaining}</span>
        <span>Payment Type: {orderData?.paymentType}</span>
      </div>
    </div>
  );
};

export default Receipt;
