import React, { useState } from 'react';
import { Button, message } from 'antd';
import ReceiptForm from './components/ReceiptForm';
import ReceiptTable from './components/ReceiptTable';
import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import CustomCard from '../../components/card/CustomCard';
import { useAddReceipt, useDeleteReceipt, useGetReceipts, useUpdateReceipt } from '../../services/Query/ReceiptQuery';
import './Reciepts.css'

const Receipt = () => {
    const [receipts, setReceipts] = useState( [] );
    const createReceiptMutation = useAddReceipt();
    const updateReceiptMutation = useUpdateReceipt();
    const deleteReceiptMutation = useDeleteReceipt();
    const [selectedRow, setSelectedRow] = useState( null );
    const [isEdit, setIsEdit] = useState( false );


    const handleAddReceipt = ( receipt ) => {
        createReceiptMutation.mutate( receipt, {
            onSuccess: () => {
                message.success( "Receipt added successfully" );
                // You can refresh the receipt list or close a modal here if needed
            },
            onError: () => {
                message.error( "Something went wrong while adding data" );
            }
        } );
    };

    const handleDeleteReceipt = ( id ) => {
        deleteReceiptMutation.mutate( id, {
            onSuccess: () => {
                message.success( "Receipt deleted successfully" );
                // Refresh the receipt list or handle other side effects
            },
            onError: () => {
                message.error( "Error deleting the receipt" );
            }
        } );
    };

    const handleEditReceipt = ( record ) => {
        console.log( "record========>", record )
        updateReceiptMutation.mutate( { receiptId: selectedRow._id, receiptData: { ...record } }, {
            onSuccess: () => {
                message.success( "Receipt updated successfully" );
                setIsEdit( false )
            },
            onError: () => {
                message.error( "Error updating the receipt" );
                setIsEdit( false )

            }
        } );
    };

    return (
        <div>
            <CustomCard>
                <ReceiptForm
                    onAddReceipt={handleAddReceipt}
                    selectedRow={selectedRow}
                    onEdit={handleEditReceipt}
                    isEdit={isEdit}
                />
            </CustomCard>
            <CustomCard>
                <ReceiptTable
                    onDelete={handleDeleteReceipt}
                    // onEdit={handleEditReceipt}
                    setSelectedRow={setSelectedRow}
                    setIsEdit={setIsEdit}
                />
            </CustomCard>
        </div>
    );
};

export default Receipt;
