import { useMutation, useQuery, useQueryClient } from "react-query";
import { fetchProfile, fetchUserProfile, login, register, updateUser } from "../APICalls/Userservice";

export const useLogin = () => {
    return useMutation( login );
}
export const useRegister = () => {
    return useMutation( register );
}

export const useUserProfile = ( shouldFetch = true ) => {
    return useQuery( 'userProfile', fetchUserProfile, { enabled: shouldFetch } );
}
export const useGetProfile = ( shouldFetch = true ) => {
    return useQuery( 'profile', fetchProfile, { enabled: shouldFetch } );
}

export const useUpdateUser = () => {
    const queryClient = useQueryClient();

    return useMutation(
        ( variables ) => updateUser( variables.userId, variables.userData ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries( 'profile' );
            },
        }
    );
};

