import React, { useState } from 'react';
import { Table, Button, Space, Spin, Alert, Popconfirm, Carousel, Modal } from 'antd';
import { EditOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import { useGetExpenses } from '../../../services/Query/ExpenseQuery';
import moment from 'moment/moment';

const ExpenseTable = ( { expenses, onRemove, onUpdate } ) => {
    const { data, isLoading, isError } = useGetExpenses();
    // const [showCarocel, showCarousel] = useState()
    const [isCarouselVisible, setIsCarouselVisible] = useState( false );
    const [currentAttachments, setCurrentAttachments] = useState( [] );
    const showCarousel = ( attachments ) => {

        setCurrentAttachments( attachments );
        setIsCarouselVisible( true );

    };


    const closeCarousel = () => {
        setIsCarouselVisible( false );
    };
    const NextArrow = ( { onClick } ) => {
        return (
            <div className="arrow next" onClick={onClick}>
                &gt;
            </div>
        );
    };

    const PrevArrow = ( { onClick } ) => {
        return (
            <div className="arrow prev" onClick={onClick}>
                &lt;
            </div>
        );
    };
    const columns = [
        {
            title: 'SN',
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            render: ( text, record, index ) => index + 1, // Render the serial number starting from 1

        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Reference Number',
            dataIndex: 'refNumber',
            key: 'refNumber'
        },

        {
            title: 'Date',
            dataIndex: 'expenseDate',
            key: 'expenseDate',
            render: date => moment( date ).format( 'MMMM D, YYYY, h:mm A' )
        },
        {
            title: 'Category',
            dataIndex: 'expenseCategoryName',
            key: 'expenseCategoryName',
            render: text => text || "N/A"  // Display N/A if createdBy is null
        },
        {
            title: 'Sub Category',
            dataIndex: 'expenseSubCategoryName',
            key: 'expenseSubCategoryName',
            render: text => text || "N/A"  // Display N/A if createdBy is null
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            key: 'createdBy',
            render: text => text || "N/A"  // Display N/A if createdBy is null
        },
        {
            title: 'Total',
            dataIndex: 'totalAmount',
            key: 'totalAmount'
        },
        {
            title: 'Paid',
            dataIndex: 'amountPaid',
            key: 'amountPaid'
        },
        {
            title: 'Remaining',
            dataIndex: 'amountRemaining',
            key: 'amountRemaining'
        },
        {
            title: 'Attachments',
            dataIndex: 'attachments',
            key: 'attachments',
            render: attachments => {
                if ( attachments && attachments.length ) {
                    return (
                        <a onClick={() => showCarousel( attachments )}>
                            View {attachments.length} Attachments
                        </a>
                    );
                }
                return "No Attachments";
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: ( text, record ) => (
                <Space size="middle">
                    <EyeOutlined onClick={() => onView( record )} />
                    <EditOutlined style={{ color: 'orange', cursor: 'pointer' }} onClick={() => onUpdate( record )} />

                    <Popconfirm
                        title="Are you sure you want to delete this order?"
                        onConfirm={() => onRemove( record )}
                        onCancel={( e ) => e.stopPropagation()}  // To prevent any further action like row click
                    >
                        <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
                    </Popconfirm>
                </Space>
            )
        }
    ];
    const onView = ( key ) => {
        // The 'key' parameter typically corresponds to a unique identifier, 
        // like the _id in a MongoDB collection.
        console.log( `View details for record with key: ${ key }` );
        // You might navigate to a detailed view page or open a modal here.
        // For example, if using React Router:
        // history.push(`/expense-details/${key}`);
    };

    // const onRemove = ( key ) => {
    //     console.log( `Remove record with key: ${ key }` );
    //     // Here, you would typically handle the removal of the record.
    //     // This might involve calling an API endpoint to delete the record.
    //     // After successful deletion, you could refresh your data or remove the item from local state.
    // };

    const onEdit = ( key ) => {
        console.log( `Edit record with key: ${ key }` );
        // You might navigate to an edit page or open a modal with a form pre-filled with the record's data.
        // For example, if using React Router:
        // history.push(`/edit-expense/${key}`);
    };

    if ( isLoading ) {
        return <Spin tip="Loading expenses..." />;  // Display a loading spinner when data is being fetched
    }

    if ( isError ) {
        return <Alert message="Error loading expenses" type="error" />;  // Display an error alert if there's an issue fetching data
    }

    return (
        <>
            <Modal
                open={isCarouselVisible}
                onCancel={closeCarousel}
                title="Attachments"
                footer={null}
            >
                <div className='carousel-container'>
                    <Carousel
                        arrows
                        prevArrow={<PrevArrow />}
                        nextArrow={<NextArrow />}
                    >
                        {currentAttachments.map( ( attachment, index ) => (
                            <div key={index}>
                                <img src={attachment} alt={`Attachment ${ index + 1 }`} style={{ width: '100%' }} />
                            </div>
                        ) )}
                    </Carousel>
                </div>
            </Modal>
            <Table dataSource={data} columns={columns} />
        </>

    );
};

export default ExpenseTable;
