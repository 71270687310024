import React, { useEffect, useState } from 'react';
import { Layout, Dropdown, Menu } from 'antd';
import { UserOutlined, PoweroffOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUserDetails } from '../../store/actions/userActions';
import { useUserProfile } from '../../services/Query/UserQuery';

// ... other imports

const { Header } = Layout;

const CustomHeader = ( { pageTitle, headerStyle } ) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const profileLoaded = useSelector( state => state.user.email );
    const { data: profile } = useUserProfile( profileLoaded ? false : true );
    const [userMenuVisible, setUserMenuVisible] = useState( false );
    const user = useSelector( ( state ) => state.user.name );
    console.log( user )
    useEffect( () => {
        if ( profile && !profileLoaded ) {
            dispatch( setUserDetails( profile ) );
        }
    }, [profile, profileLoaded, dispatch] );
    const handleUserMenuClick = ( { key } ) => {
        if ( key === 'logout' ) {
            localStorage.clear();
            navigate( "/login" )
        } else if ( key === 'profile' ) {
            // Handle profile navigation here
        }
    };

    const userMenu = (
        <Menu onClick={handleUserMenuClick}>
            <Menu.Item key="profile" icon={<UserOutlined />}>
                <Link to="/profile">Profile</Link>
            </Menu.Item>
            <Menu.Item key="logout" icon={<PoweroffOutlined />}>
                Logout
            </Menu.Item>
        </Menu>
    );

    return (
        <Header style={{
            padding: '0 20px',
            backgroundColor: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            ...headerStyle
        }}>
            {pageTitle.includes( "Ledger/details" ) ? <h2 style={{ margin: 0 }}>Ledger Details</h2> : <h2 style={{ margin: 0 }}>{pageTitle}</h2>}
            <Dropdown overlay={userMenu} trigger={['click']} visible={userMenuVisible} onVisibleChange={setUserMenuVisible}>
                <span style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', color: '#1890ff' }}>
                    <UserOutlined style={{ marginRight: '8px' }} /> {user}
                </span>
            </Dropdown>
        </Header>
    );
};

export default CustomHeader;
