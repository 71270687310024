import { useMutation, useQuery, useQueryClient } from "react-query";
import { fetchCompanies, addCompany, updateCompany, deleteCompany } from "../APICalls/CompanyService";

export const useAddCompany = () => {
    const queryClient = useQueryClient();
    return useMutation( addCompany, {
        onSuccess: () => {
            // After a successful mutation, invalidate the 'companies' query to refetch the data
            queryClient.invalidateQueries( 'companies' );
        }
    } );
}

export const useGetCompanies = () => {
    return useQuery( 'companies', fetchCompanies );
}

export const useUpdateCompany = () => {
    const queryClient = useQueryClient();

    return useMutation( (data) => updateCompany( data.companyId, data.companyData ), {
        onSuccess: () => {
            // Invalidate the 'companies' query to refetch data after updating
            queryClient.invalidateQueries( 'companies' );
        }
    } );
}

export const useDeleteCompany = () => {
    const queryClient = useQueryClient();

    return useMutation(
        companyId => deleteCompany(companyId),
        {
            onSuccess: () => {
                // Invalidate the 'companies' query to refetch data after deletion
                queryClient.invalidateQueries('companies');
            }
        }
    );
}
