import React, { useEffect, useState } from 'react';
import { Form, Input, DatePicker, Switch, Select, Button, Row, Col, message } from 'antd';
import CustomCard from '../../../components/card/CustomCard';
import { useGetProfile, useUpdateUser, useUserProfile } from '../../../services/Query/UserQuery';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useAddCompany, useGetCompanies } from '../../../services/Query/CompanyQuery';
import CompanyForm from '../../company/components/CompanyForm';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { setUserDetails } from '../../../store/actions/userActions';

// const { Option } = Select;

const Profile = () => {
    const { data, isError, isLoading } = useGetProfile()
    const [form] = Form.useForm();
    const [loading, setLoading] = useState( false );
    const { data: companies } = useGetCompanies()
    const createCompanyMutation = useAddCompany();
    const updateUserMutation = useUpdateUser();
    const dispatch = useDispatch();
    const profileLoaded = useSelector( state => state.user.email );
    const { data: profile } = useUserProfile( profileLoaded ? false : true );
    const [isCompanyModal, setCompanyModal] = useState( false );

    const handleSubmitCompany = ( formData ) => {
        createCompanyMutation.mutate( formData, {
            onSuccess: () => {
                message.success( "Company added successfully" );
                closeModal();
            },
            onError: () => {
                message.error( "Something went wrong while adding data" );
            }
        } );

    };
    const closeModal = () => {
        setCompanyModal( false )
    }

    useEffect( () => {
        if ( data?.length ) {
            const userData = data[0];  // Replace with your actual logic to get user data
            form.setFieldsValue( {
                defaultCompany: userData?.defaultCompany?._id,
                name: userData?.name,
                email: userData?.email,
                contact: userData?.contact,
                emiratesId: userData?.emiratesId,
                // expiryDate: userData?.expiryDate ? dayjs( userData.expiryDate ).format( 'YYYY-MM-DD' ) : undefined,
            } );
        }
    }, [form, data] );
    const today = dayjs();

    function disabledDate( current ) {
        return current && current.isBefore( today, 'day' );
    }
    useEffect( () => {

        console.log( "profile========>", profile, data )
        if ( data?.length ) {
            let userData = data[0]
            let defaultCompany = data[0]?.defaultCompany
        console.log( "defaultCompany========>", defaultCompany, data )

            dispatch( setUserDetails( {
                ...profile,
                defaultCompany: defaultCompany?._id,
                name: userData.name,
                email: userData.email,
                emiratesId: userData.emiratesId,
                contact: userData.contact,
                expiryDate: userData.expiryDate
            } ) );
        }
    }, [profile, profileLoaded, dispatch, data] );
    const handleUpdateUser = async () => {
        try {
            setLoading( true ); // Set loading state to true during the API call
            const values = await form.validateFields(); // Validate the form fields
            const userId = data[0]?._id; // Assuming the user ID is available in the data
            await updateUserMutation.mutateAsync( { userId, userData: values } );
            message.success( 'User updated successfully' );
        } catch ( error ) {
            // Handle validation errors or other errors if needed
            message.error( 'Failed to update user' );
        } finally {
            setLoading( false ); // Set loading state to false after API call completion
        }
    };
    return (
        <CustomCard>
            <Form layout="vertical" form={form}>
                <CompanyForm
                    isVisible={isCompanyModal}
                    handleSubmit={handleSubmitCompany}
                    closeModal={closeModal}
                />
                <Row gutter={[16, 16]}>
                    <Col lg={{ span: 8 }}>
                        <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input the name!' }]}>
                            <Input placeholder="Enter name" />
                        </Form.Item>
                    </Col>
                    <Col lg={{ span: 8 }}>
                        <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input the email!' }]}>
                            <Input placeholder="Enter email" />
                        </Form.Item>
                    </Col>
                    <Col lg={{ span: 8 }}>
                        <Form.Item label="Contact" name="contact">
                            <Input placeholder="Enter contact" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    {/* <Col lg={{ span: 8 }}>
                        <Form.Item label="Address" name="address">
                            <Input placeholder="Enter address" />
                        </Form.Item>
                    </Col> */}
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <Form.Item
                            label="Default Company"
                            name="defaultCompany"
                        // rules={[{ required: true, message: 'Please select default company!' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Select Company"
                                options={Array.isArray( companies ) ? companies.map( company => company ? { value: company._id, label: company.companyName } : null ).filter( Boolean ) : []}
                                // onSearch={handleSearchChange}
                                filterOption={false}
                                suffixIcon={
                                    <PlusCircleOutlined

                                        onClick={() => setCompanyModal( true )} // Only trigger the click event if not disabled
                                    />
                                }
                            />
                        </Form.Item>

                    </Col>
                    <Col lg={{ span: 8 }}>
                        <Form.Item label="E-Id/Passport/D-Licence" name="emiratesId">
                            <Input placeholder="Enter Number" />
                        </Form.Item>
                    </Col>
                    <Col lg={{ span: 8 }}>
                        <Form.Item label="Expiry Date" name="expiryDate">
                            <DatePicker
                                style={{ width: '100%' }}
                                format="YYYY-MM-DD"
                                disabledDate={disabledDate}
                                defaultValue={data && data[0]?.expiryDate
                                    ? dayjs( data[0]?.expiryDate )
                                    : dayjs()}
                            // onKeyDown={( e ) => {
                            //     if ( e.key === 'Enter' ) handleSubmit();
                            // }}
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={{ span: 8 }}>
                        <Form.Item label=" ">
                            <Button
                                type="primary"
                                // htmlType="submit" 
                                style={{ width: "100%" }}
                                onClick={handleUpdateUser}
                                loading={loading}  // Set loading state for the button
                            >Save</Button>
                        </Form.Item>
                    </Col>
                </Row>

                {/* <Row gutter={[16, 16]}>
                    <Col lg={{ span: 8 }}>
                        <Form.Item label="Account Status" name="accountStatus" valuePropName="checked">
                            <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
                        </Form.Item>
                    </Col>
                </Row> */}


            </Form>
        </CustomCard>
    );
};

export default Profile;
