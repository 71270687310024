import React, { useEffect, useState } from 'react';
import { Modal, Input, Select, Tag, Button, Form, message, DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useGetCompanies } from '../../../services/Query/CompanyQuery';
import dayjs from 'dayjs';
import moment from 'moment';
const { Option } = Select;
const { Item } = Form
const emiratesIDRegex = /^(\d{3}-\d{4}-\d{7}-\d{1})$/;
const phoneRegex = /^[0-9]{10}$/; // Assuming the phone number format is 10 digits without any dashes or spaces
const CustomerModal = ( { isVisible, onClose, onUpdate, data } ) => {
    const [form] = Form.useForm();
    const [contacts, setContacts] = useState( [] );
    const [currentContact, setCurrentContact] = useState( '' );
    const { data: companies } = useGetCompanies()
    const [expiryDateNew, setExpiryDateNew] = useState()
    const handleEmiratesIDChange = ( e ) => {
        let value = e.target.value.replace( /\D/g, "" ); // Strip out all non-digits
        if ( value.length > 3 ) {
            value = value.slice( 0, 3 ) + "-" + value.slice( 3 );
        }
        if ( value.length > 8 ) {
            value = value.slice( 0, 8 ) + "-" + value.slice( 8 );
        }
        if ( value.length > 16 ) {
            value = value.slice( 0, 16 ) + "-" + value.slice( 16 );
        }
        form.setFieldsValue( { emiratesId: value } );
    };
    useEffect( () => {
        if ( data && isVisible ) {  // Check if data is present and modal is visible
            // const dayjsDate = dayjs( data.expiryDate );
            // let formatedDate = dayjsDate.format( 'DD-MM-YYYY' )
            // console.log( data, dayjsDate.format( 'DD-MM-YYYY' ) )
            form.setFieldsValue( {
                name: data.name,
                email: data.email ? data.email : "",
                address: data.address,
                customerType: data.customerType ? data.customerType : "Vendor-Customer",
                emiratesId: data.emiratesId,
                // expiryDate: data.expiryDate ? moment(data.expiryDate) : null
                expiryDate: moment( data.expiryDate ).format( "YYYY-MM-DD" ),
                openingBalance: data.openingBalance ? Math.abs( data.openingBalance ) : 0,
                balanceType: data.openingBalance ? ( data.openingBalance < 0 ? "receivable" : "payable" ) :""
            } );
            setContacts( data.contacts || [] );
        } else if ( isVisible ) {
            form.setFieldsValue( {
                customerType: data?.customerType ? data.customerType : "Vendor-Customer",
                expiryDate: data?.expiryDate
                    ? dayjs( data.expiryDate ).format( 'YYYY-MM-DD HH:mm:ss' )
                    : dayjs(),
            } );
        } else {

            form.resetFields();  // Reset form when modal is not visible
            setContacts( [] );     // Clear contacts
        }
    }, [data, isVisible, form] );
    const today = dayjs();

    function disabledDate( current ) {
        console.log( current )
        return current && current.isBefore( today, 'day' );
    }
    const handleAddContact = () => {
        if ( currentContact && phoneRegex.test( currentContact ) && !contacts.includes( currentContact ) ) {
            setContacts( prev => [...prev, currentContact] );
            setCurrentContact( '' );
        } else if ( contacts.includes( currentContact ) ) {
            message.warning( 'This contact already exists.' );
        } else {
            message.warning( 'Please enter a valid phone number.' );
        }
    };

    const handleSubmit = () => {
        form.validateFields()
            .then( values => {
                values.contacts = contacts; // Add contacts to the values
                onUpdate( values ); // Send the customer object to a function to handle adding/updating
                form.resetFields();
                onClose(); // Close the modal
            } )
            .catch( errorInfo => {
                message.error( 'Please correct the errors in the form.' );
            } );
    };
    console.log( "expiryDateNew====>", expiryDateNew )
    return (
        <Modal
            title="Add/Update Customer"
            open={isVisible}
            onCancel={onClose}
            onOk={handleSubmit}
        >
            <Form form={form} layout="vertical">
                <Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, message: 'Please input the customer name.' }]}
                >
                    <Input
                        placeholder="Name"
                        onKeyDown={e => { if ( e.key === "Enter" ) handleSubmit(); }}
                    />
                </Item>
                <Item
                    name="openingBalance"
                    label="Opening Balance"
                    rules={[{ required: true, message: 'Please input the opening balance' }]}
                >
                    <Input
                        type="number"
                        placeholder="Opening Balance"
                        onKeyDown={( e ) => {
                            if ( e.key === 'Enter' ) handleSubmit();
                        }}
                    />
                </Item>

                <Item
                    name="balanceType"
                    label="Balance Type"
                    rules={[{ required: true, message: 'Please select the balance type' }]}
                >
                    <Select
                        placeholder="Select Balance Type"
                        onKeyDown={( e ) => {
                            if ( e.key === 'Enter' ) handleSubmit();
                        }}
                    >
                        <Option value="payable">Payable</Option>
                        <Option value="receivable">Receivable</Option>
                    </Select>
                </Item>
                <Item
                    name="email"
                    label="Email"
                    rules={[
                        { type: 'email', message: 'Please input a valid email.' }
                    ]}
                >
                    <Input
                        placeholder="Email"
                        onKeyDown={e => { if ( e.key === "Enter" ) handleSubmit(); }}
                    />
                </Item>

                <div>
                    {/* ... [Your contact addition logic] */}
                </div>

                <Item
                    name="address"
                    label="Address"
                    rules={[{ required: true, message: 'Please input the address.' }]}
                >
                    <Input
                        placeholder="Address"
                        onKeyDown={e => { if ( e.key === "Enter" ) handleSubmit(); }}
                    />
                </Item>
                {/* <div> */}
                <Item
                    name="contact"
                    label="Contact"
                    rules={[{ required: true, message: 'Please input the contact.' }]}
                >
                    <Input
                        placeholder="Contact"
                        value={currentContact}
                        onChange={e => setCurrentContact( e.target.value )}
                        onKeyDown={e => { if ( e.key === "Enter" ) handleAddContact(); }}
                        suffix={
                            <Button
                                type="link"
                                icon={<PlusOutlined />}
                                onClick={handleAddContact}
                                style={{ marginRight: '-10px', color: '#1890ff' }}
                            />
                        }
                        rules={[
                            { required: true, message: 'Please input the contact number.' },
                            { pattern: phoneRegex, message: 'Please enter a valid phone number.' }
                        ]}
                    />
                </Item>

                <div>
                    {contacts.map( ( contact, idx ) => (
                        <Tag key={idx} closable onClose={() => setContacts( () => contacts.filter( c => c !== contact ) )}>
                            {contact}
                        </Tag>
                    ) )}
                </div>
                {/* </div> */}


                <Item
                    name="customerType"
                    label="Customer Type"
                    rules={[{ required: true, message: 'Please select the customer type.' }]}
                >
                    <Select
                        placeholder="Customer Type"
                        onKeyDown={e => { if ( e.key === "Enter" ) handleSubmit(); }}
                        defaultValue="Vendor-Customer"
                    >
                        <Option value="Vendor-Customer" >Vendor-Customer</Option>
                        <Option value="Customer">Customer</Option>
                        <Option value="Vendor">Vendor</Option>
                    </Select>
                </Item>

                <Item
                    name="emiratesId"
                    label="E-Id/Passport/D-Licence"
                    rules={[{ required: true, message: 'Please input the ID.' }]}
                >
                    <Input
                        placeholder="Enter ID"
                        onKeyDown={e => { if ( e.key === "Enter" ) handleSubmit(); }}
                    />
                </Item>
                <Item
                    name="expiryDate"
                    label="Expiry Date"
                    rules={[{ required: true, message: 'Please select the expiry date.', }]}
                >
                    <Input
                        type="date"
                        style={{ width: '100%' }}
                    />
                </Item>

            </Form>
        </Modal >
    );
};

export default CustomerModal;