import React, { useState } from 'react';
import { Table, Button, Space, Spin, Alert, Popconfirm, Carousel, Modal } from 'antd';
import { EditOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import { useGetPayments } from '../../../services/Query/PaymentQuery';
import moment from 'moment';

const PaymentTable = ( { onDelete, setSelectedRow, setIsEdit } ) => {
  const { data, isLoading, isError } = useGetPayments();
  const [serialNumber, setSerialNumber] = useState( 0 );
  const handleDelete = ( id ) => {
    onDelete( id );
  };

  const columns = [
    {
      title: 'SN',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      render: ( text, record, index ) => index + 1 + serialNumber,
    },

    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      render: company => company.name || "N/A"  // Assuming that the company object has a 'name' property.
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      render: customer => customer.name || "N/A"  // Assuming that the customer object has a 'name' property.
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: category => category.name || "N/A"  // Assuming that the category object has a 'name' property.
    },
    {
      title: 'Payment Amount',
      dataIndex: 'amount',
      key: 'amount'
    },
    {
      title: 'Payment Date',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      render: date => moment( date ).format( 'MMMM D, YYYY, h:mm A' )
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 100,
      render: ( text, record ) => (
        <Space size="middle">
          <EditOutlined
            style={{ color: 'orange', cursor: 'pointer' }}
            onClick={() => {
              setSelectedRow( record );
              setIsEdit( true );
            }}
          />
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete( record._id )}>
            <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  if ( isLoading ) {
    return <Spin tip="Loading payments..." />;
  }

  if ( isError ) {
    return <Alert message="Error loading payments" type="error" />;
  }

  return (
    <Table
      dataSource={data}
      columns={columns}
      pagination={{
        onChange: ( page, pageSize ) => {
          const startSerial = ( page - 1 ) * pageSize;
          setSerialNumber( startSerial );
        },
      }}

    />
  );
};

export default PaymentTable;
