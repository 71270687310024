// src/components/Footer.js

import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

const FooterComponent = () => (
  <Footer style={{ textAlign: 'center' }}>Scrap It ©2023 Created by Albalooshi Group</Footer>
);

export default FooterComponent;
