import React, { useEffect } from 'react';
import { Input, Form, Button, Checkbox } from 'antd';

const RoleForm = ( { formData, isEdit, form } ) => {
    const checkAll = ( category, checked ) => {
        const newValues = {};
        Object.keys( formData.permissions[category] ).forEach( action => {
            newValues[`${ category }.${ action }`] = checked;
        } );
        form.setFieldsValue( newValues );
    };

    // Convert nested permissions to flat structure for initialValues
    const getInitialValues = () => {
        const flatValues = {};
        Object.keys( formData.permissions ).forEach( category => {
            Object.keys( formData.permissions[category] ).forEach( action => {
                flatValues[`${ category }.${ action }`] = formData.permissions[category][action];
            } );
        } );
        return { title: formData.title, ...flatValues };
    };
    useEffect( () => {
        if ( isEdit ) {
            form.setFieldsValue( getInitialValues() );
        }
    }, [formData, form, isEdit] )
    return (
        <Form layout="vertical" form={form} initialValues={isEdit ? getInitialValues() : {}} className="role-form">
            <Form.Item
                label="Role Name"
                name="title"
                rules={[{ required: true, message: 'Please input the role name!' }]}
            >
                <Input placeholder="Enter role name" />
            </Form.Item>

            {isEdit && <div>
                <strong>Permissions:</strong>
                {Object.keys( formData.permissions ).map( permission => (
                    <div key={permission} className="permission-block">
                        <div className="header">
                            <strong>{permission}</strong>
                            <Checkbox
                                onChange={( e ) => checkAll( permission, e.target.checked )}
                                className="select-all"
                            >
                                Select All
                            </Checkbox>
                        </div>
                        <div className="actions">
                            {Object.keys( formData.permissions[permission] ).map( action => (
                                <Form.Item key={action} name={`${ permission }.${ action }`} valuePropName="checked" className="action-item">
                                    <Checkbox>{action}</Checkbox>
                                </Form.Item>
                            ) )}
                        </div>
                    </div>
                ) )}
            </div>}
        </Form>
    );

}

export default RoleForm;
