import React, { useEffect, useState } from 'react';
import ImgCrop from 'antd-img-crop';
import { Upload, message, Modal } from 'antd';
import { useUploadFile } from '../../services/Query/FileUploadQuery';

const FileUploadComponent = ( { setAttachments, attachments, isEdited, ...rest } ) => {
    const [fileList, setFileList] = useState( [] );
    const [previewVisible, setPreviewVisible] = useState( false );
    const [previewImage, setPreviewImage] = useState( '' );

    const uploadMutation = useUploadFile();
    console.log( attachments, isEdited )
    const onChange = ( { fileList: newFileList } ) => {
        setFileList( newFileList );
    };

    useEffect( () => {
        if ( attachments.length === 0 ) {
            setFileList( [] );
        } else if ( isEdited ) {
            // Set fileList to existing attachments when in edit mode
            const fileListFromAttachments = attachments.map( ( url, index ) => ( {
                uid: index,
                name: `image-${ index + 1 }`,
                status: 'done',
                url,
            } ) );
            setFileList( fileListFromAttachments );
        }
    }, [attachments, isEdited] );

    const onPreview = async ( file ) => {
        let src = file.url;
        if ( !src ) {
            src = await new Promise( ( resolve ) => {
                const reader = new FileReader();
                reader.readAsDataURL( file.originFileObj );
                reader.onload = () => resolve( reader.result );
            } );
        }
        setPreviewImage( src );
        setPreviewVisible( true );
    };

    const customRequest = ( { file, onSuccess, onError } ) => {
        uploadMutation.mutate( file, {
            onSuccess: ( data ) => {
                message.success( 'Image added successfully' );
                console.log( 'data=========>', data );
                setAttachments( ( prev ) => [...prev, data.data.secure_url] );
                onSuccess( data.data.secure_url );
            },
            onError: ( error ) => {
                message.error( 'Something went wrong while adding data' );
                onError( error );
            },
        } );
    };
    const onRemove = ( file ) => {
        // Handle the removal of the corresponding attachment manually
        const updatedFileList = fileList.filter( ( item ) => item.uid !== file.uid );
        setFileList( updatedFileList );
        setAttachments( updatedFileList.map( rec => rec.url ) );
    };

    return (
        <div>
            <ImgCrop rotationSlider>
                <Upload
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    onPreview={onPreview}
                    onRemove={onRemove}
                    customRequest={customRequest}
                    showUploadList={{
                        showRemoveIcon: isEdited, // Show remove icon only when in edit mode
                        showAddIcon: true, // Show add icon always
                    }}
                >
                    {fileList.length < 3 && '+ Upload'}
                </Upload>
            </ImgCrop>
            <Modal
                visible={previewVisible}
                footer={null}
                onCancel={() => setPreviewVisible( false )}
            >
                <img alt="preview" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </div>
    );
};

export default FileUploadComponent;
