import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomCard from "../../components/card/CustomCard";
import { useGetCustomers } from "../../services/Query/CustomerQuery";
import { useGetProducts } from "../../services/Query/ProductsQuery";
import ProductForm from "./components/ProductForm";
import ProductTable from "./components/ProductTable";
import OrderDetailsForm from "./components/OrderDetailsForm";
import styles from "./Order.module.css";
import { Button, Col, Form, Row, message } from "antd";
import PaymentDetailsForm from "./components/PaymentForm";
import {
  useAddOrder,
  useDeleteOrder,
  useUpdateOrder,
} from "../../services/Query/OrderQuery";
import { ArrowLeftOutlined, PrinterOutlined } from "@ant-design/icons";
import moment from "moment";
import Receipt from "./components/Receipt";
import ReactToPrint from "react-to-print";
import { useUserProfile } from "../../services/Query/UserQuery";
import { useSelector } from "react-redux";
const Order = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [visible, setVisible] = useState( false );
  const { data: profile } = useUserProfile( true );
  const [form] = Form.useForm();
  const [editedProduct, setEditedProduct] = useState( {} );
  const [productDetails, setProductDetails] = useState( () => {
    if ( location.state?.selectedOrder?.items ) {
      return location.state.selectedOrder.items.map( ( item ) => ( {
        ...item,
        price:
          location.state?.selectedOrder?.orderType === "Sale"
            ? item.salePrice
            : item.purchasePrice,
      } ) );
    }
    return [];
  } );
  const [isTaxable, setIsTaxable] = useState( false );
  const [taxAmount, setTaxAmount] = useState( 0 );
  const [totalAmount, setTotalAmount] = useState( 0 );
  const [amountRemaining, setAmountRemaining] = useState( 0 );
  const [paymentType, setPaymentType] = useState( "Cash" )
  const [amountPaid, setAmountPaid] = useState( () => {
    return location.state?.selectedOrder
      ? location.state.selectedOrder.amountPaid
      : 0;
  } );
  const [newCustomerModalVisible, setNewCustomerModalVisible] = useState( false );
  const [newCustomerForm] = Form.useForm();
  const [pagination, setPagination] = useState( {
    current: undefined,
    pageSize: undefined,
  } );
  const { data: products } = useGetProducts();
  const [productsData, setProductsData] = useState( [] );
  const [selectedCustomer, setSelectedCustomer] = useState( "" );
  const createOrderMutation = useAddOrder();
  const updateOrderMutation = useUpdateOrder();
  const deleteOrderMutation = useDeleteOrder();
  const componentRef = useRef();
  const [searchParams, setSearchParams] = useState( {
    name: "",
    id: "",
    validity: "",
  } );
  const [isProductEdit, setIsProductEdit] = useState( false );
  const [currentOrderType, setCurrentOrderType] = useState(
    location.state?.selectedOrder
      ? location.state?.selectedOrder?.orderType
      : "Purchase"
  );
  // const {
  //   data: customersData,
  //   isLoading,
  //   isError,
  //   refetch,
  // } = useGetCustomers( pagination.current, pagination.pageSize, searchParams );
  // const customers = customersData?.data;
  // const total = data?.total;
  const { state } = location;
  console.log( "currentOrderType", location.state?.selectedOrder );

  let initialValues;
  const isView = state?.isView;
  const isEdit = state?.isEdit;
  const selectedOrder = state?.selectedOrder;
  console.log( "Selected==========>", selectedOrder );
  if ( isView || isEdit ) {
    if ( selectedOrder ) {
      initialValues = {
        ...selectedOrder,
        customerId: selectedOrder.customerId,
        orderType: selectedOrder.orderType,
        orderDate: moment( selectedOrder.orderDate ),
        taxAmount: selectedOrder.taxAmount,
        subTotal: selectedOrder.totalAmount - selectedOrder.taxAmount,
        companyId: selectedOrder.companyId
      };
    } else {
      console.warn( "No selected order provided for view or edit mode." );
      // Default to a blank form or handle this case accordingly.
      initialValues = {
        orderType: "Purchase",
        isTaxable: false,
        orderId: 1,
        orderDate: null,
        customerId: "",
        orderNumber: "",
        amountPaid: 0,
        amountRemaining: 0,
        totalAmount: 0,
        paymentType: "Cash",
        taxAmount: 0,
        subTotal: 0,
        companyId: profile?.defaultCompany
      };
    }
  } else {
    initialValues = {
      orderType: "Purchase",
      isTaxable: false,
      orderId: 1,
      orderDate: null,
      customerId: "",
      orderNumber: "",
      amountPaid: 0,
      amountRemaining: 0,
      totalAmount: 0,
      paymentType: "Cash",
      taxAmount: 0,
      subTotal: 0,
      companyId: profile?.defaultCompany
    };
  }
  useEffect( () => {
    console.log( "profileprofile", profile )
    if ( profile?.defaultCompany ) {
      form.setFieldsValue( {
        companyId: profile.defaultCompany,
      } );
    }
  }, [form, profile] );
  // console.log( "customersData", customers, customersData );
  const onFinish = ( values ) => {
    console.log( "Received values of form: ", values );
  };
  console.log( "profile=========>", profile )
  const handleEdit = ( record ) => {
    console.log( "recorddd====>", record );
    form.setFieldsValue(
      productDetails.find( ( pd ) => pd.product === record.product ) || {
        unit: "MT",
        price: "",
        quantity: 1,
      }
    );
    setEditedProduct(
      productDetails.find( ( pd ) => pd.product === record.product ) || {
        unit: "MT",
        price: "",
        quantity: 1,
      }
    );
    setIsProductEdit( true );
  };

  const handleRemove = ( key ) => {
    console.log( "key========>", key )
    console.log( "productDetails.filter( ( pd ) => pd.key !== key ) ========>",  productDetails.filter( ( pd ) => pd.product !== key )  )

    setProductDetails( productDetails.filter( ( pd ) => pd.product !== key ) );
  };

  useEffect( () => {
    const totalValue = productDetails.reduce( ( acc, product ) => {
      const price = product.price;
      return acc + price * product.quantity;
    }, 0 );

    const taxValue = form.getFieldValue( "isTaxable" ) ? totalValue * 0.05 : 0;
    const remainingValue = totalValue + taxValue - amountPaid;
    if ( paymentType === "Credit" ) {
      form.setFieldsValue( {
        taxAmount: taxValue,
        totalAmount: totalValue + taxValue,
        amountRemaining: totalValue + taxValue,
        subTotal: totalValue,
        amountPaid: 0,
        // customerId: selectedCustomer,
      } );
    } else {
      if ( isEdit && selectedOrder ) {
        console.log( "totalValue", totalValue );
        form.setFieldsValue( {
          ...selectedOrder,
          orderDate: moment( selectedOrder.orderDate ),
          taxAmount: taxValue, //selectedOrder.taxAmount,
          subTotal: totalValue,
          totalAmount: totalValue + taxValue,
          amountRemaining: remainingValue,
          amountPaid: amountPaid,
        } );
      } else if ( !isView && !isEdit ) {
        form.setFieldsValue( {
          taxAmount: taxValue,
          totalAmount: totalValue + taxValue,
          amountRemaining: remainingValue,
          subTotal: totalValue,
          // amountPaid: totalValue + taxValue
          amountPaid: amountPaid,
          // customerId: selectedCustomer,
        } );
      }
    }
  }, [isTaxable, amountPaid, paymentType] );
  useEffect( () => {
    const totalValue = productDetails.reduce( ( acc, product ) => {
      const price = product.price;
      return acc + price * product.quantity;
    }, 0 );

    const taxValue = form.getFieldValue( "isTaxable" ) ? totalValue * 0.05 : 0;
    const remainingValue = totalValue + taxValue - amountPaid;
    if ( paymentType === "Credit" ) {
      form.setFieldsValue( {
        taxAmount: taxValue,
        totalAmount: totalValue + taxValue,
        amountRemaining: totalValue + taxValue,
        subTotal: totalValue,
        amountPaid: 0,
        paymentType: paymentType
        // customerId: selectedCustomer,
      } );
    } else {
      form.setFieldsValue( {
        taxAmount: taxValue,
        totalAmount: totalValue + taxValue,
        amountRemaining: remainingValue,
        subTotal: totalValue,
        amountPaid: amountPaid,
        paymentType: paymentType
        // customerId: selectedCustomer,
      } );
    }

  }, [isTaxable, amountPaid, paymentType] );

  useEffect( () => {

    const totalValue = productDetails.reduce( ( acc, product ) => {
      const price = product.price;
      return acc + price * product.quantity;
    }, 0 );

    const taxValue = form.getFieldValue( "isTaxable" ) ? parseFloat( ( totalValue * 0.05 ).toFixed( 2 ) ) : 0;
    let remainingValue;
    if ( productDetails ) {
      remainingValue = 0
    } else {
      remainingValue = ( totalValue + taxValue ) - amountPaid;
    }

    if ( paymentType === "Credit" ) {

      if ( !isView && !isEdit ) {
        form.setFieldsValue( {
          taxAmount: taxValue,
          totalAmount: totalValue + taxValue,
          amountRemaining: totalValue + taxValue,
          subTotal: totalValue,
          amountPaid: 0,
          // customerId: selectedCustomer,
        } );
      }
    } else {
      if ( !isView && !isEdit ) {
        form.setFieldsValue( {
          taxAmount: taxValue,
          totalAmount: totalValue + taxValue,
          amountRemaining: remainingValue,
          subTotal: totalValue,
          amountPaid: totalValue + taxValue,
          // customerId: selectedCustomer,
        } );
      }
    }
  }, [isTaxable, productDetails, paymentType] )

  // order type change amounts chaneg will now work 
  // useEffect( () => {
  //   if ( currentOrderType === "Sale" && products ) {
  //     setProductDetails( ( prevDetails ) =>
  //       prevDetails.map( ( item ) => {
  //         const selectedProduct = products.find(
  //           ( value ) => value._id === item?.product
  //         );
  //         console.log( "selectedProduct", selectedProduct );

  //         return {
  //           ...item,
  //           price: selectedProduct.salePrice,
  //           total: selectedProduct.salePrice * item.quantity,
  //         };
  //       } )
  //     );
  //   } else if ( currentOrderType === "Purchase" && products ) {
  //     setProductDetails( ( prevDetails ) =>
  //       prevDetails.map( ( item ) => {
  //         const selectedProduct = products.find(
  //           ( value ) => value._id === item?.product
  //         );
  //         console.log( "selectedProduct", selectedProduct );

  //         return {
  //           ...item,
  //           price: selectedProduct.purchasePrice,
  //           total: selectedProduct.purchasePrice * item.quantity,
  //         };
  //       } )
  //     );
  //   }
  // }, [currentOrderType] );

  const handleAddOrUpdateProduct = ( values, edit = false ) => {
    const productExistsIndex = productDetails.findIndex(
      ( product ) => product.product === values.product
    );
    if ( edit ) {
      // Edit mode: Update existing product
      if ( productExistsIndex >= 0 ) {
        const updatedProducts = [...productDetails];
        updatedProducts[productExistsIndex] = {
          ...updatedProducts[productExistsIndex],
          ...values,
          total: values.price * values.quantity,
        };
        setProductDetails( updatedProducts );
      } else {
        // If in edit mode but product doesn't exist (ideally this shouldn't happen)
        // Handle this case appropriately, e.g., show an error message or add the product.
        console.error( "Attempted to edit a non-existent product." );
      }
    } else {
      // Add mode
      if ( productExistsIndex === -1 ) {
        // If product doesn't exist, add the new product
        const newProduct = {
          name: products.find( ( rec ) => rec._id === values.product )?.name,
          total: values.quantity * values.price,
          ...values,
        };
        setProductDetails( ( prevDetails ) => [...prevDetails, newProduct] );
      } else {
        // If product already exists, show an error message
        message.error( "Product is already in the cart." );
      }
    }

    setIsProductEdit( false );
    setEditedProduct( {} );
  };

  const handleAddOrder = async () => {
    try {
      const validatedValues = await form.validateFields();
      let formData = { ...validatedValues, items: [...productDetails] };

      if ( isEdit ) {
        updateOrderMutation.mutate(
          { orderId: selectedOrder._id, orderData: formData },
          {
            onSuccess: () => {
              message.success( "Order updated successfully" );
              navigate( -1 );
            },
            onError: ( error ) => {
              console.log( "error===============>", error )
              if ( error.status === 408 ) {
                message.error( "Order number already exists. Please choose a different order number." );
              } else {
                console.log( "error===========>", error )
                message.error( "Something went wrong while updating order" );
              }
            },
          }
        );
      } else {
        createOrderMutation.mutate( formData, {
          onSuccess: () => {
            message.success( "Order added successfully" );
            navigate( -1 );
          },
          onError: ( error ) => {
            console.log( "error===============>", error )
            if ( error.request.status === 408 ) {
              message.error( "Order number already exists. Please choose a different order number." );
            } else {
              console.log( "error===========>", error )
              message.error( "Something went wrong while updating order" );
            }
          },
        } );
      }
    } catch ( error ) {
      if ( error.status === 408 ) {
        message.error( "Order number already exists. Please choose a different order number." );
      } else {
        message.error( "Form validation failed " );
      }
    }
  };

  // console.log( "Customer============>", customers );
  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <Button
          type="primary"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate( -1 )}
        >
          Back
        </Button>
        {isView && (
          <ReactToPrint
            trigger={() => (
              <Button type="default" icon={<PrinterOutlined />}>
                Print
              </Button>
            )}
            content={() => componentRef.current}
          />
        )}
      </div>
      {isView && (
        <div ref={componentRef} className={styles.receiptContainer}>
          <Receipt orderData={selectedOrder} />
        </div>
      )}
      {!isView && (
        <CustomCard>
          <ProductForm
            onAddProduct={handleAddOrUpdateProduct}
            products={products ? products : []}
            editedProduct={editedProduct}
            isEdit={isProductEdit}
            orderType={currentOrderType}
          />
        </CustomCard>
      )}
      <CustomCard>
        {console.log( "productDetails", productDetails )}
        <ProductTable
          orderType={currentOrderType}
          productDetails={productDetails}
          onEdit={handleEdit}
          onRemove={handleRemove}
          isView={isView}
          isEdit={isProductEdit}
        />
      </CustomCard>
      <Row gutter={[16, 16]} style={{ display: "flex", alignItems: "stretch" }}>
        <Col lg={12} md={12} sm={24} xs={24} >
          <CustomCard style={{ width: "100%", height: "100%", overflow: "auto" }}>
            <OrderDetailsForm
              form={form}
              onSubmit={onFinish}
              // customers={customers ? customers : []}
              onAddCustomerClick={() => setNewCustomerModalVisible( true )}
              initialValues={initialValues}
              setIsTaxable={setIsTaxable}
              setAmountPaid={setAmountPaid}
              setSelectedCustomer={setSelectedCustomer}
              disabled={isView}
              setCurrentOrderType={setCurrentOrderType}
              currentOrderType={currentOrderType}
            />
          </CustomCard>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24} style={{ display: "flex", flex: "1" }}>
          <CustomCard style={{ width: "100%", overflow: "auto" }}>
            <PaymentDetailsForm
              form={form}
              onSubmit={onFinish}
              customers={[]}
              onAddCustomerClick={() => setNewCustomerModalVisible( true )}
              initialValues={initialValues}
              setIsTaxable={setIsTaxable}
              setAmountPaid={setAmountPaid}
              disabled={isView}
              setPaymentType={setPaymentType}
              paymentType={paymentType}
            />
          </CustomCard>
        </Col>
      </Row>
      {/* <CustomCard> */}
      <div className={styles.addButtonContainer}>
        {!isView && (
          <Button
            type="primary"
            className={styles.addButton}
            onClick={() => handleAddOrder()}
          >
            {isEdit ? "Update Order" : "Add Order"}
          </Button>
        )}
      </div>
      {/* </CustomCard> */}
    </div>
  );
};

export default Order;
