
import api from "../../utils/interceptors/api";

export const addExpense = ( data ) => {
    return api.post( `/expenses`, data );
};

export const fetchExpenses = async () => {
    const response = await api.get( '/expenses' );
    return response.data;
};

export const updateExpense = ( expenseId, expenseData ) => {
    return api.put( `/expenses/${ expenseId }`, expenseData );
};

export const deleteExpense = async ( expenseId ) => {
    const response = await api.delete( `/expenses/${ expenseId }` );
    return response.data;
};

export const fetchExpenseById = async ( id ) => {
    const response = await api.get( `/expenses/${ id }` );
    return response.data;
};

export const getExpenseReport = async ( searchParams, isExport = 0 ) => {
    const {
        dateFrom,
        dateTo,
        companyId,
        expenseCategoryId,
        paymentType,
        customerId,
    } = searchParams;

    return api.get(
        `/expenses/getExpenseReport?dateFrom=${ dateFrom }&dateTo=${ dateTo }&companyId=${ companyId }&expenseCategoryId=${ expenseCategoryId }&paymentType=${ paymentType }&customerId=${ customerId }&export=${ isExport }`
    );
};
