import React, { useCallback, useState } from 'react';
import { Form, Row, Col, Input, InputNumber, Select, DatePicker, Button, message, Tag, } from 'antd';
import { PlusOutlined, PlusCircleOutlined } from '@ant-design/icons';
import CustomerModal from '../../customer/Modal/CustomerModal';
import { useAddCustomer, useGetAllCustomers } from '../../../services/Query/CustomerQuery';
import { useAddCompany, useGetCompanies } from '../../../services/Query/CompanyQuery';
import { useCheckOrderNumber } from '../../../services/Query/OrderQuery';
import _ from 'lodash'; // <-- Import lodash
import moment from 'moment';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import CompanyForm from '../../company/components/CompanyForm';

const { Option } = Select;

const OrderDetailsForm = ( props ) => {
    const [selectedCustomer, setSelectedCustomer] = useState()
    const { form, initialValues, disabled = false, setCurrentOrderType, currentOrderType } = props;

    const debouncedCheckOrderNumber = useCallback(
        _.debounce( ( num ) => {
            setCurrentOrderNumber( num );
        }, 300 ), // Wait for 300ms pause in typing
        []  // Dependency array (remains empty as we don't have dependencies for this callback)
    );

    const { data: companies } = useGetCompanies()
    const [currentOrderNumber, setCurrentOrderNumber] = useState( 0 );

    const { data: orderExists, isLoading } = useCheckOrderNumber( currentOrderNumber );
    const createCompanyMutation = useAddCompany();
    const [isCompanyModal, setCompanyModal] = useState( false );
    const handleSubmitCompany = ( formData ) => {
        createCompanyMutation.mutate( formData, {
            onSuccess: () => {
                message.success( "Company added successfully" );
                closeModal();
            },
            onError: () => {
                message.error( "Something went wrong while adding data" );
            }
        } );

    };
    const handleCustomerSearchChange = async ( customerId ) => {
        setSelectedCustomer( customers?.find( rec => rec._id == customerId ) )
        console.log( "CustomerId====>", customers?.find( rec => rec._id == customerId ) )
    };

    const closeModal = () => {
        setCompanyModal( false )
    }
    useEffect( () => {
        if ( !isLoading && orderExists?.exists ) {
            message.error( 'This order number already exists.' );
        }
    }, [orderExists, isLoading] );

    const [show, setShow] = useState( false )
    const [searchTerm, setSearchTerm] = useState( "" );
    const { data: customers } = useGetAllCustomers( searchTerm );
    const createCustomerMutation = useAddCustomer();
    const handleSubmit = ( formData ) => {
        createCustomerMutation.mutate( formData, {
            onSuccess: () => {
                message.success( "Customer added successfully" )
                onClose();
            },
            onError: () => {
                message.error( "Some thing wrong while adding data" )
            }
        } )
    }
    const onClose = () => {
        setShow( false )
    }
    const handleSearchChange = ( e ) => {
        setSearchTerm( e );
    };
    const handleOrderNumberChange = ( e ) => {
        const newOrderNumber = e.target.value;
        debouncedCheckOrderNumber( newOrderNumber );  // Use the debounced function
    };


    const today = dayjs();

    function disabledDate( current ) {
        return current && current.isAfter( today, 'day' );
    }
    const handleTypeChange = ( value ) => {
        setCurrentOrderType( value )
    }

    return (
        <Form
            form={form}
            initialValues={initialValues}
            layout="vertical"
            disabled={disabled}>
            <CustomerModal
                isVisible={show}
                onUpdate={handleSubmit}
                onClose={onClose}

            />
            <h2>Order Details</h2>

            <Row gutter={32}>

                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <Form.Item
                        name="orderType"
                        label="Order Type"
                        rules={[{ required: true, message: 'Please select the order type!' }]}
                    >
                        <Select
                            onChange={handleTypeChange}
                            placeholder="Select an order type"
                        >
                            <Option value="Sale">Sale</Option>
                            <Option value="Purchase">Purchase</Option>
                        </Select>

                    </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <Form.Item
                        name="deliveryDate"
                        label="Delivery Date"
                    // rules={[{ required: true, message: 'Please select the delivery date!' }]}
                    >
                        <DatePicker defaultValue={today} disabledDate={disabledDate} />

                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <Form.Item
                        label="Customer"
                        name="customerId"
                    >
                        <Select
                            showSearch
                            placeholder="Select a customer"
                            options={Array.isArray( customers ) ? customers.map( customer => customer ? { value: customer._id, label: customer.name } : null ).filter( Boolean ) : []}
                            onSearch={handleSearchChange}
                            onChange={handleCustomerSearchChange}
                            filterOption={false}
                            suffixIcon={
                                <PlusCircleOutlined
                                    style={{
                                        cursor: disabled ? 'not-allowed' : 'pointer',
                                        color: disabled ? '#d9d9d9' : '#1890ff',
                                    }}
                                    onClick={() => !disabled && setShow( true )} // Only trigger the click event if not disabled
                                />
                            }
                        />
                    </Form.Item>

                </Col>
            </Row>
            <CompanyForm
                isVisible={isCompanyModal}
                handleSubmit={handleSubmitCompany}
                closeModal={closeModal}
            />
            <Row gutter={32}>


                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form.Item
                        name="orderNumber"
                        label="Slip Number"
                        rules={[{ required: true, message: 'Please input the Slip number!' }]}
                    >
                        <Input placeholder="Enter slip number" onChange={handleOrderNumberChange} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <Form.Item
                        label="Company"
                        name="companyId"
                        rules={[{ required: true, message: 'Please select a company!' }]}
                    >
                        <Select
                            showSearch
                            placeholder="Select Company"
                            options={Array.isArray( companies ) ? companies.map( company => company ? { value: company._id, label: company.companyName } : null ).filter( Boolean ) : []}
                            onSearch={handleSearchChange}
                            filterOption={false}
                            suffixIcon={
                                <PlusCircleOutlined
                                    style={{
                                        cursor: disabled ? 'not-allowed' : 'pointer',
                                        color: disabled ? '#d9d9d9' : '#1890ff',
                                    }}
                                    onClick={() => !disabled && setCompanyModal( true )} // Only trigger the click event if not disabled
                                />
                            }
                        />
                    </Form.Item>

                </Col>
                {selectedCustomer?.balance && <Col lg={4} >
                    {selectedCustomer && ( selectedCustomer?.balance > 0 ) ? <Tag color='red' style={{marginTop:"30px"}}>{selectedCustomer?.balance}</Tag> : <Tag color='green'  style={{marginTop:"30px"}}>{Math.abs( selectedCustomer?.balance )}</Tag>}

                </Col>}
            </Row>
            
        </Form>

    );
};

export default OrderDetailsForm;
