import { useMutation, useQuery, useQueryClient } from "react-query";
import { fetchReminders, addReminder, updateReminder, deleteReminder } from "../APICalls/ReminderService";

export const useAddReminder = () => {
    const queryClient = useQueryClient();
    return useMutation(addReminder, {
        onSuccess: () => {
            // After a successful mutation, invalidate the 'reminders' query to refetch the data
            queryClient.invalidateQueries('reminders');
        }
    });
}

export const useGetReminders = () => {
    return useQuery('reminders', fetchReminders);
}

export const useUpdateReminder = () => {
    const queryClient = useQueryClient();

    return useMutation((data) => updateReminder(data.reminderId, data.reminderData), {
        onSuccess: () => {
            // Invalidate the 'reminders' query to refetch data after updating
            queryClient.invalidateQueries('reminders');
        }
    });
}

export const useDeleteReminder = () => {
    const queryClient = useQueryClient();

    return useMutation(
        reminderId => deleteReminder(reminderId),
        {
            onSuccess: () => {
                // Invalidate the 'reminders' query to refetch data after deletion
                queryClient.invalidateQueries('reminders');
            }
        }
    );
}
