import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useGetProducts } from "../../services/Query/ProductsQuery";
import ProductForm from "../../pages/order/components/ProductForm";
import ProductTable from "../../pages/order/components/ProductTable";
import OrderDetailsForm from "../../pages/order/components/OrderDetailsForm";
import PaymentForm from "../../pages/order/components/PaymentForm";
import styles from "../../pages/order/Order.module.css";
import { Button, Col, Form, Modal, Row } from "antd";
import './ViewLedger.css'
import {
    useGetOrderById
} from "../../services/Query/OrderQuery";
import { ArrowLeftOutlined, InfoCircleOutlined, PrinterOutlined } from "@ant-design/icons";
import moment from "moment";
import Receipt from "../../pages/order/components/Receipt";
import ReactToPrint from "react-to-print";
import CustomCard from "../card/CustomCard";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const ViewOrder = ( { show, id, onClose } ) => {
    const navigate = useNavigate();
    const { data: orders, isLoading } = useGetOrderById( id );
    const { data: products } = useGetProducts();
    const componentRef = useRef();
    const [form] = Form.useForm(); // Initialize the form instance
    let order = isLoading ? {} : orders[0]
    console.log("order==========>",order)
    const handlePrint = () => {
        const modalContent = document.getElementById( "order-modal-content" );
        html2canvas( modalContent ).then( ( canvas ) => {
            const imgData = canvas.toDataURL( "image/png" );
            const pdf = new jsPDF( "p", "mm", "a4" );
            pdf.addImage( imgData, "PNG", 0, 0, pdf.internal.pageSize.width, 200 );
            pdf.save( "order.pdf" );
        } );
    };
    useEffect( () => {
        if ( order ) {
            form.setFieldsValue( {
                ...order,
                customerId: order.customerId,
                orderType: order.orderType,
                orderDate: moment( order.orderDate ),
                taxAmount: order.taxAmount,
                subTotal: order.totalAmount - order.taxAmount,
                companyId: order.companyId
            } );
        }
    }, [form, order] );

    const handleBackClick = () => {
        navigate( -1 );
    };

    const handlePrintClick = () => {
        componentRef.current && componentRef.current.onPrint();
    };

    const renderContent = () => (
        <Modal
        title={<div style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            alignContent: "center",
        }}><h3><InfoCircleOutlined /> Order Detail</h3><Button key="print" icon={<PrinterOutlined />} onClick={handlePrint}>
                Print
            </Button></div >}
            open={show}
            onCancel={onClose}
            className="my-custom-class"
            footer={null} // Remove the default footer with buttons
            style={{width:"800px"}}
        >
            <div className={styles.container} style={{ width: "100% !important" }} id="order-modal-content">
                {/* <div className={styles.buttonContainer}>
                    <ReactToPrint
                        trigger={() => (
                            <Button type="default" icon={<PrinterOutlined />}>
                                Print
                            </Button>
                        )}
                        content={() => componentRef.current}
                    />
                </div> */}
                {/* <div ref={componentRef} className={styles.receiptContainer}>
                    <Receipt orderData={order} />
                </div> */}

                <CustomCard>
                    <ProductTable
                        orderType={order ? order.orderType : "Purchase"}
                        productDetails={order?.items}
                        onEdit={() => { }}
                        onRemove={() => { }}
                        isView={true}
                        isEdit={false}
                    />
                </CustomCard>
                <Row gutter={[16, 16]} style={{ display: "flex", alignItems: "stretch" }}>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <CustomCard style={{ width: "100%", height: "100%", overflow: "auto" }}>
                            <OrderDetailsForm
                                form={form}
                                // initialValues={}
                                onSubmit={() => { }}
                                disabled={true}
                                isView={true}
                                currentOrderType={order ? order.orderType : "Purchase"}
                            />
                        </CustomCard>
                    </Col>
                    <Col lg={24} md={24} sm={24} xs={24} style={{ display: "flex", flex: "1" }}>
                        <CustomCard style={{ width: "100%", overflow: "auto" }}>
                            <PaymentForm
                                form={form}
                                onSubmit={() => { }}
                                disabled={true}
                                isView={true}
                            />
                        </CustomCard>
                    </Col>
                </Row>
            </div>
        </Modal>
    );

    return isLoading ? null : <div className="modal-ledger"> {renderContent()} </div>;
};

export default ViewOrder;
