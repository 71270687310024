import { useMutation, useQuery, useQueryClient } from "react-query";
import { fetchProducts, addProduct, updateProduct, deleteProduct } from "../APICalls/ProductsService";

export const useAddProduct = () => {
    const queryClient = useQueryClient();
    return useMutation( addProduct, {
        onSuccess: () => {
            // After a successful mutation, invalidate the 'products' query to refetch the data
            queryClient.invalidateQueries( 'products' );
        }
    } );
}
export const useGetProducts = (searchTerm = "") => {
    return useQuery(['products', searchTerm], () => fetchProducts(searchTerm));
};


export const useUpdateProduct = () => {
    const queryClient = useQueryClient();

    return useMutation( ( data ) => updateProduct( data.productId, data.productData ), {
        onSuccess: () => {
            // Invalidate the 'products' query to refetch data after updating
            queryClient.invalidateQueries( 'products' );
        }
    } );
}

export const useDeleteProduct = () => {
    const queryClient = useQueryClient();

    return useMutation(
        productId => deleteProduct(productId),
        {
            onSuccess: () => {
                // Invalidate the 'products' query to refetch data after deletion
                queryClient.invalidateQueries('products');
            }
        }
    );
}
