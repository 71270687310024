import api from "../../utils/interceptors/api";

export const addExpenseSubCategory = (data) => {
    return api.post(`/expenseSubCategories`, data);
}

export const fetchExpenseSubCategories = async () => {
    const response = await api.get('/expenseSubCategories');
    return response.data;
};
