import { Button, Col, Image, Layout, Row, Typography } from 'antd'
import { Content } from 'antd/es/layout/layout'
import React, { useEffect } from 'react'
import CompanyIncom from '../../components/charts/CompanyIncom'
import LastSevendays from '../../components/charts/LastSevenDays'
import CustomCard from '../../components/card/CustomCard'
import { useUserProfile } from '../../services/Query/UserQuery'
// import Paragraph from 'antd/es/skeleton/Paragraph'
import { useDispatch, useSelector } from 'react-redux';
import { setUserDetails, clearUserDetails } from '../../store/actions/userActions';
import LedgerTable from './components/LedgerTable'
import './Dashboard.css'
import KpiCardGroup from '../../components/card/KpiCard'
import { useGetDashboardKpis } from '../../services/Query/OrderQuery'
import EIDStatusGroup from '../../components/card/EIDStatus'
import Title from 'antd/es/typography/Title'
import { useGetAllCustomers, useGetEmiratesIDStatus } from '../../services/Query/CustomerQuery'
const Dashboard = () => {
    const dispatch = useDispatch();
    const profileLoaded = useSelector( state => state.user.email );
    const { data: profile } = useUserProfile( profileLoaded ? false : true );
    const { data: kpis, isLoading, error } = useGetDashboardKpis();
    const { data: eIds } = useGetEmiratesIDStatus();
    const { data: customers } = useGetAllCustomers();



    useEffect( () => {
        if ( profile && !profileLoaded ) {
            dispatch( setUserDetails( profile ) );
        }
    }, [profile, profileLoaded, dispatch] );

    // Use the kpis directly from the hook, if they exist, otherwise fall back to default
    const kpiData = kpis || [
        { title: "Total Sale", value: 10000 },
        { title: "Total Purchase", value: 8000 },
        { title: "Payable", value: 2000 },
        { title: "Receivable", value: 500 }
    ];
    const eIDStatusData = eIds || [
        {
            title: "Valid",
            value: 500,
            status: "Valid"
        },
        {
            title: "Expires Soon",
            value: 50,
            status: "Expires Soon"
        },
        {
            title: "Expired",
            value: 500,
            status: "Expired"
        },
    ]

    if ( isLoading ) {
        return <div>Loading...</div>;  // You can replace this with a proper loading component or spinner
    }

    if ( error ) {
        return <div>Error fetching KPIs. Please try again.</div>; // You can replace this with a more detailed error component or message
    }


    return (
        <Layout>

            <Content className="site-layout" >
                <Row gutter={4} className="row-flex">
                    <Col lg={24} sm={24}>
                        <CustomCard className="custom-card">
                            <KpiCardGroup data={kpiData} />
                        </CustomCard>
                    </Col>
                </Row>
                <Row gutter={4} className="row-flex">
                    <Col lg={12} sm={24}>
                        <CustomCard className="custom-card">
                            <CompanyIncom />
                        </CustomCard>
                    </Col>
                    <Col lg={12} sm={24}>
                        <CustomCard className="custom-card">
                            {/* <LastSevendays /> */}
                            <Title level={5} style={{ margin: '5px 0', fontWeight: 'bold' }}>Customer Emirates ID Expiry Forecast</Title>
                            <EIDStatusGroup data={eIDStatusData} />
                        </CustomCard>
                    </Col>
                </Row>

                {/* <Row>
                    <Col lg={24}>
                        <CustomCard>
                            <LedgerTable />
                        </CustomCard>
                    </Col>
                </Row> */}

            </Content>
        </Layout>

    )
}

export default Dashboard