import React, { useEffect } from 'react';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { Link, Navigate, useNavigate } from 'react-router-dom'; // Use this in react-router-dom v6
import './Login.css';
import { useLogin } from '../../services/Query/UserQuery';
import logo from "../../assets/logo.png";
import { TypeAnimation } from 'react-type-animation';
const Login = () => {
    const navigate = useNavigate(); // Use this for v6
    const loginMutation = useLogin();
    const { isLoading, isIdle, isSuccess, isError, data, error } = loginMutation
    useEffect( () => {
        // Handle the "mutating" state
        if ( isLoading || isIdle ) {
            message.loading( { content: 'Logging in...', key: 'login' } );
        }

        // Handle the "success" state
        if ( isSuccess ) {
            localStorage.setItem( 'token', data.data.token );
            message.success( { content: 'Logged in successfully!', key: 'login', duration: 2 } );
            setTimeout( () => navigate( '/' ), 2000 );  // Navigate using navigate
        }

        // Handle the "error" state
        if ( isError ) {
            message.error( { content: 'Login failed. Please try again.', key: 'login', duration: 2 } );
        }
    }, [isLoading, isIdle, isSuccess, isError, data, error] );

    const onFinish = ( values ) => {
        // Call the login mutation with form values
        loginMutation.mutate( values );
    };
    const isAuthenticated = !!localStorage.getItem( 'token' );

    if ( isAuthenticated ) {
        return <Navigate to="/" replace />;
    }
    return (
        <div className='login-wrapper'>
            <div className='login-container'>
                {/* <div className="login-sidebar">
                    <img src={logo} alt="Company Logo" className="login-logo" />
                    <p>Welcome back! Please log in to continue.</p>
                </div> */}
                <div className="login-sidebar">
                    <img src={logo} alt="Company Logo" className="login-logo" />

                    <TypeAnimation
                        sequence={[
                            'Streamlining Your Scrap Business.',
                            2000,
                            'From procurement to sales, we have you covered.',
                            2000,
                            'Specializing in:',
                            2000,
                            'Batteries Scrap Management',
                            1500,
                            'Metal Scrap Solutions',
                            1500,
                            'Car Scrap Operations',
                            1500,
                            'Computer Devices Recycling',
                            2000,
                            'Empower your business with cutting-edge SaaS.',
                            2500,
                            '',
                            1500,
                        ]}
                        style={{ fontSize: '1.5em' }}
                        repeat={Infinity}
                    />


                </div>
                <div className="login-form-container">
                    <Form
                        name="login"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Please input your Email!' }]}
                        >
                            <Input placeholder="Email" />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your Password!' }]}
                        >
                            <Input.Password placeholder="Password" />
                        </Form.Item>

                        {/* <Form.Item>
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button" loading={loginMutation.isLoading}>
                                Log in
                            </Button>

                        </Form.Item> */}
                        <Form.Item>
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item>
                            <a className="login-form-forgot" href="/forgot-password">Forgot password?</a>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button" loading={loginMutation.isLoading}>
                                Sign In
                            </Button>
                        </Form.Item>

                        <div>
                            <p>Don't have an account? <Link to="/signup">Sign Up</Link></p>
                        </div>

                    </Form>
                </div>
            </div>
        </div>
    );
};

export default Login;
