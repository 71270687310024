import { useMutation, useQuery, useQueryClient } from "react-query";
import { fetchCustomers, addCustomer, updateCustomer, deleteCustomer, getEmiratesIDStatus, fetchAllCustomers, fetchCustomer } from "../APICalls/CustomersService";

export const useAddCustomer = () => {
    const queryClient = useQueryClient();
    return useMutation( addCustomer, {
        onSuccess: () => {
            // After a successful mutation, invalidate the 'customers' query to refetch the data
            queryClient.invalidateQueries( 'customers' );
        }
    } );
}
export const useGetCustomers = ( page = undefined, pageSize = undefined, searchParams = { id: null, name: null, validity: null } ) => {
    return useQuery(
        ['customers', page, pageSize, searchParams],
        () => fetchCustomers( page, pageSize, searchParams )
    );
}


export const useGetEmiratesIDStatus = () => {
    return useQuery( 'eIds', getEmiratesIDStatus );
}


export const useUpdateCustomer = () => {
    const queryClient = useQueryClient();

    return useMutation( ( data ) => updateCustomer( data.customerId, data.customerData ), {
        onSuccess: () => {
            // Invalidate the 'customers' query to refetch data after updating
            queryClient.invalidateQueries( 'customers' );
        }
    } );
}

export const useDeleteCustomer = () => {
    const queryClient = useQueryClient();

    return useMutation(
        customerId => deleteCustomer( customerId ),
        {
            onSuccess: () => {
                // Invalidate the 'customers' query to refetch data after deletion
                queryClient.invalidateQueries( 'customers' );
            }
        }
    );
}
export const useGetAllCustomers = ( searchTerm = "" ) => {
    return useQuery( ['customers', searchTerm], () => fetchAllCustomers( searchTerm ) );
};

export const useGetCustomer = (customerId) => {
    return useQuery(['customer', customerId], () => fetchCustomer(customerId));
  };