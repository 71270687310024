import React from "react";
import styles from "../../pages/order/Order.module.css";
import { Button, Col, Form, Modal, Row } from "antd";
import {
    useGetReceiptById
} from "../../services/Query/ReceiptQuery";
import { InfoCircleOutlined, PrinterOutlined } from "@ant-design/icons";
import CustomCard from "../card/CustomCard";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
const ViewReceipt = ( { show, id, onClose } ) => {
    const { data: receipts, isLoading } = useGetReceiptById( id );
    let receipt = isLoading ? {} : receipts[0];
    const handlePrint = () => {
        const modalContent = document.getElementById( "reciept-modal-content" );
        html2canvas( modalContent ).then( ( canvas ) => {
            const imgData = canvas.toDataURL( "image/png" );
            const pdf = new jsPDF( "p", "mm", "a4" );
            pdf.addImage( imgData, "PNG", 0, 0, pdf.internal.pageSize.width, 200 );
            pdf.save( "recipt_detail.pdf" );
        } );
    };
    const renderContent = () => (
        <Modal
            // title={<h3><InfoCircleOutlined /> Receipt Detail</h3>}
            title={<div style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                alignContent: "center",
            }}><h3><InfoCircleOutlined /> Receipt Detail</h3><Button key="print" icon={<PrinterOutlined />} onClick={handlePrint}>
                    Print
                </Button></div >}
            open={show}
            onCancel={onClose}
            className="my-custom-class"
            footer={null}
            style={{ width: "800px" }}
        >
            <div className={styles.container} style={{ width: "100% !important" }} id="reciept-modal-content">
                <CustomCard>

                    <div className="invoice-header">
                        <p><strong>Date:</strong> {moment( receipt?.receiptDate ).format( "YYYY-MM-DD" )}</p>
                        <p><strong>Name:</strong> {receipt?.customer?.name}</p>
                        <p><strong>Amount:</strong> {receipt?.amount}</p>
                    </div>
                    <div className="payment-details">
                        <strong>Description:</strong>
                        <CustomCard>
                            <p >{receipt?.description}</p>
                        </CustomCard>
                        <div className="invoice-header">
                            <p><strong>Company:</strong> {receipt?.company?.name}</p>
                            <p><strong>Category:</strong> {receipt?.category?.name}</p>
                        </div>
                    </div>
                </CustomCard>
            </div>
        </Modal>
    );

    return isLoading ? null : <div className="modal-ledger"> {renderContent()} </div>;
};

export default ViewReceipt;
