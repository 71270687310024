import { useMutation, useQuery, useQueryClient } from "react-query";
import { fetchPayments, addPayment, updatePayment, deletePayment, fetchPaymentById } from "../APICalls/PaymentService";

export const useAddPayment = () => {
    const queryClient = useQueryClient();
    return useMutation(addPayment, {
        onSuccess: () => {
            // After a successful mutation, invalidate the 'payments' query to refetch the data
            queryClient.invalidateQueries('payments');
        }
    });
}

export const useGetPayments = () => {
    return useQuery('payments', fetchPayments);
}

export const useUpdatePayment = () => {
    const queryClient = useQueryClient();

    return useMutation((data) => updatePayment(data.paymentId, data.paymentData), {
        onSuccess: () => {
            // Invalidate the 'payments' query to refetch data after updating
            queryClient.invalidateQueries('payments');
        }
    });
}

export const useDeletePayment = () => {
    const queryClient = useQueryClient();

    return useMutation(
        paymentId => deletePayment(paymentId),
        {
            onSuccess: () => {
                // Invalidate the 'payments' query to refetch data after deletion
                queryClient.invalidateQueries('payments');
            }
        }
    );
}

export const useGetPaymentById = ( id ) => {
    return useQuery( ['payment', id], () => fetchPaymentById( id ) );
};