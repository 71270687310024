import React, { useEffect } from 'react';
import { Modal, Form, Input, message } from 'antd';

const CompanyForm = ( { isVisible, formData, handleSubmit, closeModal, isEdit } ) => {
    const [form] = Form.useForm();
    useEffect( () => {
        if ( formData && isVisible ) {
            form.setFieldsValue( formData );
        } else {
            form.resetFields();
        }
    }, [formData, isVisible, form] );

    const handleSubmitIn = () => {
        form.validateFields()
            .then( values => {
                handleSubmit( values ); // Send the customer object to a function to handle adding/updating
                form.resetFields();
                closeModal(); // Close the modal
            } )
            .catch( errorInfo => {
                message.error( 'Please correct the errors in the form.' );
            } );
    };
    return (
        <Modal
            title={isEdit ? "Edit Company" : "Add Company"}
            visible={isVisible}
            onOk={handleSubmitIn}
            onCancel={closeModal}
        >
            <Form layout="vertical" form={form}>
                <Form.Item
                    label="Company Name"
                    name="companyName"
                    rules={[{ required: true, message: 'Please input the company name!' }]}
                >
                    <Input placeholder="Enter company name" />
                </Form.Item>

                <Form.Item
                    label="Company Number"
                    name="companyNumber"
                    rules={[{ required: true, message: 'Please input the company number!' }]}
                >
                    <Input placeholder="Enter company number" />
                </Form.Item>

                <Form.Item
                    label="Street"
                    name={["address", "street"]}
                >
                    <Input placeholder="Enter street address" />
                </Form.Item>

                <Form.Item
                    label="City"
                    name={["address", "city"]}
                >
                    <Input placeholder="Enter city" />
                </Form.Item>

                <Form.Item
                    label="Area"
                    name={["address", "area"]}
                >
                    <Input placeholder="Enter area" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CompanyForm;
