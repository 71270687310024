import { Button, Space, Table, Spin, Alert, Tag } from "antd";
import { useGetLedger } from "../../../services/Query/OrderQuery";

function LedgerTable() {
    const { data, isLoading, isError } = useGetLedger();

    const columns = [
        
        {
            title: 'Name',
            dataIndex: 'customerName',
        },
        {
            title: 'Total Payable',
            dataIndex: 'totalCredit',
            render: ( value ) => parseFloat( value ).toFixed( 2 )
        },
        {
            title: 'Total Recievable',
            dataIndex: 'totalDebit',
            render: ( value ) => parseFloat( value ).toFixed( 2 )
        },
        {
            title: 'Net Balance',
            dataIndex: 'netBalance',
            render: ( netBalance ) => {
                let formattedBalance = parseFloat( netBalance ).toFixed( 2 );
                if ( netBalance > 0 ) {
                    return <>Receivable:<Tag color="green"> {formattedBalance}</Tag></>;
                } else if ( netBalance < 0 ) {
                    return <>Payable:<Tag color="red">{Math.abs( formattedBalance )}</Tag> </>;
                }
                return formattedBalance; // in case it's zero
            }
        },
        // {
        //     title: 'Action',
        //     key: 'action',
        //     render: () => (
        //         <Space size="middle">
        //             <Button>See all transactions</Button>
        //         </Space>
        //     ),
        // },
    ];


    if ( isLoading ) {
        return <Spin size="large" />;
    }

    if ( isError ) {
        return <Alert message="Error fetching ledger data" type="error" />;
    }

    return <Table dataSource={data} columns={columns} />;
}

export default LedgerTable;
