const drawerCash = [
    {
        "index": 0,
        "name": '1000',
    },
    {
        "index": 1,
        "name": '500',
    },
    {
        "index": 2,
        "name": '200',
    },
    {
        "index": 3,
        "name": '100',
    },
    {
        "index": 4,
        "name": '50',
    },
    {
        "index": 5,
        "name": '20',
    },
    {
        "index": 6,
        "name": '10',
    },
    {
        "index": 7,
        "name": '5',
    },
]

const lockerCash = [
    {
        "name": 1000,
    },
    {
        "name": 500,
    },
    {
        "name": 200,
    },
    {
        "name": 100,
    },
    {
        "name": 50,
    },
    {
        "name": 20,
    },
    {
        "name": 10,
    },
    {
        "name": 5,
    },
]

const oppeningData = [
    {
        "value": "Opening Balance",
        "amount": 0
    }, {
        "value": "Bank Withdrawal",
        "amount": 0
    },
    {
        "value": "Advance Received",
        "amount": 0
    },
    {
        "value": "Staff Advance",
        "amount": 0
    },
    {
        "value": "Company Expenses Advance Refund",
        "amount": 0
    },
    {
        "value": "Sales",
        "amount": 0
    },
    {
        "value": "Other Income",
        "amount": 0
    },
    {
        "value": "Excess",
        "amount": 0
    },
    {
        "value": "Opening Exess",
        "amount": 0
    },
    {
        "value": "Total Opening",
        "amount": 0
    }
]

const closingData = [
    {
        "value": "Closing Balance",
        "amount": 0
    },
    {
        "value": "Bank Deposit",
        "amount": 0
    },
    {
        "value": "Advance Paid",
        "amount": 0
    },
    {
        "value": "Staff Advance Paid",  // Assuming "Staff Advance" is different from "Staff Advance Paid",
        "amount": 0
    },
    {
        "value": "Company Expenses Advance Paid",
        "amount": 0
    },
    {
        "value": "Purchase",
        "amount": 0
    },
    {
        "value": "Expenses",
        "amount": 0
    },
    {
        "value": "Shortage",
        "amount": 0
    },
    {
        "value": "Opening Shortage",
        "amount": 0
    },
    {
        "value": "Total Closing",
        "amount": 0
    },
]

const purchase = {
    "Products": [
        { "name": "WET Battery", "Quantity": 931293239, "Price": 382932, "avg": 7.6 },
        { "name": "Dry Battery", "Quantity": 53929239, "Price": 289392, "avg": 6.4 },
        { "name": "Solar Panel", "Quantity": 234235, "Price": 849393, "avg": 7.9 },
        { "name": "LED Light", "Quantity": 8238232, "Price": 23892, "avg": 6.7 }
    ],
    "total": {
        "quantity": 1005286706,
        "Price": 1505217,
        "avg": 7.5
    },
    "Credit": {
        "quantity": 1005286706,
        "Price": 1505217,
        "avg": 7.5
    },
    "Cash": {
        "quantity": 1005286706,
        "Price": 1505217,
        "avg": 7.5
    },

};

const sales = {
    "Products": [
        { "name": "WET Battery", "Quantity": 293239, "Price": 382932, "avg": 7.6 },
        { "name": "Dry Battery", "Quantity": 529239, "Price": 239392, "avg": 6.2 },
        { "name": "Solar Panel", "Quantity": 34235, "Price": 49393, "avg": 8.1 },
        { "name": "LED Light", "Quantity": 283232, "Price": 17892, "avg": 6.4 }
    ],
    "total": {
        "quantity": 1138945,
        "Price": 685609,
        "avg": 7.3
    },
    "Credit": {
        "quantity": 1005286706,
        "Price": 1505217,
        "avg": 7.5
    },
    "Cash": {
        "quantity": 1005286706,
        "Price": 1505217,
        "avg": 7.5
    },
};

export {
    drawerCash, lockerCash,
    oppeningData, closingData, purchase, sales
}

