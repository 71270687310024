import React, { useEffect, useState } from 'react';
import { Table, Button, Form, Input, Modal, Select, Space, InputNumber, Checkbox, DatePicker, Card, Row, Col } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomCard from '../../components/card/CustomCard';
import { PlusOutlined } from '@ant-design/icons'
import { useGetCustomers } from '../../services/Query/CustomerQuery';
import { useGetProducts } from '../../services/Query/ProductsQuery';
const { Option } = Select;

// Replace these with your actual data
const paymentTypes = ['Cash', 'Credit', 'Debit'];
const SalesDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [visible, setVisible] = useState( false );
    const [form] = Form.useForm();
    const [editedProduct, setEditedProduct] = useState( null );
    const [productDetails, setProductDetails] = useState( [] );
    const [isTaxable, setIsTaxable] = useState( false );
    const [taxAmount, setTaxAmount] = useState( 0 );
    const [totalAmount, setTotalAmount] = useState( 0 );
    const [amountRemaining, setAmountRemaining] = useState( 0 );
    const [amountPaid, setAmountPaid] = useState( 0 );
    const [newCustomerModalVisible, setNewCustomerModalVisible] = useState( false );
    const [newCustomerForm] = Form.useForm();
    const [tempProduct, setTempProduct] = useState( {} );
    const [tempPrice, setTempPrice] = useState();
    const [tempUnit, setTempUnit] = useState( 'MT' );
    const [tempQuantity, setTempQuantity] = useState();
    const { data: customers, isLoading, isError } = useGetCustomers();
    const [localCustomers, setLocalCustomers] = useState( customers ? customers : [] );
    const { data: products } = useGetProducts();
    const handleAddCustomer = () => {
        newCustomerForm.validateFields().then( values => {
            const newCustomer = {
                id: Date.now().toString(), // or another unique ID generation mechanism
                name: values.name,
                phone: values.phone
            };
            setLocalCustomers( prev => [...prev, newCustomer] );
            newCustomerForm.resetFields();
            setNewCustomerModalVisible( false );
        } );
    };
    const onFinish = ( values ) => {
        console.log( 'Received values of form: ', values );
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Price',
            dataIndex: 'price',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
        },

        {
            title: 'Action',
            key: 'action',
            render: ( text, record ) => (
                <Space size="middle">
                    <Button type="primary" onClick={() => handleEdit( record )}>Edit</Button>
                    <Button danger onClick={() => handleRemove( record.key )}>Remove</Button>
                </Space>
            ),
        },
    ];

    const handleEdit = ( record ) => {
        form.setFieldsValue( productDetails.find( pd => pd.key === record.key ) || { unit: 'MT', price: '', quantity: 1 } );
        setEditedProduct( record.key );
        setVisible( true );
    };

    const handleRemove = ( key ) => {
        setProductDetails( productDetails.filter( pd => pd.key !== key ) );
    };

    const handleOk = () => {
        const values = form.getFieldsValue();
        setProductDetails( productDetails.map( pd => pd.key === editedProduct ? { ...pd, ...values } : pd ) );
        setVisible( false );
    };

    const handleCancel = () => {
        setVisible( false );
    };
    useEffect( () => {
        // Calculate the total based on productDetails
        const total = productDetails.reduce( ( acc, product ) => acc + ( product.price * product.quantity ), 0 );
        const tax = isTaxable ? total * 0.05 : 0;
        const remaining = total + tax - form.getFieldValue( 'amountPaid' );

        form.setFieldsValue( {
            taxAmount: tax,
            totalAmount: total + tax,
            amountRemaining: remaining
        } );
        console.log( totalAmount )
    }, [productDetails, isTaxable, form] );
    const handleAddProduct = () => {
        if ( tempProduct && tempPrice ) {
            setProductDetails( prev => [
                ...prev,
                {
                    key: Date.now().toString(),
                    name: tempProduct.name,
                    code: tempProduct.code,
                    price: tempPrice,
                    unit: tempUnit
                }
            ] );
            setTempProduct( {} );
            setTempPrice( undefined );
            setTempQuantity( undefined )
        }

    }
    return (
        <div style={{ padding: '1rem', backgroundColor: '#f5f5f5' }}>
            {/* <Button style={{ marginBottom: '1rem' }} onClick={() => navigate( -1 )}>Back</Button> */}
            <CustomCard>
                <Form>
                    <Row gutter={16} align="middle">

                        <Col span={5}>
                            <Form.Item label="Product">
                                <Select
                                    showSearch
                                    style={{
                                        width: 200,
                                    }}
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    filterOption={( input, option ) => ( option?.label ?? '' ).includes( input )}
                                    filterSort={( optionA, optionB ) =>
                                        ( optionA?.label ?? '' ).toLowerCase().localeCompare( ( optionB?.label ?? '' ).toLowerCase() )
                                    }
                                    options={
                                        products ? products.map( rec => {
                                            return {
                                                value: rec._id,
                                                label: rec.name
                                            }
                                        } )
                                            : []
                                    }
                                />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item label="Price">
                                <InputNumber
                                    placeholder="0.00"
                                    min={0}
                                    style={{ width: '100%' }}
                                    value={tempPrice}
                                    onChange={setTempPrice}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item label="Quantity">
                                <InputNumber
                                    placeholder="1"
                                    min={1}
                                    style={{ width: '100%' }}
                                    value={tempQuantity}
                                    onChange={setTempQuantity}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item label="Unit">
                                <Select
                                    value={tempUnit}
                                    style={{ width: '100%' }}
                                    onChange={setTempUnit}
                                >
                                    <Option value="MT">MT</Option>
                                    <Option value="KG">KG</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={4} style={{ textAlign: 'right' }}>
                            <Form.Item>
                                <Button type="primary" icon={<PlusOutlined />} onClick={handleAddProduct} size="large">
                                    Add
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </CustomCard>



            <br />
            <Table
                columns={columns}
                dataSource={productDetails}
                rowKey="key"
                pagination={false}
            />
            <Modal title="Edit Product" visible={visible} onOk={handleOk} onCancel={handleCancel}>
                <Form form={form}>
                    <Form.Item name="quantity" label="Quantity">
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item name="price" label="Price">
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item name="unit" label="Unit">
                        <Select>
                            <Select.Option value="MT">MT</Select.Option>
                            <Select.Option value="KG">KG</Select.Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            {/* <Row style={{ width: "100%", marginTop: "10px" }} > */}
            <div style={{ marginTop: "30px" }}>
                <CustomCard style={{ width: "100% ", marginTop: "30px" }}>
                    <Form
                        name="order_form"
                        onFinish={onFinish}
                        layout="vertical"
                        form={form}
                        initialValues={{
                            orderType: 'Sale',
                            isTaxable: false,
                            orderId: 1,
                            orderDate: null,
                            customerId: null,
                            orderNumber: "",
                            amountPaid: 0,
                            amountRemaining: 0,
                            totalAmount: 0,
                            paymentType: null,
                            taxAmount: 0
                        }}
                        style={{ backgroundColor: '#ffffff', padding: '20px', borderRadius: '5px' }}
                    >
                        <h2>Order Details</h2>

                        <Row gutter={32}>
                            <Col span={8}>
                                <Form.Item
                                    name="orderId"
                                    label="Order ID"
                                    rules={[{ required: true, message: 'Please input the order ID!' }]}
                                >
                                    <InputNumber min={1} style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item
                                    name="orderType"
                                    label="Order Type"
                                    rules={[{ required: true, message: 'Please select the order type!' }]}
                                >
                                    <Select placeholder="Select an order type">
                                        <Option value="Sale">Sale</Option>
                                        <Option value="Purchase">Purchase</Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item
                                    name="orderDate"
                                    label="Order Date"
                                    rules={[{ required: true, message: 'Please select the order date!' }]}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="Select order date" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={32}>
                            <Col span={8}>
                                <Form.Item
                                    name="customerId"
                                    label="Customer ID"
                                    rules={[{ required: true, message: 'Please select the customer ID!' }]}
                                >
                                    <Select placeholder="Select a customer">
                                        {localCustomers.map( customer => (
                                            <Option key={customer.id} value={customer.id}>{customer.name}</Option>
                                        ) )}
                                    </Select>
                                    <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        onClick={() => setNewCustomerModalVisible( true )}
                                        style={{ marginTop: '10px' }}
                                    >
                                        Add Customer
                                    </Button>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item
                                    name="orderNumber"
                                    label="Order Number"
                                    rules={[{ required: true, message: 'Please input the order number!' }]}
                                >
                                    <Input placeholder="Enter order number" />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item
                                    name="amountPaid"
                                    label="Amount Paid"
                                >
                                    <InputNumber min={0} style={{ width: '100%' }} onChange={value => setAmountPaid( value )} placeholder="0" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={32}>
                            <Col span={8}>
                                <Form.Item
                                    name="amountRemaining"
                                    label="Amount Remaining"
                                >
                                    <InputNumber min={0} style={{ width: '100%' }} disabled />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item
                                    name="totalAmount"
                                    label="Total Amount"
                                >
                                    <InputNumber min={0} style={{ width: '100%' }} disabled />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item
                                    name="paymentType"
                                    label="Payment Type"
                                    rules={[{ required: true, message: 'Please select a payment type!' }]}
                                >
                                    <Select placeholder="Select payment type">
                                        {paymentTypes.map( type => (
                                            <Option key={type} value={type}>{type}</Option>
                                        ) )}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={32}>
                            <Col span={8}>
                                <Form.Item
                                    name="isTaxable"
                                    valuePropName="checked"
                                >
                                    <Checkbox onChange={e => setIsTaxable( e.target.checked )}>Is Taxable?</Checkbox>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item
                                    name="taxAmount"
                                    label="Tax Amount"
                                >
                                    <InputNumber min={0} style={{ width: '100%' }} disabled />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={32}>
                            <Col span={24} style={{ textAlign: 'right' }}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" size="large">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                        {/* customer form  */}
                        <Modal
                            title="Add New Customer"
                            visible={newCustomerModalVisible}
                            onOk={handleAddCustomer}
                            onCancel={() => setNewCustomerModalVisible( false )}
                        >
                            <Form form={newCustomerForm}>
                                <Form.Item
                                    name="name"
                                    label="Customer Name"
                                    rules={[{ required: true, message: 'Please input the customer name!' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name="phone"
                                    label="Phone Number"
                                    rules={[{ required: true, message: 'Please input the phone number!' }]}
                                >
                                    <Input />
                                </Form.Item>

                                {/* Added Customer Type field */}
                                <Form.Item
                                    name="customerType"
                                    label="Customer Type"
                                    rules={[{ required: true, message: 'Please select the customer type!' }]}
                                >
                                    <Select placeholder="Select Customer Type">
                                        <Option value="Vendor-Customer">Vendor-Customer</Option>
                                        <Option value="Customer">Customer</Option>
                                        <Option value="Vendor">Vendor</Option>
                                    </Select>
                                </Form.Item>

                                {/* Added Emirates ID field */}
                                <Form.Item
                                    name="emiratesId"
                                    label="Emirates ID"
                                    rules={[{ required: true, message: 'Please input the Emirates ID!' }]}
                                >
                                    <Input placeholder="Emirates ID" />
                                </Form.Item>
                            </Form>
                        </Modal>

                    </Form>


                </CustomCard>
                {/* </Row> */}
            </div>

        </div>
    );
};

export default SalesDetails;
