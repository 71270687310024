import api from "../../utils/interceptors/api";

let baseUrl = process.env.REACT_APP_BASE_URL

export const addCompany = ( data ) => {
    return api.post( `/companies`, data );
}

export const fetchCompanies = async () => {
    const response = await api.get( '/companies' );
    return response.data;
};

export const updateCompany = ( companyId, companyData ) => {
    return api.put( `/companies/${ companyId }`, companyData );
}

export const deleteCompany = async ( companyId ) => {
    const response = await api.delete( `/companies/${ companyId }` );
    return response.data;
}
