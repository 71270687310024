import React, { useEffect } from 'react';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import '../login/Login.css';
// import { useRegister } from '../../services/Query/UserQuery'; // Assuming you have a registration query
import logo from '../../assets/logo.png';
import { TypeAnimation } from 'react-type-animation';
import { useRegister } from '../../services/Query/UserQuery';

const SignUp = () => {
    const navigate = useNavigate();
    const registerMutation = useRegister(); // Assuming you have a registration mutation
    const {
        isLoading,
        isIdle,
        isSuccess,
        isError,
        data,
        error,
    } = registerMutation;

    useEffect( () => {
        // Handle the "mutating" state
        if ( isLoading || isIdle ) {
            message.loading( {
                content: 'Signing up...',
                key: 'signup',
            } );
        }

        // Handle the "success" state
        if ( isSuccess ) {
            // Handle success, e.g., redirect to login
            message.success( {
                content: 'Signed up successfully! Please log in.',
                key: 'signup',
                duration: 2,
            } );
            setTimeout( () => navigate( '/login' ), 2000 );
        }

        // Handle the "error" state
        if ( isError ) {
            message.error( {
                content: 'Sign up failed. Please try again.',
                key: 'signup',
                duration: 2,
            } );
        }
    }, [isLoading, isIdle, isSuccess, isError, data, error] );

    const onFinish = ( values ) => {
        // Call the register mutation with form values
        registerMutation.mutate( values );
    };

    return (
        <div className="login-wrapper">
            <div className="login-container">
                <div className="login-sidebar">
                    <img src={logo} alt="Company Logo" className="login-logo" />

                    <TypeAnimation
                        sequence={[
                            'Streamlining Your Scrap Business.',
                            2000,
                            'From procurement to sales, we have you covered.',
                            2000,
                            'Specializing in:',
                            2000,
                            'Batteries Scrap Management',
                            1500,
                            'Metal Scrap Solutions',
                            1500,
                            'Car Scrap Operations',
                            1500,
                            'Computer Devices Recycling',
                            2000,
                            'Empower your business with cutting-edge SaaS.',
                            2500,
                            '',
                            1500,
                        ]}
                        style={{ fontSize: '1.5em' }}
                        repeat={Infinity}
                    />
                </div>
                <div className="login-form-container">
                    <Form
                        name="signup"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Email!',
                                },
                            ]}
                        >
                            <Input placeholder="Email" />
                        </Form.Item>

                        <Form.Item
                            name="contact"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Phone Number!',
                                },
                            ]}
                        >
                            <Input placeholder="Phone Number" />
                        </Form.Item>
                        <Form.Item
                            name="company"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your default company!',
                                },
                            ]}
                        >
                            <Input placeholder="Default Company" />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Password!',
                                },
                            ]}
                        >
                            <Input.Password placeholder="Password" />
                        </Form.Item>

                        <Form.Item
                            name="confirmPassword"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ( { getFieldValue } ) => ( {
                                    validator( _, value ) {
                                        if ( !value || getFieldValue( 'password' ) === value ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error( 'The two passwords do not match!' )
                                        );
                                    },
                                } ),
                            ]}
                        >
                            <Input.Password placeholder="Confirm Password" />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="login-form-button"
                                loading={registerMutation.isLoading}
                            >
                                Sign Up
                            </Button>
                        </Form.Item>

                        <div>
                            <p>
                                Already have an account?{' '}
                                <Link to="/login">Log In</Link>
                            </p>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
