import api from "../../utils/interceptors/api";

export const addReceipt = (data) => {
    return api.post(`/receipts`, data);
};

export const fetchReceipts = async () => {
    const response = await api.get('/receipts');
    return response.data;
};

export const updateReceipt = (receiptId, receiptData) => {
    return api.put(`/receipts/${receiptId}`, receiptData);
};

export const deleteReceipt = async (receiptId) => {
    const response = await api.delete(`/receipts/${receiptId}`);
    return response.data;
};

export const fetchReceiptById = async ( id ) => {
    const response = await api.get( `/receipts/${ id }` );
    return response.data;
};
