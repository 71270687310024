import React, { useState, useEffect } from 'react';
import { Form, Input, Button, DatePicker, InputNumber, Select, Modal, Space, Row, message, Col, Tag } from 'antd';
import { PlusOutlined, PlusCircleOutlined } from '@ant-design/icons';
import FileUploadComponent from '../../../components/upload/FileUploadComponent';
import { useAddPaymentCategory, useFetchPaymentCategories } from '../../../services/Query/PaymentCategoryQuery';
import { useAddCompany, useGetCompanies } from '../../../services/Query/CompanyQuery';
import { useAddCustomer, useGetAllCustomers, useGetCustomers } from '../../../services/Query/CustomerQuery';
import CustomerModal from '../../customer/Modal/CustomerModal';
import CompanyForm from '../../company/components/CompanyForm';

const { Option } = Select;

const PaymentForm = ( { onAddPayment, selectedRow, isEdit, onEdit } ) => {
    const [form] = Form.useForm();
    const [isModalVisible, setModalVisible] = useState( false );
    const [isCompanyModal, setCompanyModal] = useState( false );
    const paymentTypes = ['Cash', 'Bank'];
    const [searchTerm, setSearchTerm] = useState( "" );

    const [newCategory, setNewCategory] = useState( '' );
    const [description, setDescription] = useState( '' );
    const { data: categories = [] } = useFetchPaymentCategories();
    const useAddCategoryMutate = useAddPaymentCategory();
    // const [pagination, setPagination] = useState( {
    //     current: undefined,
    //     pageSize: undefined,
    // } );
    const initialFormValues = {
        customerId: isEdit ? selectedRow?.customerId : null,
        amount: isEdit ? selectedRow?.amount : null,
        companyId: isEdit ? selectedRow?.companyId : null,
        description: isEdit ? selectedRow?.description : '',
        category: isEdit ? selectedRow?.category : null,
        paymentType: isEdit ? selectedRow?.paymentType : "Cash",

    };
    // const [searchParams, setSearchParams] = useState( {
    //     name: "",
    //     id: "",
    //     validity: "",
    // } );
    const { data: companies = [] } = useGetCompanies();
    const { data: customers, isLoading, isError } = useGetAllCustomers( searchTerm );
    const [show, setShow] = useState( false )
    const createCustomerMutation = useAddCustomer();
    const createCompanyMutation = useAddCompany();
    const [selectedCustomer, setSelectedCustomer] = useState()
    const handleSubmit = ( formData ) => {
        createCustomerMutation.mutate( formData, {
            onSuccess: () => {
                message.success( "Customer added successfully" )
                onClose();
            },
            onError: () => {
                message.error( "Some thing wrong while adding data" )
            }
        } )
    }

    const handleSubmitCompany = ( formData ) => {
        createCompanyMutation.mutate( formData, {
            onSuccess: () => {
                message.success( "Company added successfully" );
                closeModal();
            },
            onError: () => {
                message.error( "Something went wrong while adding data" );
            }
        } );

    };
    const closeModal = () => {
        setCompanyModal( false )
    }
    const onClose = () => {
        setShow( false )
    }




    // const customers = customersData?.data

    const handleFormSubmit = ( values ) => {
        onAddPayment( { ...values } );
        form.resetFields();
    };
    const handleFormEditSubmit = ( values ) => {
        console.log( "values", { ...values } )
        onEdit( { ...values } );
    };
    useEffect( () => {
        console.log( "initialFormValues.paymentType:", initialFormValues.paymentType );
        form.setFieldsValue( { paymentType: 'Cash' } );
    }, [form] );
    useEffect( () => {
        if ( isEdit && selectedRow ) {
            const values = {
                customerId: selectedRow.customer.id,
                amount: selectedRow.amount,
                companyId: selectedRow.company.id,
                description: selectedRow.description,
                category: selectedRow.category.id
            };
            form.setFieldsValue( values );
        } else {
            form.resetFields();
        }
    }, [isEdit, selectedRow, form] );

    const handleAddNewCategory = () => {
        useAddCategoryMutate.mutate( { name: newCategory, description }, {
            onSuccess: () => {
                message.success( "Category added successfully" );
                setModalVisible( false );
                setNewCategory( '' );
                setDescription( '' );
            },
            onError: () => {
                message.error( "Something went wrong while adding data" );
                setModalVisible( false );
                setNewCategory( '' );
                setDescription( '' );
            }
        } );
    };
    const handleCustomerSearchChange = async ( customerId ) => {
        setSelectedCustomer( customers?.find( rec => rec._id == customerId ) )
        console.log( "CustomerId====>", customers?.find( rec => rec._id == customerId ) )
    };

    const handleSearchChange = ( e ) => {
        setSearchTerm( e );
    };

    console.log( "Customers========>", customers )
    const customFilter = ( input, option ) => {
        const optionLabel = option.label?.toLowerCase();
        const inputLower = input?.toLowerCase();
        return optionLabel && inputLower ? optionLabel.includes( inputLower ) : false;
    };
    return (
        <>
            <Space direction="vertical" size="large">
                <Form
                    form={form}
                    onFinish={isEdit ? handleFormEditSubmit : handleFormSubmit}
                    initialValues={initialFormValues}
                >
                    <CustomerModal
                        isVisible={show}
                        onUpdate={handleSubmit}
                        onClose={onClose}

                    />
                    <Row gutter={16}>
                        {/* Customer ID */}
                        <CompanyForm
                            isVisible={isCompanyModal}
                            handleSubmit={handleSubmitCompany}
                            closeModal={closeModal}
                        />
                        <Col lg={6}>
                            <Form.Item
                                label="Customer"
                                name="customerId"
                            >
                                <Select
                                    showSearch
                                    placeholder="Select a customer"
                                    options={Array.isArray( customers ) ? customers.map( customer => customer ? { value: customer._id, label: customer.name } : null ).filter( Boolean ) : []}
                                    onSearch={handleSearchChange}
                                    onChange={handleCustomerSearchChange}
                                    filterOption={false}  // Turn off client-side filtering, since we'll do it server-side
                                    suffixIcon={
                                        <PlusCircleOutlined
                                            style={{
                                                cursor: 'pointer',
                                                color: '#1890ff'  // '#d9d9d9' is a typical disabled color.
                                            }}
                                            onClick={() => setShow( true )}
                                        />
                                    }
                                />
                            </Form.Item>

                        </Col>

                        {/* Receipt Amount */}
                        <Col lg={6}>
                            <Form.Item
                                label="Amount"
                                name="amount"
                                rules={[{ required: true, message: 'Please input the receipt amount!' }]}
                            >
                                <InputNumber placeholder="Amount" style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>

                        {/* Company */}
                        <CompanyForm
                            isVisible={isCompanyModal}
                            handleSubmit={handleSubmitCompany}
                            closeModal={closeModal}
                        />
                        <Col lg={6}>
                            <Form.Item
                                label="Company"
                                name="companyId"
                                rules={[{ required: true, message: 'Please select a company!' }]}
                            >

                                <Select
                                    showSearch
                                    placeholder="Select Company"
                                    options={Array.isArray( companies ) ? companies.map( company => company ? { value: company._id, label: company.companyName } : null ).filter( Boolean ) : []}
                                    onSearch={handleSearchChange}
                                    filterOption={false}  // Turn off client-side filtering, since we'll do it server-side
                                    suffixIcon={
                                        <PlusCircleOutlined
                                            style={{
                                                cursor: 'pointer',
                                                color: '#1890ff'  // '#d9d9d9' is a typical disabled color.
                                            }}
                                            onClick={() => setCompanyModal( true )}
                                        />
                                    }
                                />
                                {/* <Select
                                    showSearch
                                    placeholder="Select a company"
                                    options={companies?.map( company => ( { value: company._id, label: company.companyName } ) ) || []}
                                /> */}
                            </Form.Item>
                        </Col>

                        {/* Receipt Description */}
                        <Col lg={6}>
                            <Form.Item
                                label="Description"
                                name="description"
                            >
                                <Input placeholder="Description" />
                            </Form.Item>
                        </Col>

                        {/* Receipt Category */}
                        <Col lg={6}>
                            <Form.Item
                                label="Category"
                                name="category"
                                rules={[{ required: true, message: 'Please select a category!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select a category"
                                    filterOption={customFilter}
                                    options={categories?.map( cat => ( { value: cat._id, label: cat.name } ) ) || []}
                                    suffixIcon={
                                        <PlusCircleOutlined
                                            style={{ cursor: 'pointer', color: '#1890ff' }}
                                            onClick={() => setModalVisible( true )}
                                        />
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6} md={12} sm={24} xs={24}>
                            <Form.Item
                                name="paymentType"
                                label="Payment Type"
                                rules={[{ required: true, message: 'Please select a payment type!' }]}
                            // style={formItemStyle}
                            >
                                {/* <Select style={fullWidthStyle}
                            placeholder="Select payment type"
                            
                        // defaultValue="Cash"
                        > */}
                                <Select
                                    placeholder="Select payment type"
                                    defaultValue="Cash" // Set "Cash" as the default value
                                // onChange={handlePaymentTypeChange}
                                >
                                    {paymentTypes.map( type => (
                                        <Option key={type} value={type}>
                                            {type}
                                        </Option>
                                    ) )}
                                </Select>

                            </Form.Item>

                        </Col>



                        <Col lg={6}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    {isEdit ? "Update" : "Add"}
                                </Button>
                            </Form.Item>
                        </Col>


                    </Row>
                    <Row>
                        {selectedCustomer?.balance && <Col lg={4}>
                            {selectedCustomer && ( selectedCustomer?.balance > 0 ) ? <Tag color='red'>{selectedCustomer?.balance}</Tag> : <Tag color='green'>{Math.abs( selectedCustomer?.balance )}</Tag>}

                        </Col>}

                    </Row>
                </Form>
                {/* Modal for New Category */}
                <Modal
                    title="Add new payment category"
                    visible={isModalVisible}
                    onCancel={() => setModalVisible( false )}
                    footer={null}
                >
                    <Form layout="vertical">
                        <Form.Item label="Category Name" required>
                            <Input
                                placeholder="Enter category name"
                                value={newCategory}
                                onChange={e => setNewCategory( e.target.value )}
                            />
                        </Form.Item>

                        <Form.Item label="Description">
                            <Input
                                placeholder="Enter description"
                                value={description}
                                onChange={e => setDescription( e.target.value )}
                            />
                        </Form.Item>

                        <Space align="end">
                            <Button onClick={() => setModalVisible( false )}>
                                Cancel
                            </Button>
                            <Button type="primary" onClick={handleAddNewCategory}>
                                Add Category
                            </Button>
                        </Space>
                    </Form>
                </Modal>
            </Space>
        </>
    );
}

export default PaymentForm;
