import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Select, Switch } from 'antd';
import { useGetCompanies } from '../../../services/Query/CompanyQuery';

const { Option } = Select;

const BalanceForm = ( { currentBalances, onSetBalance } ) => {
    const [form] = Form.useForm();
    const [isClosingBalance, setIsClosingBalance] = useState( true );  // State to toggle between closing and opening balance
    const { data: companies } = useGetCompanies();

    const handleSubmit = ( values ) => {
        const { companyId, balance } = values;
        if ( isClosingBalance ) {
            onSetBalance( { companyId, closingBalance: balance } );

        } else {
            onSetBalance( { companyId, openingBalance: balance } );

        }
    };

    return (
        <Form
            layout="horizontal"
            form={form}
            initialValues={currentBalances}
            onFinish={handleSubmit}
        >
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={6}>
                    <Form.Item
                        label="Company"
                        name="companyId"
                        rules={[{ required: true, message: 'Please select a company!' }]}
                    >
                        <Select placeholder="Select a company">
                            {companies && companies.map( company => (
                                <Option key={company._id} value={company._id}>
                                    {company.companyName}
                                </Option>
                            ) )}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={6}>
                    <Form.Item
                        label="Balance"
                        name="balance"
                        rules={[{ required: true, message: 'Please input the balance!' }]}
                    >
                        <Input type="number" placeholder="Enter Balance" />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={6}>
                    <Form.Item
                        label="Balance Type"
                        name="balanceType"
                    >
                        <Switch checked={isClosingBalance} onChange={setIsClosingBalance} checkedChildren="Closing" unCheckedChildren="Opening" />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={6}>
                    <Form.Item style={{ textAlign: 'center' }}>
                        <Button type="primary" htmlType="submit">
                            Set Balances
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default BalanceForm;
