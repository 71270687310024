import { useMutation, useQuery, useQueryClient } from "react-query";
import { fetchExpenses, addExpense, updateExpense, deleteExpense ,fetchExpenseById} from "../APICalls/ExpenseService";

export const useAddExpense = () => {
    const queryClient = useQueryClient();
    return useMutation(addExpense, {
        onSuccess: () => {
            // After a successful mutation, invalidate the 'expenses' query to refetch the data
            queryClient.invalidateQueries('expenses');
        }
    });
}

export const useGetExpenses = () => {
    return useQuery('expenses', fetchExpenses);
}

export const useUpdateExpense = () => {
    const queryClient = useQueryClient();

    return useMutation((data) => updateExpense(data.expenseId, data.expenseData), {
        onSuccess: () => {
            // Invalidate the 'expenses' query to refetch data after updating
            queryClient.invalidateQueries('expenses');
        }
    });
}

export const useDeleteExpense = () => {
    const queryClient = useQueryClient();

    return useMutation(
        expenseId => deleteExpense(expenseId),
        {
            onSuccess: () => {
                // Invalidate the 'expenses' query to refetch data after deletion
                queryClient.invalidateQueries('expenses');
            }
        }
    );
}

export const useGetExpenseById = ( id ) => {
    return useQuery( ['expense', id], () => fetchExpenseById( id ) );
};
