import React, { useState } from "react";
import { Table, Input, Button, Space, Popconfirm, Form, message } from "antd";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  useAddTransaction,
  useDeleteTransaction,
  useGetTransactions,
  useUpdateTransaction,
} from "../../services/Query/TransactionQuery";
import CustomCard from "../../components/card/CustomCard";
import TransactionForm from "./components/TransactionForm";
import moment from "moment";

const Transactions = () => {
  const [isVisible, setIsVisible] = useState( false );
  const [formData, setFormData] = useState( {} );
  const [isEdit, setIsEdit] = useState( false );
  const [transactionId, setTransactionId] = useState( "" );
  const { data: transactions, isLoading, isError } = useGetTransactions();
  const createTransactionMutation = useAddTransaction();
  const updateTransactionMutation = useUpdateTransaction();
  const deleteTransactionMutation = useDeleteTransaction();
  const [form] = Form.useForm();

  const closeModal = () => {
    setIsVisible( false );
    setIsEdit( false );
  };

  const handleEditSubmit = ( transactionData ) => {
    updateTransactionMutation.mutate(
      { transactionId, transactionData },
      {
        onSuccess: () => {
          message.success( "Transaction Updated successfully" );
          closeModal();
        },
        onError: () => {
          message.error( "Something went wrong while updating data" );
        },
      }
    );
  };

  const handleSubmit = ( values ) => {
    console.log( "values__", values );
    createTransactionMutation.mutate( values, {
      onSuccess: () => {
        message.success( "Transaction added successfully" );
        closeModal();
      },
      onError: () => {
        message.error( "Something went wrong while adding data" );
      },
    } );
  };

  const handleInputChange = ( e ) => {
    const { name, value } = e.target;
    setFormData( ( prev ) => ( { ...prev, [name]: value } ) );
  };

  const formatDate = ( dateString ) => {
    return moment( dateString ).format( 'MMMM D, YYYY, h:mm A' );
  }

  const columns = [
    {
      title: "Customer Name",
      dataIndex: "customerName",
      render: ( text ) => text || "N/A"
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      render: ( text ) => text || "N/A"
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      render: ( text ) => text ? formatDate( text ) : "N/A"
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      render: ( text ) => text || "N/A"
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      render: ( text ) => text || "N/A"
    },
  ];



  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 16,
        }}
      >
        <Input
          placeholder="Search Transaction"
          prefix={<SearchOutlined />}
          style={{ width: 200 }}
        />
        {/* <Button
          type="primary"
          onClick={() => {
            setFormData({});
            setIsVisible(true);
          }}
        >
          Add Transaction
        </Button> */}
      </div>
      {isLoading ? (
        <p>Loading....</p>
      ) : (
        <CustomCard>
          <Table columns={columns} dataSource={transactions} />
        </CustomCard>
      )}

      {/* <TransactionForm
        isEdit={isEdit}
        open={isVisible}
        onOk={
          isEdit ? () => handleEditSubmit(form.getFieldValue()) : handleSubmit
        }
        handleEditSubmit={handleEditSubmit}
        onCancel={closeModal}
        formData={formData}
      /> */}
    </div>
  );
};

export default Transactions;
