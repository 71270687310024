import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import FullCalendar from '@fullcalendar/react';
import { Modal, Form, Input, Button, notification, Row, Col, Select, DatePicker, TimePicker, message } from 'antd';
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import CustomCard from '../../components/card/CustomCard';
import listPlugin from '@fullcalendar/list';
import moment from 'moment';
import { useAddReminder, useDeleteReminder, useGetReminders, useUpdateReminder } from '../../services/Query/RemindersQuery';
import { useGetCustomers } from '../../services/Query/CustomerQuery';
import timeGridPlugin from '@fullcalendar/timegrid';
function Reminders() {
    const [form] = Form.useForm();
    const [selectedDate, setSelectedDate] = useState( null );
    const [selectedTime, setSelectedTime] = useState( null );
    const [modalVisible, setModalVisible] = useState( false );
    const [currentReminderId, setCurrentReminderId] = useState( null );
    const { data: reminders } = useGetReminders();
    const { data: customersData, isLoading, isError } = useGetCustomers();


    const createReminderMutation = useAddReminder();
    const updateReminderMutation = useUpdateReminder();
    const deleteReminderMutation = useDeleteReminder();
    const customers = customersData?.data;
    const handleDateClick = ( { dateStr } ) => {
        console.log( "Date String:", dateStr );

        const momentDate = moment( dateStr );

        // If the dateStr contains time (hours, minutes, or seconds not equal to zero)
        if ( momentDate.hour() !== 0 || momentDate.minute() !== 0 || momentDate.second() !== 0 ) {
            console.log( "hou========", momentDate )
            setSelectedDate( momentDate.format( 'YYYY-MM-DD HH:mm:ss' ) );
            setSelectedTime( momentDate.format( 'HH:mm:ss' ) );
        } else {
            console.log( "hou========", momentDate )
            setSelectedDate( dateStr );
            setSelectedTime( null );  // or any default time you wish to set
        }

        setModalVisible( true );
    };


    const handleTimeChange = ( time ) => {
        setSelectedTime( time ? time.format( 'HH:mm:ss' ) : null );
    };

    const handleEventClick = ( { event } ) => {
        setCurrentReminderId( event.extendedProps._id );
        console.log( "event.extendedProps", event.extendedProps, event )
        form.setFieldsValue( {
            title: event.title,
            description: event.extendedProps.description,
            customerId: event.extendedProps.customerId,
        } );
        setModalVisible( true );
    };
    const closeModal = () => {
        setModalVisible( false );
        setCurrentReminderId( null );
        form.resetFields();
        setSelectedTime( null )
    }

    const handleSubmit = ( values ) => {
        const data = {
            ...values,
            date: moment( `${ selectedDate } ${ selectedTime }` ).toISOString(),
        };
        if ( currentReminderId ) {
            updateReminderMutation.mutate( {
                reminderId: currentReminderId,
                data: data
            },
                {
                    onSuccess: () => {
                        message.success( "Remider Updated successfully" );
                        closeModal();
                    },
                    onError: () => {
                        message.error( "Something went wrong while adding data" );
                    }
                }
            );
        } else {
            createReminderMutation.mutate( data, {
                onSuccess: () => {
                    message.success( "Remider added successfully" );
                    closeModal();
                },
                onError: () => {
                    message.error( "Something went wrong while adding data" );
                }
            } );
        }
    };
    console.log( "current=========>", currentReminderId )
    return (
        <div>
            <Row>
                <Col lg={24}>
                    <CustomCard>
                        <FullCalendar
                            plugins={[dayGridPlugin, interactionPlugin, listPlugin, timeGridPlugin]} // Add timeGridPlugin
                            headerToolbar={{
                                left: 'prev,next today',
                                center: 'title',
                                right: 'dayGridMonth,listWeek,timeGridWeek,timeGridDay' // Add timeGrid views
                            }}
                            initialView="dayGridMonth"
                            events={reminders}
                            dateClick={handleDateClick}
                            eventClick={handleEventClick}
                        />
                        <Modal
                            title={currentReminderId ? "Edit Reminder" : "Add Reminder"}
                            visible={modalVisible}
                            onCancel={closeModal}
                            footer={[
                                <Button key="delete" icon={<DeleteOutlined />} danger onClick={() => {
                                    deleteReminderMutation.mutate( currentReminderId );
                                    setModalVisible( false );
                                }}>
                                    Delete
                                </Button>,
                                <Button key="back" onClick={() => setModalVisible( false )}>
                                    Cancel
                                </Button>,
                                <Button key="submit" type="primary" onClick={() => form.submit()}>
                                    Save
                                </Button>,
                            ]}
                        >
                            <Form form={form} onFinish={handleSubmit}>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item name="title" rules={[{ required: true, message: 'Please input a title!' }]}>
                                            <Input placeholder="Title" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name="description">
                                            <Input.TextArea placeholder="Description" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name="customerId" rules={[{ required: true, message: 'Please select a customer!' }]}>
                                            <Select placeholder="Select a customer">
                                                {customers && customers.map( customer => (
                                                    <Select.Option key={customer._id} value={customer._id}>{customer.name}</Select.Option>
                                                ) )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Date">
                                            <DatePicker value={moment( selectedDate )} disabled format="YYYY-MM-DD" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Time">
                                            <TimePicker
                                                value={selectedTime ? moment( selectedTime, 'HH:mm:ss' ) : null}
                                                onChange={handleTimeChange}
                                                format="HH:mm"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal>
                    </CustomCard>
                </Col>
            </Row>
        </div>
    );
}

export default Reminders;
