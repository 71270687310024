import api from "../../utils/interceptors/api";
export const addTransaction = ( data ) => {
    return api.post( `/transactions`, data );
}

export const fetchTransactions = async () => {
    const response = await api.get( '/transactions' );
    return response.data;
};

export const updateTransaction = ( transactionId, transactionData ) => {
    return api.put( `/transactions/${ transactionId }`, transactionData );
}

export const deleteTransaction = async ( transactionId ) => {
    const response = await api.delete( `/transactions/${ transactionId }` );
    return response.data;
}
export const fetchCustomerTransactions = async ( customerId, companyIds, searchParams ) => {
    const response = await api.get( `/ledger/fetchCustomerLedger/${ customerId }`, { params: { companyIds, searchParams } } );
    console.log( "response", response );
    return response.data;
};
