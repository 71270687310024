import { useMutation, useQueryClient } from "react-query";
import { uploadFile } from "../APICalls/FileUploadService";

export const useUploadFile = () => {
    const queryClient = useQueryClient();
    
    return useMutation(uploadFile, {
        onSuccess: () => {
            queryClient.invalidateQueries('someQueryKey');
        }
    });
};