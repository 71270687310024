import React, { useState, useEffect } from "react";
import styles from "../../pages/order/Order.module.css";
import { Button, Carousel, Form, Modal, Row, Skeleton, Spin } from "antd";
import { InfoCircleOutlined, PrinterOutlined } from "@ant-design/icons";
import CustomCard from "../card/CustomCard";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import moment from "moment";
import { useGetExpenseById } from "../../services/Query/ExpenseQuery";

const ViewExpense = ( { show, id, onClose } ) => {
    const { data: expenses, isLoading } = useGetExpenseById( id );
    const [imageLoading, setImageLoading] = useState( true ); // Initially set to true
    const [showCarousel, setShowCarousel] = useState( false );

    let expense = isLoading ? {} : expenses[0];

    useEffect( () => {
        const timeoutId = setTimeout( () => {
            setImageLoading( false );
            setShowCarousel( true );
        }, 5000 );

        return () => clearTimeout( timeoutId ); // Clear the timeout on component unmount
    }, [] );

    const NextArrow = ( { onClick } ) => {
        return (
            <div className="arrow next" onClick={onClick}>
                &gt;
            </div>
        );
    };

    const PrevArrow = ( { onClick } ) => {
        return (
            <div className="arrow prev" onClick={onClick}>
                &lt;
            </div>
        );
    };
    const handlePrint = () => {
        const modalContent = document.getElementById( "expense-modal-content" );
        html2canvas( modalContent ).then( ( canvas ) => {
            const imgData = canvas.toDataURL( "image/png" );
            const pdf = new jsPDF( "p", "mm", "a4" );
            pdf.addImage( imgData, "PNG", 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height );
            pdf.save( "expense_detail.pdf" );
        } );
    };

    const renderContent = () => (
        <Modal
            title={<div style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                alignContent: "center",
            }}><h3><InfoCircleOutlined /> Expense Detail</h3><Button key="print" icon={<PrinterOutlined />} onClick={handlePrint}>
                    Print
                </Button></div >}
            visible={show}
            onCancel={onClose}
            footer={null}
            className="my-custom-class"
            style={{ width: "800px" }}
        >
            <div className={styles.container} style={{ width: "100% !important" }} id="expense-modal-content">
                {/* <div id="expense-modal-content"> */}
                <CustomCard>
                    <div className="invoice-header">
                        <p><strong>Date:</strong> {moment( expense?.expenseDate ).format( "YYYY-MM-DD" )}</p>
                        <p><strong>Name:</strong> {expense?.name}</p>
                        <p><strong>Amount:</strong> {expense?.totalAmount}</p>
                    </div>
                    <div className="invoice-header">
                        <p><strong>Company:</strong> {expense?.company?.name}</p>
                        <p><strong>Category:</strong> {expense?.expenseCategoryName}</p>
                        <p><strong>Sub Category:</strong> {expense?.expenseSubCategoryName}</p>
                    </div>
                    <p><strong>Remarks:</strong> {expense?.expenseDetails}</p>
                    <div className="payment-details">
                        {/* <strong>Attachments:</strong> */}
                        <CustomCard>
                            {expense?.attachments ? (
                                <>
                                    {imageLoading ? (
                                        <Skeleton.Image active={true} />
                                    ) : showCarousel ? (
                                        <Carousel
                                            arrows
                                            prevArrow={<PrevArrow />}
                                            nextArrow={<NextArrow />}
                                        >
                                            {expense?.attachments?.map( ( attachment, index ) => (
                                                <div key={index}>
                                                    <img
                                                        src={attachment}
                                                        alt={`Attachment ${ index + 1 }`}
                                                        style={{ width: '100%' }}
                                                    />
                                                </div>
                                            ) )}
                                        </Carousel>
                                    ) : null}
                                </>
                            ) : null}
                        </CustomCard>
                        <div className="invoice-header">

                        </div>
                    </div>
                </CustomCard>
            </div>
        </Modal >
    );

    return isLoading ? null : <div className="modal-ledger"> {renderContent()} </div>;
};

export default ViewExpense;
