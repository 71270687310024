import React, { useState } from 'react';
import { Select, Button, Space, Form, Modal, message } from 'antd';
import { useGetAllCustomers, useGetCustomers } from '../../services/Query/CustomerQuery';
import LedgerTable from '../../components/tables/LedgerTable'
import CustomCard from '../../components/card/CustomCard';
import { CloseCircleOutlined } from '@ant-design/icons';

const Ledger = () => {
    const [searchTerm, setSearchTerm] = useState( "" );
    const { data: customers } = useGetAllCustomers( searchTerm );



    const handleSearchChange = ( e ) => {
        setSearchTerm( e );

    };
    const [selectedCustomer, setSelectedCustomer] = useState( null );

    const [searchParams, setSearchParams] = useState( {
        name: "",
        id: "",
        validity: "",
    } );
    const [pagination, setPagination] = useState( {
        current: undefined,
        pageSize: undefined,
    } );
    // const {
    //     data: customersData,
    //     isLoading,
    //     isError,
    //     refetch,
    // } = useGetCustomers( pagination.current, pagination.pageSize, searchParams );
    // const customers = customersData?.data;
    console.log( "selectedCustomer=======>", selectedCustomer )
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>

                <Form>
                    <Form.Item
                        name="customerId"
                        style={{ width: "300px" }}
                    >
                        <Select
                            showSearch
                            placeholder="Select a customer"
                            options={( customers || [] ).map( customer => customer && ( { value: customer._id, label: customer.name } ) ).filter( Boolean )}
                            onSearch={handleSearchChange}
                            filterOption={false}
                            onChange={( value, option ) => {
                                console.log( "Value========>", value );
                                setSelectedCustomer( value );
                            }}
                            suffixIcon={
                                selectedCustomer && (
                                    <CloseCircleOutlined
                                        style={{ color: '#999', cursor: 'pointer' }}
                                        onClick={() => {
                                            setSelectedCustomer( null )
                                            setSearchParams(null)
                                        }}
                                    />
                                )
                            }
                        />
                    </Form.Item>
                </Form>
                {/* <Select
                    showSearch
                    placeholder="Select a customer"
                    optionFilterProp="children"
                    style={{ width: 200 }}
                    onChange={( value ) => setSelectedCustomer( value )}
                    filterOption={( input, option ) =>
                        option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                    }
                >
                    {customers && customers.map( customer => (
                        <Select.Option key={customer._id} value={customer._id}>
                            {customer.name}
                        </Select.Option>
                    ) )}
                </Select> */}

                {/* <Button type="primary" onClick={() => { }}>Add Ledger Entry</Button> */}

            </div>
            <CustomCard>
                <LedgerTable
                    selectedCustomer={selectedCustomer}
                />
            </CustomCard>
        </div>
    );
};

export default Ledger;
